<template>
    <div class="column items-center ">
        <div class="col-sx-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
            <div class="text-center self-center">
                <h3 class="q-mb-xl">Accedi con la tua Email</h3>
                <form v-on:submit.prevent="emailLinkLogin" >
                    <q-input id="qEmail" class="q-mb-xl"
                        v-model.lazy="email"
                        :rules="[ d => email_regexp.test(d) || 'Email non conforme']"
                        outlined 
                        type="email" 
                        hint="Email" 
                        label="Inserisci l'email">
                    </q-input>

                    <div class="text-center">
                        <VueRecaptcha sitekey="6Ld_XKcfAAAAAJ8m4nvXkAaM1_QGc_N73N3Tr5u6"
                            type='recaptcha'
                            class="g-recaptcha"
                            @verify="captchaVerified"
                            @expired="captchaFailed"
                            @error="captchaFailed"
                        >
                        </VueRecaptcha>
                    </div>
                    <p></p>
                    <div v-if='loading==false'>
                        <q-btn 
                            color="green" 
                            text-color="white" 
                            rounded
                            size='lg'
                            icon="email"
                            class="q-ma-sm" 
                            label="Invia Email" 
                            type="submit"
                            v-bind:disabled="disable_send_link"
                        ></q-btn>
                    </div>
                    <div v-else>
                        <q-spinner-ios
                            color="green"
                            size="6em"
                            :thickness="9"
                        ></q-spinner-ios>
                    </div>
                </form>
            </div>
            <div>
                <img style="width: 100%;" src="/img/gfx/login.svg" />
            </div>
            <div class="devinfo">
                {{version}}
            </div>
        </div>
    </div>

    <!-- OK Dialog -->
	<q-dialog v-model="login_dialog_show" persistent>
		<q-card class="q-ma-sm">
			<q-card-section class="row items-center">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
				    <q-avatar icon="email" color="green" text-color="white" size="100px"></q-avatar>
				</div>
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <span class="q-ml-sm" v-html="login_dialog_html"></span>
                </div>
			</q-card-section>
		</q-card>
	</q-dialog>

    <!-- Error Dialog -->
	<q-dialog v-model="error_dialog_show" persistent>
		<q-card>
			<q-card-section class="row items-center">
				<q-avatar icon="error" color="red" text-color="white" size="100px"></q-avatar>
				<span class="q-ml-sm" v-html="error_dialog_html"></span>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn flat icon="close" label="OK" color="green" v-close-popup></q-btn>
			</q-card-actions>
		</q-card>
	</q-dialog>


</template>



<script>
    import * as tools from '../common/tools'
    import * as config from '../common/config'
    import { auth } from '../common/firebase'
    import { sendSignInLinkToEmail } from 'firebase/auth'
    import { VueRecaptcha } from 'vue-recaptcha';
 
    export default {
        name : 'Login',
        components: {
            VueRecaptcha,
        },

        props : {            // values passed by caller Commponent via props
        },

        data () {            // State(s) function
            return {
                version : config.AppVersion,

                email : this.$route.query.flmail || '',
                password : '',
                seePwd : true,                       // Used for password vision toggle
                query : this.$route.query,

                loading : false,
                login_dialog_show : false,
                error_dialog_show : false,
                
                email_regexp : config.Email_RegExp,
                login_dialog_html : '',
                error_dialog_html : '',

                captcha_passed : false,
            } 
        },

        computed : {         // Computed on the spot from data values
            disable_send_link : function () {
                return !(this.captcha_passed && this.email_regexp.test(this.email))
            } 
        },

        methods: {
            logout : function () {
                this.email = ''
                this.password = ''
                tools.clearLogout()
                this.$emit("evLogged")
            },

            tryLogin : async function () {
                // Try to login using fl-auth-token only
                let logged = await tools.apiLogin(null, null, null, null)
                console.error("LOGGED", logged)
                if (logged.ok && logged.success) {
                    // JWT Token login succeeded
                    let user = {}

                    user.email = logged.user_email
                    user.loginMethod = 'JWT'
                    user['fl-auth-token'] = logged['fl-auth-token']
                    user.user_fluid = logged.user_fluid
                    // Set empty flag to make user view fill the data
                    user.empty = true
                    tools.storeUser(user)
                    this.$emit("evLogged")
                    
                    // Go to page
                    switch (logged.user_role) {
                        case 0 : // owner
                        case 1 : // breeder
                        case 3 : // group / association
                            this.$router.push({path : '/user/'+logged.user_fluid})
                            break
                        case 2 : // vet
                        case 4 : // company
                            this.$router.push({path : '/biz/'+logged.user_fluid})
                            break
                        default: 
                            break
                    }
                }
            },

            captchaVerified : function (response) {
                console.error("Captcha Response : ", response)
                this.captcha_passed = true
            },

            captchaFailed : function () {
                this.captcha_passed = false
            },

            emailLinkLogin : async function () {
                // Add the email used as query string to prevent need to ask for it later
                this.query.flmail = this.email
                const actionCodeSettings = {
                    // If we got here with a query string, it will get back to next page
                    url: `${location.origin}/logback?`+tools.obj2QueryString(this.query),
                    handleCodeInApp: true,
                }
                try {
                    await sendSignInLinkToEmail(auth, this.email, actionCodeSettings)
                    let user = {
                        email: this.email,
                        loginMethod: 'email_link_temp',
                    }
                    tools.updateUser(user)
                    this.login_dialog_html = "<h4>Grazie !</h4>Ti abbiamo inviato una mail con il link per accedere alla piattaforma.<br>Cerca nella casella (e nello spam) la mail <br><b><i>\"Accedi a FaunaLife\"</i></b>"
                    this.login_dialog_show = true
                } catch (e) {
                    console.error(e)
                    console.error(e.message)
                    this.error_dialog_html = "<h4>Opss... !</h4>Si è verificato un errore di di autenticazione.<br>Controlla l'email inserita e riprova.<br>Grazie."                    
                    this.error_dialog_show = true
                }
            },
        },

        watch: {             // Stuff that happend when a variable changes
        },

        // Lifecycle Hooks

        mounted() {            
            this.logout()
            this.tryLogin()
            this.email = this.$route.query.flmail || ''
        },

        created() {
            // this.logout()
        },
    }
</script>


// #css
<style>
    .g-recaptcha {
        display: inline-block;
    }
</style>