<template>
    <!-- Back Button -->
    <div class="text-center q-mx-sm">
        <q-btn 
            color="navigate" 
            text-color="white" 
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="$router.go(-1)"
        ></q-btn>
    </div>

    <!-- MAIN SELECT -->

    <div class="container q-px-md" v-if='phase==0'>
        <h3>Che emergenza vuoi segnalare?</h3>
        <div class="separator-blank q-my-xl"></div>
        <q-radio
            class="q-ma-sm"
            keep-color 
            v-model="emergencyType" 
            val="e_lost"   
            label="Ho perso un pet" 
            color="green" 
            dense
            @update:model-value="advancePhase('e_lost', null)"
        ></q-radio><br>
        <q-radio
            class="q-ma-sm"
            keep-color 
            v-model="emergencyType" 
            val="e_found"   
            label="Ho ritrovato un pet" 
            color="green" 
            dense
            @update:model-value="advancePhase('e_found', null)"
        ></q-radio><br>
        <q-radio
            class="q-ma-sm"
            keep-color 
            v-model="emergencyType" 
            val="e_other"   
            label="Altro" 
            color="green" 
            dense
            @update:model-value="advancePhase('e_other', null)"
        ></q-radio><br>
    </div>

    <!-- LOST -->

    <div class="container q-px-md" v-if='phase==11'>
        <h3>Quale dei tuoi pet hai perso?</h3>
        <div class="separator-blank q-my-xl"></div>
        <ScanPetGrid :pet_list="userPets" :selectOnly="true" @petSelected="petSelected"/>
    </div>

    <div class="container q-px-xl" v-if='phase==111'>
        <h3>Ti aiuteremo a ritrovare {{pet_name}}</h3>
        <div class="separator-blank q-my-sm"></div>
        Dicci qualcosa di più per poterti aiutare. Indica il posto, e/o come vuoi essere contattato.<br>
        Nessuna informazione è di troppo, nessun dettaglio è scontato.
        <div class="separator-blank q-my-sm"></div>
        <q-input 
            v-model="message_text"
            autogrow
            outlined 
            rounded
            label-color="green"
            color="green" 
            label="Informazioni aggiuntive"
        ></q-input>
        <!-- Send Button  -->
        <div class="separator-blank q-my-sm"></div>
        <div class="text-center">
            <q-btn class=" q-ma-sm"
                color="green"
                text-color="white" 
                size="lg"
                icon="upload"
                rounded
                label="Invia" 
                @click="advancePhase('e_lost_send', null)"
            ></q-btn>
        </div>
    </div>
    
    <div class="container q-px-md" v-if='phase==112'>
        <h3>Ti aiuteremo a ritrovare {{pet_name}}</h3>
        <div class="separator-blank q-my-xl"></div>
        Invieremo una mail agli altri utenti vicini a te avvisandoli che {{pet_name}}
        si è perso.<br>Chiunque ritrovasse {{pet_name}} può cercarlo su FaunaLife ed inviarci
        una segnalazione. Appena dovessimo ricevere notizie ti invieremo aggiornamenti tramite
        email o usando i dettagli che ci hai fornito
    </div>

    <!-- FOUND -->

    <div class="container q-px-md" v-if='phase==12'>
        <h3>Quale pet hai ritrovato?</h3>
        <div class="separator-blank q-my-xl"></div>
        <div v-if="userPets[0]">
            L'ultimo pet visualizzato
            <ScanPetGrid 
                class="text-center"
                :pet_list="userPets" 
                :selectOnly="true" 
                @petSelected="petSelected"
            />
            Oppure:
            <div class="separator-blank q-my-xl"></div>
        </div> 
        Inserisci il numero di microchip
        <q-input id="pet_tag" v-model="pet_tag" type="text" 
            outlined 
            rounded
            label-color="green"
            color="green"
            hint="Inserire numero di microchip" 
            label=""
            :error="chip_error"
            error-message="Numero chip non conforme"
            @update:model-value="isValidChip()"
            @blur="isValidChip()"
        >
            <template v-slot:after>
                <q-icon name="pets" :color="icm_chip" size="25px"></q-icon>
            </template>                    
        </q-input>
        <!-- Send Button  -->
        <div class="separator-blank q-my-xl"></div>
        <div class="text-center">
            <div v-if="computed_can_send">
                <q-btn 
                    color="green"
                    text-color="white" 
                    size="lg"
                    icon="done"
                    rounded
                    class="q-ma-sm" 
                    label="Conferma" 
                    @click="advancePhase('e_found_chip')"
                ></q-btn>
            </div>
            <div v-else>
                <!-- Mandatory marker -->
                E' necessario inserire un numero di Chip conforme <q-icon name="pets" color="red" size="25px"/><br>
            </div>
        </div>
    </div>

    <div class="container q-px-md" v-if='phase==121'>
        <h3>Hai ritrovato {{pet_name}} !</h3>
        <ScanPetGrid 
            class="text-center"
            :pet_list="userPets" 
            :selectOnly="true"
        />
        Dicci qualcosa di più per poter aiutare {{pet_name}} a tornare a casa. Indica il posto, e come vuoi essere contattato.<br>
        Nessuna informazione è di troppo, nessun dettaglio è scontato.
        <div class="separator-blank q-my-sm"></div>
        <q-input 
            v-model="message_text"
            autogrow
            outlined 
            rounded
            label-color="green"
            color="green" 
            label="Informazioni aggiuntive"
        ></q-input>
        <!-- Send Button  -->
        <div class="separator-blank q-my-sm"></div>
        <div class="text-center">
            <q-btn class=" q-ma-sm"
                color="green"
                text-color="white" 
                size="lg"
                icon="upload"
                rounded
                label="Invia" 
                @click="advancePhase('e_found_send', null)"
            ></q-btn>
        </div>
    </div>

    <div class="container q-px-md" v-if='phase==122'>
        <h3>Sei l'eroe di {{pet_name}} !!</h3>
        <div class="separator-blank q-my-xl"></div>
        Contatteremo ora il proprietario di {{pet_name}} e cercheremo di mettervi in contattato
        per riportare {{pet_name}} a casa !
    </div>

    <!-- OTHER -->

    <div class="container q-px-md" v-if='phase==13'>
        <h3>Come possiamo aiutarti ?</h3>
        <div class="separator-blank q-my-xl"></div>
        Dicci cosa possiamo fare per te. Non scordarti di lasciare i tuoi contatti.
        <q-input 
            v-model="message_text"
            autogrow
            outlined 
            rounded
            label-color="green"
            color="green" 
            label="Informazioni aggiuntive"
        ></q-input>
        <!-- Send Button  -->
        <div class="separator-blank q-my-sm"></div>
        <div class="text-center">
            <q-btn class=" q-ma-sm"
                color="green"
                text-color="white" 
                size="lg"
                icon="upload"
                rounded
                label="Invia" 
                @click="advancePhase('e_other_send', null)"
            ></q-btn>
        </div>
    </div>

    <div class="container q-px-md" v-if='phase==131'>
        <h3>Come possiamo aiutarti ?</h3>
        <div class="separator-blank q-my-xl"></div>
        Prenderemo in carico la tua segnalazione il prima possibile.
        Grazie per averci contattato
    </div>

    <!-- Login Dialog -->
    <q-dialog v-model="login_dialog_show" persistent>
        <q-card>
            <q-card-section class="row items-center">
                <q-avatar icon="done" color="green"></q-avatar>
                <span class="q-ml-sm">
                <h4>Attenzione !</h4>
                <p>Sembra che tu non sia loggato.</p>
                <p>Non puoi usare questa funzione finchè non effettui la login.</p>
                </span>
            </q-card-section>
            <q-card-actions align="right">
                <q-btn flat icon="check" label="login" color="green" @click="this.$router.push({path : '/login'})" v-close-popup></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog> 

    <!-- Error Dialog -->
    <q-dialog v-model="error_dialog_show" persistent>
    <q-card>
        <q-card-section class="row items-center">
            <q-avatar icon="warning" color="green"></q-avatar>
            <div class="q-ml-sm">
            <h4>Attenzione !</h4>
            {{error_message}} 
            </div>
        </q-card-section>
        <q-card-section class="row items-center">
            <div v-html="input_error"></div>
        </q-card-section>
        <q-card-actions align="right">
            <q-btn flat icon="close" label="Chiudi" color="green" v-close-popup></q-btn>
        </q-card-actions>
    </q-card>
</q-dialog>
 
</template>



<script>
    import * as tools from '../common/tools'
    import ScanPetGrid      from '../components/Scan/Scan_PetGrid.vue'

    export default {
        name : 'SOS',
        components: {
            ScanPetGrid,
        },

        props : {            // values passed by caller Commponent via props
        },

        data () {            // State(s) function
            return {
                is_logged       : false,
                user_fluid      : 0,
                phase           : 0,
                emergencyType   : 0,
                userPets        : [],
                login_dialog_show : false,
                pet_name        : '',
                pet_fluid       : null,
                pet_tag         : null,
                chip_error      : null,
                icm_chip        : 'red',
                message_text    : '',

                error_dialog_show : false,
                error_message   : ''
            } 
        },

        computed : {         // Computed on the spot from data values
            computed_can_send : function() {
                return this.chip_error==false
            },
        },

        methods: {

            advancePhase : async function (value, event=null ) {
                console.error("advancing ", value, event)
                
                let message = {}
                let user = tools.getUser()
                if (user) {
                    message.user = user.email || user.user_email
                }

                switch (value) {
                    case 'e_lost':
                        if (this.is_logged) {
                            let d = []
                            let t = []
                            // Gather list of pets
                            this.userPets = tools.getUser().pets
                            // Remove dead or transfered pets
                            for (let n in this.userPets) {
                                let dh = await tools.apiGetPetIsDead(this.userPets[n])
                                if (dh != false) {
                                    d.push(this.userPets[n])
                                    continue
                                }
                                let ow = (await tools.apiGetPetOwner(this.userPets[n]))
                                if (ow && this.user_fluid.localeCompare(ow, undefined, {sensitivity: 'base'})) {
                                    t.push(this.userPets[n])
                                }
                            }
                            this.userPets = this.userPets.filter(x=> {return !d.includes(x)})
                            this.userPets = this.userPets.filter(x=> {return !t.includes(x)})
                            this.phase = 11
                        } else {
                            // Warn and then send to Login
                            this.login_dialog_show = true
                        }
                        break
                    case 'e_lost_send':
                        message.event = 'lost pet'
                        message.pet_name = this.pet_name
                        message.pet_fluid = this.pet_fluid
                        message.message = this.message_text
                        tools.apiNotifyMessage(message)
                        this.phase = 112
                        break
                    case 'e_found':
                        this.userPets = [await tools.getFocusPet(false)]
                        console.error("UP: ", this.userPets.length)
                        this.phase = 12
                        break
                    case 'e_found_chip':
                        this.phase = 121
                        break
                    case 'e_found_send':
                        message.event = 'found pet'
                        message.pet_name = this.pet_name
                        message.pet_fluid = this.pet_fluid
                        message.message = this.message_text
                        tools.apiNotifyMessage(message)
                        this.phase = 122    
                        break
                    case 'e_other':
                        this.phase = 13
                        break
                    case 'e_other_send':
                        message.event = 'other SOS request'
                        message.message = this.message_text
                        tools.apiNotifyMessage(message)
                        this.phase = 131
                        break

                    // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
                    // Geolocation.getCurrentPosition()
                }
            },

            petSelected : function(pet) {
                console.error("Pet: ", pet)
                this.pet_name = pet.name
                this.pet_fluid = pet.fluid
                this.userPets = [pet.fluid]
                switch (this.phase) {
                    case 11 :                        
                        this.phase = 111
                        break
                    case 12:
                        this.phase = 121
                        break
                }
            },

            isValidChip : async function() {
                // Check if supplied chip is syntacticaly valid and matches ISO for FDXB
                this.pet_tag = this.pet_tag.toUpperCase()
                if (!tools.isValidChip(this.pet_tag,null)) {
                    this.chip_error = true
                    this.icm_chip = 'red'
                    return false
                }

                // Check if chip is used == Known
                // Ask if a NEW Fluid can be created from this tag. 
                let r = await tools.apiGet('/fluid/from/'+this.pet_tag+'/?new=true')
                if (r.ok==false) {
                    // Error means the tag is already in use, so it's OK
                    r = await tools.apiGet('/fluid/from/'+this.pet_tag)
                    this.pet_fluid = r.fluid
                    let pet = await tools.cacheGetPet(this.pet_fluid)
                    this.pet_name = pet.rawattributes.name
                    this.userPets = [this.pet_fluid]
                    this.chip_error = false
                    this.icm_chip = 'green'
                    return true
                } else {
                    // No error means the chip is not registered in FL
                    this.chip_error = true
                    this.icm_chip = 'red'
                    this.error_message = 'Il chip immesso non risulta nel nostro database...'
                    this.error_dialog_show = true
                    return false    
                }
            },

            // Go back to User Page
            backToUser : function () {
                if (this.is_logged) {
                    this.$router.push('/user/'+user_fluid)
                } else {
                    this.$route.go(-1)
                }
            },

        },

        watch: {             // Stuff that happend when a variable changes
        },

        // Lifecycle Hooks

        mounted() {
            this.is_logged = tools.checkLogged()
            this.user_fluid = (this.is_logged)  ? (tools.getUser()).user_fluid : 0
            window.scrollTo(0,0)      
        },

        created() {
        },
    }
</script>



// #css
<style>

</style>