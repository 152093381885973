<template>
    <div ref="printable" class="A4Page">
        <Logo />
        <div class="container q-px-xl">

            <div v-if='this.fetched.ok'>
                <div class="row items-center">
                    <div v-if='fetched.attributes' class="col-12 q-pa-sm">
                        <h4>Scheda di {{fetched.attributes.nome}}</h4>
                    </div>
                </div>
                <div class="row items-center">
                    <div v-if='fetched.attributes' class="col-9 q-pa-sm">
                        <div class='scan-data' >
                            <div v-for='(att, name, idx) in fetched.attributes' :key='idx'>
                                <div class="row">
                                    <div class="detail-print-property col-5">
                                        {{ name }}:
                                    </div>
                                    <div class="detail-print-value col-7">
                                        <span v-html="att"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if='fetched.attributes' class="col-3 q-pa-sm">
                        <div class="row items-center q-pa-sm ">
                            <q-img
                                ratio=1
                                v-bind:src="fetched.profilepicurl"
                                spinner-color="white"
                            ></q-img>
                        </div>
                        <div class="row items-center q-pa-sm">
                            <span v-html="qr_img_url"></span>
                        </div>
                    </div>
                </div>

                <div class="row items-center">
                    <div v-if='fetched.attributes' class="col-12 q-pa-sm">
                        <h4>Proprietari</h4>
                    </div>
                </div>
                <div v-if="fetched.owners" class='scan-data q-pa-sm'>
                    <div v-for='(hash,  idx) in fetched.owners' :key='idx'>
                        <div class="row">
                            <div class="detail-print-property col-4">
                                ID Proprietario n°{{idx+1}}:
                            </div>
                            <div class="detail-print-value col-8">
                                {{hash}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row items-center">
                    <div v-if='notes' class="col-12 q-pa-sm">
                        <h4>Note e caratteristiche</h4>
                        <div v-for='(note, idx) in notes' :key='idx'>
                            <div class="row">
                                <div class="detail-print-property col-4">
                                    {{idx+1}}) {{note.desc}}
                                </div>
                                <div class="detail-print-value col-8">
                                    {{note.data}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <p/>
            </div>
        </div>
    </div>
</template>

<script>
    import * as bctools from '../common/bctools' 
    import * as tools from '../common/tools'
    import * as config from '../common/config'
    import Logo from "../components/Logo"

    export default {
        name: 'Print',
        components: {
            Logo
        },

        props : {            // values passed by caller Commponent via props
            fluid : {
                type: String,
                required : true,
                validator : (fluid) => bctools.validAddress(fluid),
            },
        },

        data()  {
            return {
                ifluid : bctools.validAddress(this.fluid) ? this.fluid : -1,
                fetched : 'Loading...',
                notes_html : '',
                tr_details : {},
                avatar_size : config.AvatarPrintSize+'px',
                notes : []
            }
        },

        computed : {         // Computed on the spot from data values
            qr_img_url : function () {
                let page_url = encodeURIComponent(config.AppBaseUrl+'/scan/'+this.fetched.fluid)
                return '<img src="'+config.ApiBaseUrl+'/util/qr/'+page_url+'" width="'+config.AvatarPrintSize+'px"></img>'
            }
        },

        created() {
            this.retrieveData()
        },

        methods: {
            retrieveData: async function() {   
                let fp = await tools.getFocusPet()
                if (fp !== null && fp.fluid == this.ifluid) {
                    this.fetched = fp
                    this.fetched.profilepicurl = (fp.profilepicurl) ? fp.profilepicurl[(fp.profilepicurl).length-1] : [tools.defaultPic(fp.rawcodes.species)]
                }
                await this.readNotes()
            },

            readNotes : async function () {
                this.notes_html = '<div class="indented">'
                if (this.fetched.notes) {
                    for (let n of this.fetched.notes) {
                        let fetched = await tools.apiGet('/docs/'+n.dochash)
                        if (fetched.ok) {
                            this.notes_html += fetched.data.description+':<p>'
                            this.notes_html += '<div class="indented">'
                            this.notes_html += (await tools.downloadUrl(fetched.data.url)).content
                            this.notes_html += '</div>'
                            this.notes.push({
                                    'desc' : fetched.data.description , 
                                    'data' : (await tools.downloadUrl(fetched.data.url)).content
                                })
                        }
                    }
                }
                this.notes_html += '</div>'
                await this.printDownload()
            },

            printDownload : async function () {
                await setTimeout(() => {
                    window.print()
                    this.$router.go(-1)
                }, 1000)
            }
        }
    }
</script>

<style>
.A4Page {
    padding: 6mm 5mm;
    background-image: url("${bgImage}");
    background-size: contain;
    page-break-after: always;
    width: 200mm;
    height: 284mm;   
    background-color: #FFF !important;
    font-size: 10px;         
}

@page {
            size: A4;
            margin: 0mm;
}
@media print {
    html, body {
    width: 210mm;
    height: 297mm;
    margin: 0mm;
    }
    .page {
        border : 1px;
        margin: 0mm;
        background: initial;
        page-break-after: always;                
    }
}
</style>