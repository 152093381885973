<template>
    <div class='infogroup-dark text-center' v-bind:class="{mesh_bg: hascard}">
        <div style="`position:relative;`" v-on:click="manageClick()">
            <div>
                <q-img
                    v-if="pet_profilepic"
                    ratio=1
                    class="img-rounder" v-bind:class="{profile_with_card: hascard}" 
                    v-bind:src="pet_profilepic[presented_pic]"
                    spinner-color="white"
                    style="`position:absolute; z-index=0;`"
                >            
                    <template v-slot:error >
                        <img v-bind:src="error_pic" class="profilepic" />
                    </template>
                    <div v-if="editable && has_no_pic && is_owner" class="absolute-bottom text-subtitle1 text-center">
                        Aggiungi Foto
                    </div>
                </q-img>
                <q-spinner-ios v-else
                    color="green"
                    size="6em"
                    :thickness="9"
            ></q-spinner-ios>
            </div>
        </div>

        <div v-if=editable>
            <q-btn
                v-if="multiple_images"
                flat
                color="white" 
                :text-color="img_icons" 
                size="md"
                icon="keyboard_arrow_left" 
                type="submit"
                @click="ciclePic(-1)"
            ></q-btn>
            <q-btn
                v-if="is_owner"
                flat
                color="white" 
                :text-color="img_icons" 
                size="md"
                icon="edit" 
                @click="emitEdit()"
            >
                <q-tooltip class="text-h6">Aggiungi Foto</q-tooltip>
            </q-btn>
            <q-btn
                v-if="multiple_images"
                flat
                color="white" 
                text-color="img_icons" 
                size="md"
                icon="keyboard_arrow_right" 
                type="submit"
                @click="ciclePic(+1)"
            ></q-btn>
        </div>
        
        <!-- NAME -->
        <div v-on:click='manageClick()'>
            <h3 class="ellipsis"  v-bind:class="{white: hascard}">{{ pet_name }}</h3>
        </div>

        <!-- CARD & SCORES -->
        <div v-if="editable">
            <div v-if="hascard">
                <span 
                    class="material-icons green" v-bind:class="{white: hascard}"
                >
                    credit_card
                </span>
                <span class="font-small q-mx-sm" v-bind:class="{white: hascard}">
                    &nbsp;{{pet_extras.mypetcard}}
                </span>
            </div>
            <!-- <span class="material-icons green"><h2>workspace_premium</h2></span>&nbsp; -->
            <span v-for="(score, idx) in pet_scores" :key='idx' class="font-small q-mx-sm" v-bind:class="{white: hascard}">
                <q-tooltip class="text-h6">
                    {{score.tooltip}}
                </q-tooltip>
                {{score.value}}
                <span 
                    class="material-icons"
                    v-bind:class="[score.color]"
                >{{score.materialicon}}</span>&nbsp;
            </span>
        </div> 
        
    </div>
</template>


<script>
    import * as bctools from '../common/bctools' 
    import * as tools from '../common/tools'
    import * as config from '../common/config'
 
    export default {
        name : 'PetProfileCard',
        components: {
        },

        props : {
            pet_fluid : {
                type: String,
                required : false,
                default : null,
                validator : (fluid) => bctools.validAddress(fluid),
            },
            // Editable is true only in the Pet Scan page
            editable : {
                type : Boolean,
                required : false,
                default : false,
            }
        },

        data()  {
            return {
                ifluid : bctools.validAddress(this.pet_fluid) ? this.pet_fluid : -1,
                avatar_size : config.AvatarSize+'px',
                fetched : 'Loading...',
                historylink : null,
                input_search : "",
                pet_name : null,
                pet_profilepic : false,
                pet_scores : [],
                pet_extras : {},
                presented_pic : 0,
                error_pic : '',
                has_no_pic : true,

                // Extras
                hascard : false,
            }
        },   

        computed : {         // Computed on the spot from data values
            is_owner : function () {
                return tools.userHasPet(this.ifluid)
            },
            multiple_images : function () {
                return this.pet_profilepic.length > 1
            },
            img_icons : function() {
                return this.hascard ? 'white' : 'grey'
            }
        },

        methods: {           // Component functions
            getProfile : async function() {
                // Minimal Default Values
                this.pet_name = tools.shortHash(this.ifluid)

                // Get it from scratch (Should already be in cache now)
                let refetched = await tools.cacheGetPet(this.ifluid)
                if (refetched == null) {
                    console.error(refetched.message)
                    return
                }

                this.error_pic = tools.defaultPic(refetched.rawcodes.species)
                this.pet_name = refetched.rawattributes.name
                this.pet_profilepic = refetched.profilepicurl
                this.pet_scores = refetched.scores
                if (! Array.isArray(this.pet_profilepic) || this.pet_profilepic.length==0 ) {
                    // No pic, use default
                    this.pet_profilepic= [tools.defaultPic(refetched.rawcodes.species)]
                } else {
                    this.has_no_pic = false
                }
                this.presented_pic = this.pet_profilepic.length-1


                if (refetched.extra) {
                    this.pet_extras = JSON.parse(JSON.stringify(refetched.extra))
                    if (refetched.extra.mypetcard) {
                        this.hascard = true        
                    }
                }
            },

            manageClick : function () {
                // Why this works and editing avatar don't ???
                console.error("managing click")
                if (this.editable) {
                    if (this.has_no_pic) {
                        this.emitEdit()
                    }
                } else {        
                    this.$router.push({path : '/scan/'+this.ifluid})
                }
            },

            emitEdit : function () {
                if (this.is_owner) {
                    this.$emit('editAvatarEvent')
                }
            },

            ciclePic : function (move) {
                this.presented_pic = (this.pet_profilepic.length + move + this.presented_pic)%this.pet_profilepic.length
            },
        },


        watch: {
        },

        // Lifecycle Hooks
        mounted() {
        },

        created() {
            this.getProfile()
        },
    }
</script>



// #css
<style scoped>
    .profilepic {
    width: v-bind(avatar_size) !important;
    height: v-bind(avatar_size) !important;
    }

    .mesh_bg {
        background-image: url("../assets/bg_mesh_ver.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .profile_with_card {
        /*border: clamp(0.5rem, 0.3906rem + 0.4861vw, 0.9375rem) solid orange; */
        border : 1px solid white;
    }

    .white {
        color: white !important;
    }

    .red {
        color: var(--fl-red-1);
    }

    .gold {
        color: var(--fl-yellow-1);
    }

    .blue {
        color : var(--fl-blue-1);
    }

    .green {
        color: var(--fl-green-2);
    }

</style>