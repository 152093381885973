<template>
    <div class="container q-px-xs">

        <!-- Biz Data -->
        <div class='fluid-details q-mb-xl' v-if="display == 2">
            <div class="detail-table-heading infogroup">
                <div class="detail-table-heading-title" v-if="biz.logo_url">
                    <q-img
                        loading="lazy"
                        :width="banner_size"
                        :src="biz.logo_url"
                    ></q-img>
                </div>
                <div v-else class="detail-table-heading-title">
                    <h3>{{biz.biz_name}}</h3>
                </div>
                <div class="justify-content: center">

                    <!-- Unfollow Button -->
                    <q-btn
                        v-if = "this.is_follower"
                        color="green" 
                        text-color= "red" 
                        rounded
                        size="sm"
                        icon="favorite"
                        class="q-ma-sm"
                        @click="toggleFollow()"
                    >
                        <q-tooltip class="text-h6">sei follower</q-tooltip>
                    </q-btn>

                    <!-- Follow button -->
                    <q-btn
                        v-else
                        color="green" 
                        text-color= "white" 
                        rounded
                        size="sm"
                        icon="favorite_border"
                        class="q-ma-sm"
                        @click="toggleFollow()"
                    >
                        <q-tooltip class="text-h6">segui</q-tooltip>
                    </q-btn>

                </div>
            </div>
            
                
            <ScanDetails 
                v-bind:details={}
                v-bind:buttons='details_buttons'
                title = 'Contatti'
            />

            <div v-if="biz.iframe_url" class="h_iframe">
                <div class="separator-blank q-my-xl"></div>
                <div class="text-center">
                    <iframe
                        id ="biz_iframe"  
                        ref="biz_iframe"
                        frameborder="0"
                        v-bind:src="biz.iframe_url"
                        width="95%"
                        style="border:none;"
                    />
                </div>
            </div>
            
            <div v-if="maps_url" class='q-px-lg'>
                <div class="separator-blank q-my-xl"></div>
                <h3>Dove si trova:</h3>
                <div class="text-center">
                    <iframe 
                        width="95%"
                        height="450"
                        frameborder="0" style="border:0"
                        referrerpolicy="no-referrer-when-downgrade"
                        v-bind:src="maps_url"
                        allowfullscreen
                    >
                    </iframe>
                </div>
            </div>

            <!-- Promotion Banners -->
            <div>
            </div>

        </div>


        <!-- contact info -->
        <div v-if="display == 1" class="q-ma-xl">
            <h1>Benvenuto su FaunaLife!</h1>
            <p>Grazie per esserti registrato. La tua pagina verrà pronta quanto prima.<br>
            Per farlo abbiamo bisogno di alcune informazioni da parte tua!</p>
            <a href="mailto://info@fauna.life">Contattaci!</a>
        </div>

        <!-- Spinner -->
        <div v-if="display == 0" class="text-center">
            <q-spinner-ios
                color="green"
                size="6em"
                :thickness="9"
            ></q-spinner-ios>
        </div>

        <br>
    </div>
</template>


<script>
    import * as bctools     from '../common/bctools' 
    import * as tools       from '../common/tools'
    import * as config      from '../common/config'
    import ScanDetails      from '../components/Scan/Scan_Details'

    export default {
        name : 'Business',
        components: {
            ScanDetails
        },
    
        props : {            // values passed by caller Commponent via props
            business_fluid : {
                type: String,
                required : true,
                default : null,
                validator : (fluid) => bctools.validAddress(fluid),
            },
        },


        data()  {
            return {
                fetched         : 'Loading...',
                display         : 0,
                biz             : null,
                maps_url        : null,
                key             : config.MapsApiKey,
                banner_size     : config.SquareAdvSize+'px',
                iframe_padding  : "80%",
                is_follower     : false
            }
        },    

        computed : {
            details_buttons : function () {
                let buttons=[]
                let share = {}
                share.url = location.href
                share.title = 'La landing page di '+this.biz.biz_name+' su FaunaLife'
                share.text = 'La landing page di '+this.biz.biz_name+' su FaunaLife\n'
                buttons.push({icon:'share' , url:'',  label:'', tooltip:'condividi', click:'share', share_data : share})
                buttons.push({icon:'open_in_new' , url: this.biz.site_url,  label:'', tooltip:'sito', click:'open'})
                buttons.push({icon:'mail' , url:this.biz.email,  label:'', tooltip:'email', click:'open'})
                buttons.push({icon:'phone' , url:this.biz.phone,  label:'', tooltip:'chiama', click:'open'})
                return buttons
            },

        },
    
        methods: {
            /**
             * If it's a company/business, get the iframe to inject into the page
             */
            getBusiness : async function(fluid) {
                let biz = await tools.apiGetBusiness(fluid)
                // Show something...
                this.display = 1
                if (biz.ok) {
                    delete biz.ok
                    if (biz.length > 0 ) {
                        // Got something more to show
                        this.biz = biz[0]
                        if (this.biz.address?.length > 0 ) {
                            this.maps_url = "https://www.google.com/maps/embed/v1/place?key="+this.key+"&q="+this.biz.address+"&maptype=satellite&zoom=17"
                        }
                        this.biz.email = "mailto:"+this.biz.email
                        if (this.biz.email?.length > 0) {
                        }
                        if (this.biz.phone?.length > 0) {
                            this.biz.phone = "tel:"+this.biz.phone
                        }
                        if (this.biz.iframe_height == 0) {
                            this.biz.iframe_height == "700px"
                        }
                        this.biz.iframe_height = (this.biz.iframe_height).toString()+'px'
                        this.display = 2
                    }
                } 
            },

            toggleFollow : function () {
                let user = tools.getUser()
                if (!user.referrals || ! Array.isArray(user.referrals) ) {
                    user.referrals = []
                }

                let body = {business : this.business_fluid}
                if (user.referrals.includes(this.business_fluid)) {
                    // delete from array
                    user.referrals = user.referrals.filter(x => {return x != this.business_fluid})
                    this.is_follower = false
                } else {
                    // add to array
                    user.referrals.push(this.business_fluid)
                    this.is_follower = true
                }
                tools.storeUser(user)
                
                // Push update to API
                body.follow = this.is_follower
                tools.apiFollow(body)
            }
        },
    
        watch: {                    // Stuff that happend when a variable changes
        },
    
        // Lifecycle Hooks
    
        mounted() {
            tools.clearFocusPet()
            window.scrollTo(0,0)
            this.getBusiness(this.business_fluid)

            let user = tools.getUser()
            this.is_follower = (user.referrals && Array.isArray(user.referrals) && user.referrals.includes(this.business_fluid)) 
        },
    
        updated() {
        },

    }
</script>


// #css
<style scoped>

.h_iframe {
    overflow: auto;
    position:relative;
    padding-top: v-bind(iframe_padding);
}


.h_iframe iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

</style>