<template>
    <div class="container bg-dark q-py-xs q-px-md vertical-middle" v-if="is_print==false">
        <div class="row">
            <div class="text-left col-xs-2 col-sm-2 col-md-3 col-lg-3 col-xl-3">
                <a href="https://fauna.life" target="_blank" >
                    <img width="40" height="40" role="img" src="../assets/ithink.png" class="vertical-middle" />
                    <q-tooltip class="text-h6">Sito</q-tooltip>
                </a>
            </div>
            <div class="text-center self-center col-xs-7  col-sm-7  col-md-6  col-lg-6 col-xl-6 q-pa-l">
                <div class="container">
                    <div class="row justify-center">
                        <div class="col col-2 q-ma-m" v-if="!is_logged">
                            <router-link to="/login"  class="text-white">
                                <span class="material-icons" style="font-size: 30px">home</span>
                                <q-tooltip class="text-h6">Vai alla<br>Login</q-tooltip>
                            </router-link>
                        </div>
                        <div class="col col-2 q-ma-m">
                            <router-link to="/about"  class="text-white">
                                <span class="material-icons" style="font-size: 30px">info</span>
                                <q-tooltip class="text-h6">Cos'è FaunaLife?</q-tooltip>
                            </router-link>
                        </div>
                        <div class="col col-2 q-ma-m">
                            <router-link to="/search" class="text-white">
                                <span class="material-icons" style="font-size: 30px">search</span>
                                <q-tooltip class="text-h6">Cerca un pet<br>tramite chip/id</q-tooltip>
                            </router-link>
                        </div>
                        <div class="col col-2 q-ma-m">
                            <a href="https://www.fauna.life/guide-e-howto/" class="text-white" target="_faunalife">
                            <span class="material-icons" style="font-size: 30px">contact_support</span>
                            <q-tooltip class="text-h6">Supporto e FAQ</q-tooltip>
                            </a>
                        </div>

                        <div class="col col-2 q-ma-m">
                            <router-link to="/sos" class="text-red">
                                <span class="material-icons-outlined" style="font-size: 22px">sos</span>
                                <q-tooltip class="text-h6">Segnala<br>un'emergenza</q-tooltip>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right self-center col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" v-if="is_logged">
                <q-btn-dropdown 
                    color="white"
                    text-color="green"
                    dropdown-icon="menu"
                    size="15px"
                    dense=true
                >
                    <q-list>
                        <q-item clickable v-close-popup v-on:click="this.$router.push({path : '/user/'+this.user_fluid})">
                            <q-item-section avatar>
                                <q-avatar icon="person" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Profilo</q-item-label>
                            </q-item-section>
                        </q-item>


                        <q-item clickable v-close-popup v-on:click="goShop()">
                            <q-item-section avatar>
                                <q-avatar icon="shopping_cart" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Promozioni</q-item-label>
                            </q-item-section>
                        </q-item>



                        <q-item clickable v-close-popup v-on:click="this.share_profile()">
                            <q-item-section avatar>
                                <q-avatar icon="share" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Condividi</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup v-on:click="goSupport()">
                            <q-item-section avatar>
                                <q-avatar icon="contact_support" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Supporto</q-item-label>
                            </q-item-section>
                        </q-item> 
                        <q-separator spaced></q-separator>
                        <q-item clickable v-close-popup v-on:click="goLogOut()">
                            <q-item-section avatar>
                                <q-avatar icon="logout" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Logout</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
            </div>
        </div>
    </div>

    <q-dialog v-model="done_dialog_show" persistent>
        Dialog
    </q-dialog>


    <Logo v-if="showLogo" />


</template>



<script>
    import * as tools from "../common/tools"
    import * as config from "../common/config"
    import Logo from "./Logo"

    export default {
    name : 'Header',
    components: {
        Logo
    },
 
    props : {            // values passed by caller Commponent via props
        page : String,  // short version
        showLogo: {
            type: Boolean,
            default: true,
        },
    },
 
    data () {            // State(s) function
        return {
            is_logged : false,
            showLogo: this.showLogo === true,
            user_fluid: 0
        } 
    },
 
    methods: {           // Component functions
        goLogOut : function() {
            tools.clearLogout()
            tools.apiLogout()
            this.is_logged = tools.checkLogged()
            this.$router.push({path: '/'})
        },

        goSupport : function() {
            window.open('https://www.fauna.life/guide-e-howto/', '_blank').focus()
        },

        goShop : function() {
            window.open(config.ShopBaseUrl+'/promo', '_blank').focus()
        },

        share_profile : function () {
            let share = {}
            share.title = 'Il mio profilo su FaunaLife'
            share.url = config.AppBaseUrl+'/user/'+this.user_fluid
            share.text = 'Il mio profilo su FaunaLife:\n'
            share.text+= 'Email: '+(tools.getUser()).email+"\n"
            share.text+= 'FLuID: '+this.user_fluid+"\n"
            if (navigator.share) {
                navigator.share(share)
            } else {
                // allow to send an email if navigator share fails
                let mail_url = 'mailto:?subject='+encodeURIComponent(share.title)+'&body='+encodeURIComponent(share.text)+'\nURL: '+encodeURIComponent(share.url)
                window.open(mail_url, null);                
            }
        }

    },

    computed : {
        is_print : function() {
            // Black header must not show in print page
            return this.$route.fullPath.includes('/print/')
        }
    },
 
    watch: {             // Stuff that happend when a variable changes
    },
 
    // Lifecycle Hooks
 
    mounted() {
        this.is_logged = tools.checkLogged()
        this.user_fluid = (this.is_logged)  ? (tools.getUser()).user_fluid : 0
    },

    updated() {
        this.is_logged = tools.checkLogged()
        this.user_fluid = (this.is_logged)  ? (tools.getUser()).user_fluid : 0
    },
 
    created() {
    },
  }
</script>



// #css
<style>
</style>