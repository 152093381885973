import { createStore } from 'vuex'

export default createStore({
  
    // // All common variables go here
    // state: {
    //     user_fluid  : '',
    //     user_email  : '',
    //     user_pets   : [],
    //     user_logged : false,
    // },

    // // Al Sync methods that change the state go here
    // mutations: {
    //     setUserFluid(state, fl) {
    //         state.user_fluid = fl
    //     },

    //     setUserEmail(state, em) {
    //         state.user_email = em
    //     },

    //     setUserLogged(state, lg) {
    //         state.user_logged = lg
    //     },
        
    //     addUserPet(state, pet) {
    //         state.user_pets.push(pet)
    //     },


    // },

    // // all Async methods that chagne the state go here
    // actions: {
    // },

    // // Methods to do more complex operations on data then simply reading it (filtering etc)
    // getters: {
    // },

    // // Sub-parts, sub-gruous and other advanced stuff
    // modules: {
    // }


})
