<template>
    <div class="container q-px-xxs">
        <!-- HAS A VALID FLUID TO SHOW -->
        <div v-if='fetched?.ok'>
            <div class="row reverse-wrap items-center">
                <div v-if='fetched.attributes' class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 q-pa-sm">
                    <ScanDetails
                        v-bind:details= fetched.attributes
                        v-bind:buttons= details_buttons
                        v-bind:updates= fetched.updates
                        v-bind:copy   = "['chip','FaunaLife ID']"
                        title="Scheda:" 
                    />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 self-stretch">
                    <div class="row items-center">
                        <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 self-stretch q-pa-sm">
                            <PetProfileCard 
                                v-bind:editable="true"
                                v-bind:pet_fluid="ifluid"
                                @editAvatarEvent="goEditAvatar()"
                            />
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 self-stretch q-pa-sm">
                            <QRCard />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row col-sm" v-if='fetched.owners'>
                <ScanOwner />
            </div>
            <p/>


            <div class="separator-blank q-my-xl" v-if='is_owner && !pet_extras.mypetcard'>
                <div class="row justify-center">
                    <div
                        class="text-center q-pa-lg col-xs-8 col-sm-8 col-md-6 col-lg-6 col-xl-5 mesh_bg"
                        v-on:click='buyCard()'
                    >
                        <q-img
                            src="../assets/card_teaser.png"
                            style="max-height: 80px;"
                            fit="scale-down"
                            class="q-ma-sm"
                        >
                        </q-img>
                        Acquista MyPetCard per {{ fetched.rawattributes.name }}
                    </div>
                </div>

            </div>

            <div class="row col-sm">
                <ScanNotes />
            </div>
            <p/>
            <div class="row col-sm">
                <ScanDocs />
            </div> 
            <div class="row col-sm" v-if='fetched.family'>
                <ScanFamily />
            </div> 
            <p/>
        </div>

        <div v-else-if="fetched && !fetched.ok" class ="col-auto text-center">
            <!-- did fetch but got no data -->
            <h3>FLuID non trovato</h3>
            <h5>
                L'identificativo che cerchi non è stato trovato.<br>
                Controlla di averlo scritto correttamente e riprova.
            </h5>
        </div>

        <!-- NO FLUID TO SHOW : SEARCH-->
        <div v-else class="centered">
            <q-spinner-ios
                color="green"
                size="6em"
                :thickness="9"
            ></q-spinner-ios>
        </div>
    </div>
</template>

<script>
    import * as bctools from '../common/bctools' 
    import * as tools from '../common/tools'
    import * as config from '../common/config'

    import ScanDetails from '../components/Scan/Scan_Details'
    import ScanOwner from '../components/Scan/Scan_Owner'
    import ScanDocs from '../components/Scan/Scan_Docs'
    import ScanNotes from '../components/Scan/Scan_Notes'
    import ScanFamily from '../components/Scan/Scan_Family'
    import PetProfileCard from '../components/PetProfileCard.vue'
    import QRCard from '../components/QRCard.vue'

    export default {
        name: 'Scan',
        components: {
            ScanDetails,
            ScanOwner,
            ScanDocs,
            ScanNotes,
            ScanFamily,
            PetProfileCard,
            QRCard,
        },

        props : {            // values passed by caller Commponent via props
            fluid : {
                type: String,
                required : true,
                default : '0x00000000000000000000000000000000000000ZZ',
                validator : (fluid) => bctools.validAddress(fluid),
            },
            refresh : null
        },

        data()  {
            return {
                ifluid      : bctools.validAddress(this.fluid) ? this.fluid : -1,
                fetched     : false,
                searching   : false,
                is_logged   : tools.checkLogged(),
                pet_extras  : {} // MyPetCard and future use
            }
        },

        computed : {
            is_owner : function () {
                return tools.userHasPet(this.ifluid)
            },

            details_buttons : function () {
                let buttons=[]
                let share = {}
                share.title = 'La scheda di '+this.fetched.rawattributes.name+' su FaunaLife'
                share.text = 'La scheda di '+this.fetched.rawattributes.name+' su FaunaLife\n'
                share.url = location.href
                buttons.push({icon:'share' , url:'',  label:'', tooltip:'condividi', click:'share', share_data : share})
                if (this.fetched.hasOwnProperty('updates') ) {
                    buttons.push({icon:'restore' , url:'/hist/'+this.ifluid,  label:'', click:'go', tooltip:'Storico'})
                }
                if (this.is_owner) {
                    if (! this.fetched.rawattributes.death) {
                        buttons.push({icon:'edit' , url:'/edit/'+this.ifluid,  label:'', click:'go', tooltip:'Modifica'})
                    }
                    buttons.push({icon:'sync' , url:'/scan/'+this.ifluid+'/refresh',  label:'', click:'go', tooltip:'Aggiorna', click:'sync'})
                    buttons.push({icon:'print', url:'/print/'+this.ifluid, label:'', click:'open', tooltip:'Stampa'})
                }
                return buttons
            },
        },

        created() {
            this.refreshCache()
            this.fetchFluid()
            window.scrollTo(0,0)
        },

        methods: {

            refreshCache: async function() {
                if (this.refresh == "refresh") {
                    tools.cacheClear(this.ifluid)
                    this.$router.push({path : '/scan/'+this.ifluid})
                }
            },

            fetchFluid: async function() {
                this.fetched = await tools.cacheGetPet(this.ifluid)
                if (this.fetched?.ok) {
                    tools.storeFocusPet(this.ifluid)
                    // Get extras (MyPetCard)
                    if (this.fetched.extra) {
                        this.pet_extras = JSON.parse(JSON.stringify(this.fetched.extra))
                        if (this.fetched.extra.mypetcard) {
                            this.hascard = true        
                        }
                    }   
                } else {
                    this.fetched = true
                }
            },

            goEditAvatar : function() {
                if (this.is_owner) {
                    this.$router.push({path : '/avatar/'+this.ifluid})
                }
                // ???? This does not work :
                // this.$router.go({name : 'Avatar', params : { fluid: this.ifluid }})
            },

            buyCard : function() {
                let url = config.ShopBaseUrl+'/buy/'+this.ifluid
                window.open(url, '_blank');
            }
        }
    }
</script>

<style scoped>

    .mesh_bg {
        background-image: url("../assets/bg_mesh_ver.png");
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 25px;
        color : white  !important;
        font-size: large;
        font-weight: 700;
        text-shadow: 2px 4px 4px rgba(46,91,73,0.6);
    }

</style>