<template>
    <div id="logo"></div>
</template>
<style>
    #logo {
        background-image : url("../assets/logofauna.jpg");
        background-position: center;
        background-size : contain;
        background-repeat: no-repeat;
        height : 5.6rem;
        margin-bottom: 2rem;
    }
</style>