<template>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white"
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="goToPet()"
        ></q-btn>
    </div>

    <div class="container q-px-sm">
        <h3>Elenco delle modifiche:</h3>
        <br>
        <div v-for='(h, idx) in history' :key='idx'>
            <span class="text-h5">{{idx+1}}) {{h.timestamp}}</span> (<a v-bind:href=h.tx_url target="explorer">{{h.tx}})</a>
            <div class="row" v-for='(k,v,idx) in h.update' :key='idx' >
                <div class="detail-table-property col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    {{v}}:
                </div>
                <div class="detail-table-value col-xs-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                    <div v-html="k" style="width:100%;"></div>
                </div>
            </div>
            <br>
        </div>

    </div>
</template>

<script>
import * as bctools from '../common/bctools' 
import * as tools from '../common/tools'
import * as config from '../common/config'

export default {
    name: 'Hist',
    components: {
    },

    props : {            // values passed by caller Commponent via props
        fluid : {
            type: String,
            required : true,
            default : '0x00000000000000000000000000000000000000ZZ',
            validator : (fluid) => bctools.validAddress(fluid),
        }
    },

    data()  {
        return {
            ifluid : bctools.validAddress(this.fluid) ? this.fluid : -1,
            fetched : 'Loading...',
            html : 'Caricamento dati in corso...',
            history : []
        }
    },

    created() {
        window.scrollTo(0,0)
        this.fetchHistory()    
    },

    methods: {
        fetchHistory: async function() {
            // let fp = await tools.getFocusPet()
            // if (fp !== null && fp.fluid == this.ifluid) {
            //     this.fetched = fp
            // } else {
            //     // Go back as something is wrong
            //     this.$router.go(-1)
            //     return
            // }
            
            let fetched = await tools.apiGet('/fluid/hist/'+this.ifluid+'/?ord=t&translate=true&lang=IT')
            if (!fetched.ok) {
                console.error(fetched.message)
                this.html = "Dati non disponibili al momento. Riprovare più tardi"
                this.$router.go(-1)
                return
            }
            delete fetched.ok

            for (let step of fetched.history) {
                // Skip empty updates
                if (Object.keys(step.update).length == 0) {
                    continue
                }
                let block = {}
                block.timestamp=tools.date2EUDate(step.timestamp)
                block.tx = tools.shortHash(step.tx)
                block.tx_url = config.TrxBaseUrl+'/tx/'+step.tx
                block.update = await tools.translateDetails(step.update)
                for (let [key, value] of Object.entries(block.update)) {
                    if (key == 'documento') {
                        let html =''
                        for (let doc of value) {
                            html +=  "("+doc.doctype+'/'+doc.docsubtype+', hash: '+tools.shortHash(doc.dochash)+')<br>'
                        }
                        block.update['documento']=html
                    } else if (key == 'rawdata') {
                        block.update['dati grezzi'] = block.update['rawdata']
                        delete block.update['rawdata']
                    }
                }
                this.history.push(block)
            }

        },

        goToPet : async function () {
            let to = await tools.getFocusPet(false)
            this.$router.push('/scan/'+to)
        },
    }
}
</script>



<style>
</style>