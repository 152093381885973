<template>
  <component :is="layout">
    <router-view :key="$route.fullPath"  @evLogged="redrawHeader" />
  </component>
</template>

<script>
    import DefaultLayout from './components/Layout/DefaultLayout'
    import WithoutLogoLayout from './components/Layout/WithoutLogoLayout'
    const defaultLayout = 'DefaultLayout'
    
    export default {
        name: 'App',
        components: {
            DefaultLayout,
            WithoutLogoLayout
        },
        computed: {
          layout () {
            const layout = (this.$route.meta.layout || defaultLayout)
            // console.log(this.$route, {layout})
            return layout
          }
        },
        data ()  {
          return {
            hkey : 0
          }
        },

        methods: { 
          redrawHeader : function () {
            // Change che key of the router element to for re-rendering
            this.hkey++            
          }
        }
    }
</script>


<!--
    ********************************************************************
            My Styles
    ********************************************************************
-->
  

<style>

:root {
  --fl-green-1  : #3aa575;
  --fl-green-2  : #42b983;
  --fl-green-3  : #65B687;
  --fl-green-4  : #A0D1B5;

  --fl-pink-1   : #EFB097;
  --fl-red-1    : #FF0000;
  --fl-yellow-1 : #ffbb00;
  --fl-blue-1   : #174af4;


  /* --fl-font-g1  : #65B687; */
  --fl-font-G1  : #595959;
  --fl-font-G2  : #393939;

  --fl-gray-1   : #6b6b6b;
  --fl-gray-2   : #9b9b9b;
  --fl-gray-3   : #d1d1d1;
  --fl-gray-4   : #F9F9F9;
  --fl-gray-5   : #FBFBFB;

  --fl-whatermark : #eee;
}

h1 {
  margin : 5px !important;
  /* 
    https://royalfig.github.io/fluid-typography-calculator/ 
    https://www.marcbacon.com/tools/clamp-calculator/
  */
  font-size: clamp(4.5rem, -0.875rem + 8.333vw, 6rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}

h2 {
  margin : 5px !important;
  font-size: clamp(3rem, -0.875rem + 8.333vw, 4rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}


h3 {
  margin : 0px !important;
  font-size: clamp(1.625rem, 1.827rem + -0.897vw, 0.75rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}

h4 {
  margin : 0px !important;
  /* 20 -> 34 [360 - 1919] */
  font-size: clamp(1rem, 0.875rem + 0.5208333333333333vw, 1.5rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}

.font-small {
  margin : 0px !important;
  font-size: 16px !important;
  font-weight: 0 !important;
  color : var(--fl-green-3);
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--fl-font-G1);
  font-size: clamp(1rem, 0.875rem + 0.5208333333333333vw, 1.5rem) !important;
}

a {
  color : var(--fl-green-3);
  text-decoration: none;
}

a:hover {
  color : var(--fl-green-4);
  text-decoration: none;
}

a.router-link-exact-active {
  color: var(--fl-green-2) !important;
}

b {
  font-weight: 600 !important;
  color : var(--fl-green-3);
}

.fluid-details {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: var(--fl-font-G2);
  margin: auto;
}

.fluid-header {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    padding:3px; 
    font-size:16px; 
    border-style:solid; 
    text-align:center; 
    border-radius:6px; 
    border-width:1px; 
    border-color:var(--fl-green-3); 
    background-color: var(--fl-green-4); 
    font-style:none;
    font-weight:bold;
}

.scan-data {
    margin-left: 0px;
}

table.scan-table {
  border: 2px;
  border-width:1px; 
  width: 100%;
  text-align: left;
  table-layout:fixed
}
table.scan-table td, table.scan-table th {
  background:  var(--fl-green-4);
  border: 1px;
  border-style: solid;
  border-color: white;
  border-radius:6px; 
  padding: 2px 10px;
  margin: 8px 8px;
  word-wrap: break-word;
}
table.scan-table td:nth-child(1) {
  background: var(--fl-green-3);
  width: 25%;
}
table.scan-table tfoot td {
  font-size: 14px;
}

div.detail-table-property {
  text-align: left;
  background: white;
  border: 2px solid white;
  /* background: var(--fl-green-3); 
  border-color: white;
  border: 1px;
  border-style: solid; */


  border-radius:6px; 
  padding: 2px 8px;
  word-wrap: break-word;
}

div.detail-table-value {
  text-align: left;
  background: var(--fl-gray-4);
  border: 2px solid white;
  border-radius:6px; 
  padding: 2px 8px;
  word-wrap: break-word;
}

.detail-table-warning {
  text-align : right;
  color: var(--fl-gray-3);
  float: right;
  font-size: 75%;
  padding : 4px 2px;
}

.detail-table-warning.material-icons:active {
  color : white;
}


.details-shorten-value {
	white-space: nowrap;
	display : block;
	float : left;
	max-width :80%;
	overflow : hidden;
  text-overflow: ellipsis; 
}

div.petbg {
    background-image: url("/img/petbg.png");
    display: block;
}

div.detail-print-property {
  text-align: left;
  background: var(--fl-green-4);
  border: 1px;
  border-style: solid;
  border-color: white;
  border-radius:6px; 
  padding: 2px 8px;
  /* margin: 8px 8px; */
  word-wrap: break-word;
}

div.detail-print-value {
  text-align: left;
  background: white;
  border: 1px;
  border-style: solid;
  border-color: white;
  border-radius:6px; 
  padding: 2px 8px;
  /* margin: 8px 8px; */
  word-wrap: break-word;
}

div.detail-table-heading  {
    /* width: 100%;  */
    overflow: hidden;
}

div.detail-table-heading-title {
  float: left;
}

div.detail-table-heading-buttons {
  float: right;
}

.infogroup {
  margin : 10px;
  padding :10px;
  border-style: none;
  flex: 1 1 auto;
  max-width: 100%;
  overflow-x: auto;
}

.infogroup-dark {
  margin : 10px;
  padding :10px;
  background-color: var(--fl-gray-4);
  border-style: solid;
  border-width: 1px;
  border-radius:18px;
  border-color: var(--fl-gray-3);
  flex: 1 1 auto;
  max-width: 100%;
  overflow-x: auto;
}


.centered {
    text-align: center;
}

.righthand {
  float : right;
}

div.indented {
  margin-left: 50px;
}

div.devinfo {
font-family: Avenir, Helvetica, Arial, sans-serif;
    float: right;
    padding:3px; 
    font-size:9px; 
    color: var(--fl-whatermark);
    font-style:none;
    line-height: 220px;
}

.searchhead {
    margin : 60px;
    line-height: 40px;
    text-align: center;
}

.text-navigate {
  color: var(--fl-pink-1) !important;
}
.bg-navigate {
  background: var(--fl-pink-1) !important;
}


button.submit {
    margin : 10px 0 auto;
    -webkit-box-shadow: 1px 1px 8px 0 black;
    -moz-box-shadow: 1px 1px 8px 0 black;
    box-shadow: 1px 1px 8px 0 black;
    
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

button.submit:disabled {
    background: var(--fl-gray-2);
    color: var(--fl-gray-3);
    border: solid var(--fl-gray-1) 1px;
}

button.submit:hover:enabled {
    background:  var(--fl-gray-2);
    box-shadow: 1px 1px 6px rgba(0,0,0,0.61);
}

/* Simple CSS to avoid Avatar */
.img-rounder {
  width: -webkit-fill-available;
  height: auto;
  overflow: hidden;
  border-radius: 50%;  
}
</style>