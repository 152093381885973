<template>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white" 
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="$router.go(-1)"
        ></q-btn>
    </div>

    <div class="container q-px-xl">
        <h3 class="q-mb-xl">Aggiungi una nota descrittiva</h3>
        <form>
            
            <!-- Notes & More -->
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 q-my-lg q-px-lg">

                    Titolo o descrizione
                    <q-input v-model.lazy="pet_notes_title" type="input" 
                        outlined 
                        label-color="green"
                        color="green"
                        hint="Inserisci un titolo per questa nota" 
                        label="titolo"
                        maxlength=512
                        v-on:update:model-value="changed_title()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_title" size="25px"></q-icon>
                        </template>      
                    </q-input>
                    <br>
                    Note e caratteristiche
                    <q-input v-model.lazy="pet_notes" type="textarea" 
                        outlined 
                        label-color="green"
                        color="green"
                        hint="Inserisci note e caratteristiche importanti" 
                        label="testo della nota"
                        maxlength=4096
                        v-on:update:model-value="changed_notes()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_notes" size="25px"></q-icon>
                        </template>      
                    </q-input>
                    Tags
                    <q-input v-model.lazy="pet_notes_tags" type="input" 
                        outlined 
                        label-color="green"
                        color="green"
                        hint="Inserisci una o più tag per identificare la nota" 
                        label="tag, tag, tag"
                        maxlength=512
                        v-on:update:model-value="changed_tags()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_tags" size="25px"></q-icon>
                        </template>      
                    </q-input>

                </div>
            </div>


            <div v-if="check_can_send" class="q-mb-lg text-center">
                <div v-if='loading==false'>
                    <!-- Send Button  -->
                    <q-btn 
                        color="green"
                        text-color="white" 
                        size="lg"
                        rounded
                        icon="upload"
                        class="q-ma-sm" 
                        label="Invia" 
                        @click="preSubmit()"
                    ></q-btn>
                </div>
                <div v-else>
                    <!-- Spinner -->
                    <q-spinner-ios
                        color="green"
                        size="6em"
                        :thickness="9"
                    ></q-spinner-ios>
                </div>
            </div>
            <div v-else class="q-mb-lg text-center">
                <!-- Mandatory marker -->
                Compila i campi richiesti <q-icon name="pets" color="red" size="25px"/>
            </div>
        
        </form>

        <!-- Confirm Dialog -->
        <q-dialog v-model="confirm_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="warning" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Attenzione !</h4>
                    Una volta inviati, i dati che stai per inserire 
                    <ul>
                        <li><b>non sono modificabili</b> e</li> 
                        <li><b>non sono cancellabili</b>.</li>
                    </ul>
                    <p>Controlla i seguenti dati prima di procedere:</p>
                    Titolo: <span v-html="pet_notes_title"></span><br>
                    Nota: <span v-html="pet_notes"></span><br>
                    Tags: <span v-html="pet_notes_tags"></span><br>
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
                    <q-btn flat icon="check" label="Confermo" color="green" @click="submitPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Error Dialog
        <q-dialog v-model="error_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="warning" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Attenzione !</h4>
                    Dati inseriti incompleti o errati:
                    <span v-html="input_error"></span>
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog> -->

        <!-- Done Dialog -->
        <q-dialog v-model="done_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="check" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Fatto !</h4>
                    La transazione è stata presa in consegna. il nuovo profilo sarà visibile a breve.                    
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="OK" color="green" @click="backToPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>
 
 
 
<script>
    import * as tools from '../common/tools'
    import * as config from '../common/config'

    export default {
        name : 'AddNote',
        components: {
        },

        props : {   
        },

        data () {   
            return {
                // Form data
                pet_name : '',
                pet_tag : '',
                pet_notes : '',
                pet_notes_title : '',
                pet_notes_tags : '',

                // Icon Colors for fields markers
                icm_title : 'red',
                icm_notes : 'red',
                icm_tags  : 'red',

                // Session
                fluid : '',

                // Status
                loading : false,
                confirm_dialog_show : false,
                // error_dialog_show : false,
            } 
        },

        computed : {
            check_can_send : function () {
                return ( 
                    this.pet_notes_title.length > 0 &&
                    this.pet_notes_tags.length  > 0 &&
                    this.pet_notes.length > 0       
                )                
            },
        },

        methods: {

            changed_title : function () {
                this.icm_title = (this.pet_notes_title.length > 0) ? 'green' : 'red'
            },

            changed_notes : function () {
                this.icm_notes = (this.pet_notes.length > 0) ? 'green' : 'red'
            },

            changed_tags : function () {
                this.icm_tags = (this.pet_notes_tags.length > 0) ? 'green' : 'red'
            },


            checkLogged : function () {
                // If it's not a logged user redirect it to login
                if (!tools.checkLogged()) {
                    this.$router.push({path : '/login'})
                }
            },

            preSubmit: async function() {
                // minimal data check
                if (!this.check_can_send) {
                    return
                }
                // shows the popup that asks for a confirmation before sending
                this.confirm_dialog_show = true
            },

            submitPet : async function () {
                // Sanity check before sending     
                if (!this.check_can_send) {
                    return
                }

                // Start the spinner
                this.loading = true

                let payload = {}
                payload.doc = []

                // Send Document of type 90 (0x5A) for Notes if present
                // Turn text into blob
                let blob = new Blob([this.pet_notes ], {type: 'text/plain'})
                // Compute hash
                let txt_hash = '0x'+tools.sha256sum(this.pet_notes)
                payload.doc.push('0x5A'+txt_hash)
                
                // Add Document to pet
                let linked = await tools.apiFluidSetData(payload, this.fluid)
                if (!linked.ok) {
                    console.error(linked.message)
                    alert(linked.message)
                    this.loading = false
                    return
                }
                if (!linked.transactions || !Array.isArray(linked.transactions || linked.transactions.length==0)) {
                    console.error(linked.message)
                    alert("Error in timestamping transactions")
                    this.loading = false
                    return
                }

                // Upload File to AWS
                // Create form data
                let data = new FormData()
                data.append('file', blob, this.pet_notes_title+'.txt')
                data.append('tags', this.pet_notes_tags)
                data.append('hash', txt_hash)
                data.append('description', this.pet_notes_title)
                data.append('transaction', linked.transactions[0])
                let done = await tools.apiUploadDoc(data)
                if (!done.ok) {
                    console.error(done.message)
                    alert(done.message)
                    this.loading = false
                    return
                }
            
                // Stop spinner, show dialog 
                this.loading = false
                this.done_dialog_show = true                
            },

            // Go back to Pet Page
            backToPet : function () {
                // Might just add the new doc instead but...
                tools.clearFocusPet()
                this.$router.push('/scan/'+this.fluid)
            }
        },

        // Lifecycle Hooks
        async mounted() {
            this.checkLogged()
            this.fluid = (await tools.getFocusPet()).fluid
        },
    }
</script>
 
<style>
</style>