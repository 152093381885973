<template>
    <div class="text-center">        
        <h3>Cerca su FaunaLife.</h3>
        Puoi usare : <br>
        un numero di <b>chip</b> valido (ISO FDXB/FDXA),<br> 
        un id FaunaLife (<b>FLuID</b>),<br> 
        il nome di una <b>attività</b>, un <b>indirizzo</b>, <b>città</b>...
        <div class="separator-blank q-my-xl"></div>                
        <form v-on:submit.prevent="search"  class="form-floating" >
            <h3>Cosa cerchi?</h3>
            <div class="row">
                <div class="col-xs-1 col-sm-2 col-md-3 col-lg-4 col-xl-4">
                </div>
                <div class="col">
                    <q-input 
                        v-model.lazy="input_search" 
                        outlined 
                        rounded
                        type="text" 
                        hint="chip, id, nome, indirizzo, attività...">
                    </q-input>
                </div>
                <div class="col-xs-1 col-sm-2 col-md-3 col-lg-4 col-xl-4">
                </div>
            </div>
            <br>
            <div v-if='searching'>
                <q-spinner-ios
                    color="green"
                    size="6em"
                    :thickness="9"
                ></q-spinner-ios>
            </div>
            <div v-else>
                <q-btn 
                    color="green" 
                    text-color="white" 
                    rounded
                    size='lg'
                    icon="search"
                    class="q-ma-sm" 
                    label="Cerca" 
                    type="submit"
                ></q-btn>
            </div>
        </form>
    </div>
    <div class="text-center" v-if="results">
        <div class="separator-blank q-my-xl"></div>
        <h3>Risultati della ricerca:</h3>
        <div v-if="results.length>0">
            <div v-for='(res, idx) in results' :key='idx'>
                <a v-bind:href="res.page">
                    <q-img
                        v-bind:src="res.logo_url"
                        v-bind:width="logo_size"
                    />
                    {{res.biz_name}}
                </a>
            </div>
        </div>
        <div v-else>
            Nessun risultato
        </div>
        <div class="separator-blank q-my-xl"></div>
    </div>

    <!-- Skater IMG --> 
    <div class="text-center">
        <div class="separator-blank q-my-xl"></div>
        <img style="max-width: 600px;" src="/img/gfx/skater.svg" />
    </div>

    <!-- Error Dialog -->
    <q-dialog v-model="error_dialog_show" persistent>
        <q-card>
            <q-card-section class="row items-center">
                <q-avatar icon="warning" color="green"></q-avatar>
                <span class="q-ml-sm">
                <h4>Errore !</h4>
                Criterio di ricerca non valido.<br>
                Controlla il formato e riprova.
                </span>
            </q-card-section>
            <q-card-actions align="right">
                <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>


</template>

<script>
    import * as bctools from '../common/bctools' 
    import * as tools from '../common/tools'
    import * as config from '../common/config'

    export default {
        name: 'Search',
        components: {
        },

        props : {
        },

        data()  {
            return {
                input_search : "",
                error_dialog_show : false,
                results : false,
                logo_size : config.AvatarSmallSize+"px"
            }
        },

        methods: {
            search: async function() {
                // Is it a chip or FLUID ?
                this.searching = true
                let fluid = this.input_search

                // NON FLUID 
                if (!bctools.validAddress(fluid)) {
                    // Is it a CHIP?
                    if (tools.isValidChip(fluid, null)) {
                        let r = await tools.apiGet('/fluid/from/'+this.input_search+'/?new=false')
                        if (r.ok) {
                            // is a chip: it (should be) a pet
                            let exists = await tools.cacheGetPet(r.fluid)
                            if (exists && exists.ok) {
                                this.searching = false
                                this.$router.push({path : '/scan/'+r.fluid})
                                return
                            } 
                        }
                    } 
                    
                    // Some generic string ? -> Business ?
                    let biz = await tools.apiGet('/adv/search/'+this.input_search)
                    if (biz.ok) {
                        delete biz.ok
                        if (Array.isArray(biz) && biz.length > 0) {
                            // Multiple results , add local page
                            for (let b of biz) {
                                switch(parseInt(b.role_id)) {
                                    case 0 : // owner
                                    case 1 : // breeder
                                    case 3 : // group / association
                                        b.page = config.AppBaseUrl+'/user/'+b.fluid
                                        break
                                    case 2 : // vet
                                    case 4 : // company
                                    default :
                                        b.page = config.AppBaseUrl+'/biz/'+b.fluid
                                        break
                                }
                            }
                            this.results = biz
                            this.input_search = ''
                            this.searching = false
                            return
                        } else {
                            this.results = []
                        }
                        // What if biz is not an array? -> Error?
                        this.input_search = ''
                        this.searching = false
                        return
                    }
                }
                
                // Syntactically correct fluid: Is it a user, a biz or a pet?
                let exists = await tools.apiGetUser(fluid)
                if (exists.ok) {
                    // It's an existing User
                        this.searching = false
                        switch (parseInt(exists.attributes.role)) {
                            case 0 : // owner
                            case 1 : // breeder
                            case 3 : // group / association
                                this.$router.push({path : '/user/'+fluid})
                                break
                            case 2 : // vet
                            case 4 : // company
                                this.$router.push({path : '/biz/'+fluid})
                                break
                            default: 
                                // Unrecognized
                                console.error("User with unknown role")
                                break
                        }
                } else {
                    // Might be pet
                    this.searching = false
                    this.$router.push({path : '/scan/'+fluid})
                    return
                }

                // 404
                this.error_dialog_show = true
                console.error("Fallback1")
                this.input_search = ''
                this.searching = false
            },

        }
    }
</script>

<style>
</style>