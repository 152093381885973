<template>
    <div class="infogroup">
         <div class="detail-table-heading">
            <div v-if="owners_count > 1" class="detail-table-heading-title">
                <h3>Proprietari:</h3>
            </div>
            <div v-else class="detail-table-heading-title">
                <h3>Proprietario:</h3>
            </div>

            <div class="detail-table-heading-buttons" v-if="buttons">
                <q-btn
                color="green" 
                text-color="white" 
                rounded
                label="Cedi"
                size="md"
                icon="fast_forward"
                class="q-ma-sm" 
                @click="$router.push({path : '/transfer/'+fluid})"
                ></q-btn>
            </div>
        </div>

        <div v-if="owners_count" class='scan-data'>
            <div v-for='(link,  idx) in links' :key='idx'>
                <div class="row">
                    <div class="detail-table-property col-xs-6 col-sm-4 col-md-4 col-lg-4 col-xl-4" v-if="idx==0">
                        Proprietario attuale:
                    </div>
                    <div class="detail-table-property col-xs-6 col-sm-4 col-md-4 col-lg-4 col-xl-4" v-else>
                        Precedente n°{{idx}}:
                    </div>
                    <div class="detail-table-value col-xs-6 col-sm-8 col-md-8 col-lg-8 col-xl-8" v-if="shortHash">
                        <router-link v-bind:to="link.url">{{link.shortHash}}</router-link>
                    </div>
                    <div class="detail-table-value col-xs-6 col-sm-8 col-md-8 col-lg-8 col-xl-8" v-else>
                        <router-link v-bind:to="link.url" class="details-shorten-value" >{{link.hash}}</router-link>
                        <span v-if="cancopy"
                            class="detail-table-warning material-icons" 
                            v-on:click="clipboardCopy(link.hash)"
                        >
                            <q-tooltip anchor="top middle" self="top middle" :offset="[10, 10]">copia</q-tooltip>
                            content_copy
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as tools from '../../common/tools'
    export default {
        name : 'ScanOwner',

        props : {
            shortHash : {
                type: Boolean,
                required : false,
                default : false
            },
        },

        data () {
            return {
                links : null,
                buttons : false,
                owners_count : 0,
                fluid : '',
                cancopy : navigator.clipboard != undefined,
            }
        },

        created() {
            this.prepareHtml()    
        },

        methods: {
            prepareHtml: async function() {
                let fp = await tools.getFocusPet()
                if (fp == null) {
                    return
                }
                this.fluid = fp.fluid
                this.links =  []
                if (Array.isArray(fp.owners) && fp.owners.length > 0) {
                    this.owners_count = fp.owners.length
                    for (let oh of fp.owners) {
                        let oo = {}
                        oo.hash = oh
                        oo.shortHash = tools.shortHash(oh)
                        oo.url='/User/'+oh
                        this.links.push(oo)
                    }
                    // Check if this is the owner
                    this.buttons = tools.checkLogged(fp.owners[0]) && fp.rawattributes.death == undefined
                }
            },

			clipboardCopy: function (text) {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(text);
                }
			}
        }
    }
</script>


<style>
</style>