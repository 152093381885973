<template>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white"
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="$router.go(-1)"
        ></q-btn>
    </div>

    <div class="container q-px-xxs">
        <h3 class="q-px-xl">Inserisci i dati del tuo pet</h3>
        <form>

            <!-- Geolocation -->
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Paese
                    <q-select id="sel_state" v-model="sel_state" 
                        v-bind:options="opt_state"
                        v-on:update:model-value="update_region_list()"
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Seleziona il Paese di residenza"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_state" size="25px"></q-icon>
                        </template>                     
                    </q-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Regione
                    <q-select id="sel_region" v-model="sel_region" 
                        v-bind:options="opt_region"
                        v-on:update:model-value="changed_region()"
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        label=""
                        v-bind:disable="disable_region_and_chip"
                        hint="Seleziona la Regione di residenza"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_region" size="25px"></q-icon>
                        </template>                     
                    </q-select>                    
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    {{zip_name}}
                    <q-input id="zip" v-model.lazy="zip" type="text" 
                        outlined
                        rounded
                        label-color="green"
                        color="green"
                        hint="Inserisci il codice postale" 
                        label=""
                        mask="#####"
                        :error="zip_error"
                        error-message="Valore non conforme"
                        @update:model-value="isValidZip()"
                        @blur="isValidZip()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_zip" size="25px"></q-icon>
                        </template>
                    </q-input>
                </div>
            </div>

            <!-- NAME & TAG -->
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Nome del pet
                    <q-input id="pet_name" v-model.lazy="pet_name" type="text" 
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Inserisci il nome" 
                        label=""
                        maxlength=64
                        v-on:update:model-value="changed_name()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_name" size="25px"></q-icon>
                        </template>
                    </q-input>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Numero Microchip
                    <q-input id="pet_tag" v-model.lazy="pet_tag" type="text" 
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Inserire numero di microchip" 
                        label=""
                        :error="chip_error"
                        v-bind:disable="disable_region_and_chip"
                        error-message="Numero chip non conforme"
                        @update:model-value="isValidChip()"
                        @blur="isValidChip()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_chip" size="25px"></q-icon>
                        </template>                    
                    </q-input>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Data di nascita
                    <q-input v-model="date_birth" mask="##/##/####" :rules="[ d => date_regex.test(d) || 'Data errata']"
                        label-color="green"
                        color="green"                        
                        hint="Inserisci la data di nascita"
                        label=""
                        outlined
                        rounded
                        @focus="()=>{date_birth = (date_birth=='') ? todaystr : date_birth}"
                    >
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="date_birth" color="green"
                                v-bind:navigation-max-year-month="max_year_month"
                                mask="DD/MM/YYYY"
                                @update:model-value="() => {icm_birth = 'green'}"
                            >
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Ok" color="green" flat></q-btn>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer"></q-icon>
                        </template>
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_birth" size="25px"></q-icon>
                        </template>      
                    </q-input>
                </div>
            </div>

            <!-- Species & Breed -->
            <div class="row">
                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Specie
                     <q-select id="sel_species" v-model="sel_species" 
                        v-bind:options="opt_species"
                        v-on:update:model-value="update_breed_list()"
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        label="" 
                        hint="Seleziona la specie del tuo animale"
                     >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_sel_species" size="25px"></q-icon>
                        </template>                     
                     </q-select>
                </div>
                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Razza
                    <q-select 
                        outlined 
                        rounded
                        v-model="sel_breed" 
                        v-bind:options="opt_breed_filtered"
                        v-on:update:model-value="changed_breed()"
                        label-color="green"
                        color="green"
                        label=""
                        use-input
                        v-bind:disable="disable_breed"
                        @filter="filterBreed"
                        behavior="dialog"
                        hint="Seleziona la razza (Inizia a scrivere e scegli dalla lista)"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_sel_breed" size="25px"></q-icon>
                        </template>
                        <template v-slot:selected-item="scope">
                            <div class="ellipsis">{{scope.opt}}</div>
                        </template>
                        <q-tooltip class="text-h6">{{sel_breed}}</q-tooltip>                         
                    </q-select>
                </div>
            </div>

            <!-- Weight Build -->
            <div class="row">
                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Peso (kg)
                    <q-input id="pet_weight" v-model.lazy="weight" type="number" 
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Inserire il peso in kg (es: 2.400)" 
                        label=""
                        :error="weight_error"
                        error-message="Peso non conforme"
                        @update:model-value="isValidWeight()"
                        @blur="isValidWeight()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="white" size="25px"></q-icon>
                        </template>                    
                    </q-input>
                </div>

                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Taglia
                     <q-select id="sel_build" v-model="sel_build" 
                        :options="opt_build"
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        label="" 
                        hint="Seleziona la taglia (xxs...xxl)"
                     >
                        <template v-slot:after>
                            <q-icon name="pets" color="white" size="25px"></q-icon>
                        </template>                     
                     </q-select>
                </div>
            </div>

            <!-- Gender and Reproduction-->
            <div class="row">
                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    <div class="row items-center">
                        <div class="col-6 text-right">
                            Genere: <q-icon name="pets" :color="icm_gender" size="25px"></q-icon>
                        </div>
                        <div class="col-6 self-center">
                            <q-radio 
                                keep-color 
                                v-model="gender" 
                                val="M"   
                                label="Maschio" 
                                color="green" 
                                dense
                                @update:model-value="changed_gender(value, evt)"
                            ></q-radio><br>
                            <q-radio 
                                keep-color
                                v-model="gender"
                                val="F"
                                label="Femmina"
                                color="green"
                                dense
                                @update:model-value="changed_gender(value, evt)"
                            ></q-radio>
                        </div>
                    </div>
                </div>

                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    <div class="row items-center">
                        <div class="col-6 text-right">
                        Sterilizzato:
                        </div>
                        <div class="col-6">
                            <q-radio 
                                keep-color 
                                v-model="neutered" 
                                val="N"   
                                label="No" 
                                color="green" 
                                dense
                            ></q-radio><br>
                            <q-radio 
                                keep-color
                                v-model="neutered"
                                val="Y"
                                label="Si"
                                color="green"
                                dense
                            ></q-radio>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Send / complete footer -->
            <div  class="q-ma-xl text-center">
                <div v-if="computed_can_send">
                    <div v-if='loading==false'>
                        <!-- Send Button  -->
                        <q-btn 
                            color="green"
                            text-color="white" 
                            size="lg"
                            icon="upload"
                            rounded
                            class="q-ma-sm" 
                            label="Invia" 
                            @click="preSubmit()"
                        ></q-btn>
                    </div>
                    <div v-else>
                        <!-- Spinner -->
                        <q-spinner-ios
                            color="green"
                            size="6em"
                            :thickness="9"
                        ></q-spinner-ios>
                    </div>
                </div>
                <div v-else>
                    <!-- Mandatory marker -->
                    Compila i campi richiesti <q-icon name="pets" color="red" size="25px"/><br>
                    (<span v-html="input_error"></span>)
                </div>
            </div>
            
        </form>

        <!-- Confirm Dialog -->
        <q-dialog v-model="confirm_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="warning" color="green"></q-avatar>
                    <span class="q-ml-sm">
                        <h4>Attenzione !</h4>
                        <p>Controlla i seguenti dati prima di procedere:</p>
                    </span>
                </q-card-section>
                <q-card-section class="row items-center">
                    <span class="q-ml-sm">
                        Nome: <span v-html="pet_name"></span><br>
                        Numero di chip: <span v-html="pet_tag"></span><br>
                        Data di nascita: <span v-html="date_birth"></span><br>
                        Specie: <span v-html="sel_species"></span><br>
                        Razza: <span v-html="sel_breed"></span><br>
                        Genere: <span v-html="gender"></span><br>
                        <!-- <span v-if="date_sterilized != ''">Data sterilizzazione: <span v-html="date_sterilized"></span><br></span> -->
                        <span v-if="netured== 'Y'">Sterilizzato</span>
                        <!-- <span v-if="date_chip != ''">Data di impianto microchip:<span v-html="date_chip"></span><br></span> -->
                    </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
                    <q-btn flat icon="check" label="Confermo" color="green" @click="submitPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Error Dialog -->
        <q-dialog v-model="error_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="warning" color="green"></q-avatar>
                  <div class="q-ml-sm">
                    <h4>Attenzione !</h4>
                    Dati inseriti incompleti o errati:
                  </div>
                </q-card-section>
                <q-card-section class="row items-center">
                  <div v-html="input_error"></div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Chiudi" color="green" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Done Dialog -->
        <q-dialog v-model="done_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="check" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Fatto !</h4>
                    La transazione è stata presa in consegna. il nuovo profilo sarà visibile a breve.                    
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="OK" color="green" @click="backToUser" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>
  
<script>
    import * as tools from '../common/tools'
    import * as config from '../common/config'
    import Datepicker from 'vue3-datepicker'

    import VueCropper from 'vue-cropperjs'
    import 'cropperjs/dist/cropper.css'

    export default {
        name : 'AddPet',
        components: {
            Datepicker,
            VueCropper
        },

        props : {   
        },

        data () {   
            return {
                //Date Handling
                date_regex : config.Date_RegExp,

                // Form data
                pet_name :      '',
                pet_tag :       '',
                // pet_notes :     '',
                gender :        '',
                neutered :      "N",
                weight :        '',
                zip :           '',
                zip_name :      'CAP',

                // Icon Colors for fields markers
                icm_name :      'red',
                icm_chip :      'red',
                icm_sel_species:'red',
                icm_sel_breed:  'red',
                icm_birth:      'red',
                icm_state:      'red',
                icm_region:     'red',
                icm_zip:        'red',
                icm_gender:     'red',

                // Drop Down lists, selections and values
                species_list :  '',
                breed_list :    '',
                state_list :    '',
                region_list :   '',
                sel_species :   'Seleziona',
                sel_breed :     "",
                sel_build :     '',
                sel_state :     'Seleziona il Paese',
                sel_region :    'Seleziona la regione',
                opt_species :   '',
                opt_breed :     '',
                opt_breed_filtered : '',
                opt_build : ['XXS','XS','S','M','L','XL','XXL'],
                opt_state : '',
                opt_region : '',
                
                // Dates
                todaystr : '',
                max_year_month : '',
                date_birth : '',
                // date_chip : '',
                // date_sterilized : '',

                // Session
                owner_fluid : tools.getUser().user_fluid,

                // Error Handling
                weight_error :false,
                chip_error : false,
                zip_error : false,
                input_error : '',

                // Status
                loading : false,
                confirm_dialog_show : false,
                error_dialog_show : false,

                // Pre-values
                prevalues : {}
            } 
        },

        computed : {

            // chip_date_error : function () {
            //     let td = tools.euDate2Date(this.todaystr)
            //     let bd = (this.date_birth == '') ? td : tools.euDate2Date(this.date_birth)
            //     let cd = (this.date_chip == '') ? td : tools.euDate2Date(this.date_chip)
            //     if (cd > td)  {return true} // cant' be chipped in the future 
            //     if (this.date_birth != '' && (cd < bd)) {return true}   // can't be chipped before born
            //     // All good (false = no error)
            //     return false
            // },

            // neutered_date_error : function() {
            //     let td = tools.euDate2Date(this.todaystr)
            //     let bd = (this.date_birth == '') ? td : tools.euDate2Date(this.date_birth)
            //     let nd = (this.date_sterilized.length == 0) ? td : tools.euDate2Date(this.date_sterilized)

            //     if (nd > td)  {return true} // cant' be neutered in the future 
            //     if (this.date_birth != '' && (nd < bd)) {return true}   // can't be neutered before born
            //     // All good (false = no error)
            //     return false
            // },

            computed_can_send : function() {
                return this.check_can_send()
            },

            disable_breed : function() {
                // return true
                return !(Array.isArray(this.breed_list) && this.breed_list.length > 0)
            },

            disable_region_and_chip : function() {
                // return true
                return !(Array.isArray(this.region_list) && this.region_list.length > 0)
            }


        },

        methods: {

            changed_name : function() {
                this.icm_name = (this.pet_name.length >0)? 'green' : 'red'
            },

            changed_breed : function() {
                this.icm_sel_breed = ( this.opt_breed.includes(this.sel_breed) ) ? 'green' : 'red'
            },

            changed_gender: function (value, evt) {
                this.icm_gender = 'green'
            },

            changed_region: function () {
                this.icm_region = (this.opt_region.includes(this.sel_region)) ? 'green' : 'red'
            },

            check_can_send : function (showdialog=false) {
                let error = ''
                if (tools.checkLogged() == false)                               {error = 'Utente non loggato'} 
                if (this.chip_error)                                            {error = 'Chip non valido'} 
                if (this.isValidZip() == false)                                 {error = this.zip_name+' non valido'}
                if (this.isValidChip() == false)                                {error = 'Chip non valido'}
                if (this.zip == '')                                             {error = 'Specificare '+this.zip_name} 
                
                if (this.gender == '')                                          {error = 'Specificare sesso'} 
                if (this.date_birth == '')                                      {error = 'Indicare data di nascita'} 
                if (this.opt_region.includes(this.sel_region) == false)         {error = 'Selezionare Regione'} 
                if (this.opt_state.includes(this.sel_state) == false)           {error = 'Selezionare Stato'} 
                if (this.opt_breed.includes(this.sel_breed) == false)           {error = 'Selezionare Razza'} 
                if (this.opt_species.includes(this.sel_species) == false)       {error = 'Selezionare Specie'} 
                if (this.pet_tag == '')                                         {error = 'Inserire numero microchip'} 
                if (this.pet_name == '')                                        {error = 'Attribuire nome'} 

                let td = tools.euDate2Date(this.todaystr)
                let bd = (this.date_birth == '') ? td : tools.euDate2Date(this.date_birth)
                // let cd = (this.date_chip == '') ? td : tools.euDate2Date(this.date_chip)
                // let nd = (this.date_sterilized == '') ? td : tools.euDate2Date(this.date_sterilized)
                if ( 
                    // (cd < bd) || // can't be chipped before born
                    // (cd > td) || // can't chip in the future
                    (bd > td)    // can't be born in the future
                    // (nd > td) || // must be before today
                    // (nd < bd)    // must be afther birth
                ) { error = "Valori delle date non plausibili" }

                if (error == '') {
                    return true
                }
                // console.error(error)
                this.input_error = error
                this.error_dialog_show = showdialog
                return false
            },

            isValidZip : function() {
                // Empty zip string is not an error, but marker stays red
                if (this.zip == '') {
                    this.zip_error = false
                    this.icm_zip = 'red'
                    return true
                }
                // Otheerwise check content
                let zipRegEx =''
                switch (this.sel_state) {
                    case 'Switzerland' :
                        zipRegEx = "^[1-9][0-9]{3}$"
                        break
                    case 'Italy' :
                    default:
                        zipRegEx = "^[0-9]{5}$"
                        break
                }
                // Include empty string zipRegEx = "("+zipRegEx+")|^$"
                let rx = new RegExp(zipRegEx)
                if (this.zip.match(rx) !== null) {
                    this.zip_error = false
                    this.icm_zip = 'green'
                    return true
                }
                console.error("zip error")
                this.zip_error = true
                this.icm_zip = 'red'
                return false
            },

            isValidChip : async function() {
                // Check if supplied chip is syntacticaly valid and matches ISO for FDXB
                this.pet_tag = this.pet_tag.toUpperCase()
                let iso3166  = this.state_list ? this.state_list.filter(x => {return x.country_name == this.sel_state}).map(x=>{return x.iso3166}) : null
                if (!tools.isValidChip(this.pet_tag,iso3166)) {
                    this.chip_error = true
                    this.icm_chip = 'red'
                    return false
                }

                // Check if chip is already used
                let r = await tools.apiGet('/fluid/from/'+this.pet_tag+'/?new=true')
                if (r.ok==false) {
                    alert("Questo numero di chip è già stato registrato")
                    this.chip_error = true
                    this.icm_chip = 'red'
                    return false    
                } else {
                    this.chip_error = false
                    this.icm_chip = 'green'
                    return true
                }
            },

            isValidWeight : function() {
                let rx = new RegExp(/^([0-9]{1,3}[,.])?[0-9]{1,3}$/gm)
                let w = this.weight.toString()
                this.weight_error = (w.match(rx) == null)
                return !this.weight_error
            },

            checkLogged : function () {
                // If it's not a logged user redirect it to login
                if (!tools.checkLogged()) {
                    this.$router.push({path : '/login'})
                }
            },

            getPrevalues : async function () {
                let user = tools.getUser()
                if (user.pets && user.pets.length > 0) {
                    let pet = await tools.cacheGetPet(user.pets[0])
                    this.prevalues.state  = pet.rawattributes.geo_state
                    this.prevalues.region = pet.rawattributes.geo_region
                    this.prevalues.zip    = pet.rawattributes.geo_zip

                    // Set the values
                    this.sel_state = pet.rawattributes.geo_state
                    await this.update_region_list()
                    this.sel_region = pet.rawattributes.geo_region
                    this.changed_region()
                    this.zip = this.prevalues.zip
                }
            },

            initialize : async function () {
                // Set Dates
                const yourDate = new Date()
                // this.todaystr = yourDate.toISOString().split('T')[0]
                this.todaystr = yourDate.toLocaleDateString(config.Date_Locale, config.Date_Options)
                this.max_year_month = yourDate.getFullYear()+'/'+(yourDate.getMonth()+1)
                // this.date_birth=this.todaystr

                // Get species values
                this.species_list = await tools.apiGet('/util/lookup/species?distinct=true&cols=id,IT as species_name')
                if (!this.species_list.ok) {
                    console.error(this.species_list.message)
                    return
                }
                this.opt_species = this.species_list.filter(x => {return x.id != '0x00'}).map(x => {return x.species_name} )
                this.update_breed_list()

                // Get geo values
                this.state_list =  await tools.apiGet('/util/lookup/countries/?distinct=true')
                if (!this.state_list.ok) {
                    console.error(this.state_list.message)
                    return
                }
                this.opt_state = [ ...new Set(this.state_list.map(x => {return x.country_name}))]
            },

            update_breed_list : async function () {
                if ( this.opt_species.includes(this.sel_species) == false) {
                    this.icm_sel_species = 'red'
                    return
                }
                this.icm_sel_species = 'green'

                let species_id = this.species_list.filter(x => {return x.species_name == this.sel_species}).map(x=>{return x.id})
                this.breed_list = await tools.apiGet('/util/lookup/breeds/?distinct=true&species_id='+species_id+'&cols=breed_id,IT as breed_name,EN,ORIGINAL')
                if (!this.breed_list.ok) {
                    console.error(this.breed_list.message)
                    return
                }
                delete this.breed_list.ok
                
                // remove empty lines and order by name 
                // some names might have a number in front to alter ordering
                this.breed_list = this.breed_list
                    .filter(x => {return x.breed_name && x.breed_name != ''})
                    .sort( (a,b)=> { 
                        let o = a.breed_name.localeCompare(b.breed_name, 'it')  
                        return (o > 0) ? 1 : ( (o<0)? -1 : 0 )
                    })

                // Compose "complex" option list = "name (english, original)"
                this.opt_breed = this.breed_list.map(x=>{
                    // Remove "numbers before name" ordering trick
                    let opt = x.breed_name.replace(/^[0-9]+ /, '') 
                    // Add Eng and Original between brackets and remove duplicates
                    // Put into two sets to remove duplicates
                    let opt_set = new Set(opt.split(', '))
                    let en = x.EN.replace(/^[0-9]+ /, '')
                    let or = x.ORIGINAL.replace(/^[0-9]+ /, '')
                    let oth_set = new Set([en, or].join(', ').split(', '))
                    oth_set.delete('')
                    // Get element of eng+orig that are not in name
                    let diff = [...oth_set].filter( x => { return !opt_set.has(x)})
                    if (diff.length > 0) {
                        opt += ' ('
                        opt += (diff.length > 1) ? diff.join(', ') : diff[0]
                        opt += ')'
                    }
                    return opt
                })
                if (this.opt_breed.length == 0) {
                    this.opt_breed[0] = '(non definito)'
                }
                this.opt_breed_filtered = this.opt_breed
                this.sel_breed = ""
                this.icm_sel_breed = 'red'
            },

            update_region_list : async function () {
                if(!this.opt_state.includes(this.sel_state)) {
                    this.icm_state = 'red'
                    this.icm_region = 'red'
                    return
                } 
                this.icm_state = 'green'
                this.icm_region = 'red'

                let state_id = this.state_list.filter(x => {return x.country_name == this.sel_state}).map(x=>{return x.id})
                this.region_list = await tools.apiGet('/util/lookup/regions/?distinct=true&country_id='+state_id)
                if (!this.region_list.ok) {
                    console.error(this.region_list.message)
                    return
                }
                delete this.region_list.ok
  
                // remove empty lines and order by name 
                // some names might have a number in front to alter ordering
                this.region_list = this.region_list
                    .filter(x => {return x.region_name && x.region_name != ''})
                    .sort( (a,b)=> { 
                        // return (a.region_name > b.region_name) ? 1 : ((a.region_name < b.region_name) ? -1 : 0 ) 
                        let o = a.region_name.localeCompare(b.region_name, 'it', { sensitivity: 'variant' })  
                        return (o > 0) ? 1 : ( (o<0)? -1 : 0 )
                    })
                this.opt_region = this.region_list.map(x=>{return x.region_name})
                this.sel_region = 'Seleziona la regione'

                // Set the propre ZIP Code name
                switch (this.sel_state) {
                    case 'Switzerland' :
                        this.zip_name = 'NPA'
                        break
                    case 'Italy' :
                    default:
                        this.zip_name = 'CAP' 
                        break
                }
                this.isValidZip()
            },

            filterBreed : async function(val, update) {
                update(() => {
                    const needle = val.toLocaleLowerCase()
                    this.opt_breed_filtered = this.opt_breed.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
                })
            },

            preSubmit: async function() {
                // minimal data check
                if (!this.check_can_send(true)) {
                    return
                }
                // shows the popup that asks for a confirmation before sending
                this.confirm_dialog_show = true
            },

            submitPet : async function () {
                // Sanity check before sending     
                if (!this.check_can_send(true)) {
                    return
                }

                // Start the spinner
                this.loading = true

                let payload = {}
                payload.idtag = this.pet_tag
                payload.name = this.pet_name
                payload.birth = (this.date_birth == '') ? undefined : tools.euDate2ISO(this.date_birth)
                payload.regdate = tools.euDate2ISO(this.todaystr)
                // payload.chipdate = (this.date_chip == '') ? undefined : tools.euDate2ISO(this.date_chip)
                // payload.neutered = (this.date_sterilized == '') ? undefined : tools.euDate2ISO(this.date_sterilized)
                payload.neutered_b = this.neutered == 'Y' ? 1 : undefined
                payload.owner = this.owner_fluid
                
                payload.species = this.species_list.filter(x => {return x.species_name == this.sel_species}).map(x=>{return x.id})[0]
                payload.breed = this.breed_list[this.opt_breed.indexOf(this.sel_breed)].breed_id

                payload.geo_state = this.state_list.filter(x=>{return x.country_name==this.sel_state}).map(x=>{return x.id})[0]
                payload.geo_region = this.region_list.filter(x=>{return x.region_name==this.sel_region}).map(x=>{return x.region_id})[0]
                payload.geo_zip = this.zip

                payload.gender = this.gender

                // Prepare empty docs array for later
                payload.doc = []

                // weight
                let local_weight = parseFloat(this.weight)*1000
                if (!isNaN(local_weight) &&  local_weight > 0 && local_weight < 999999) {
                    payload.weight = local_weight
                }

                // build
                let local_build = this.opt_build.indexOf(this.sel_build)
                if (local_build >= 0 ) {
                    payload.build = local_build
                }

                // Get the fluid ID first
                let pet_fluid = await tools.apiGet('/fluid/from/'+payload.idtag)
                if (!pet_fluid.ok) {
                    console.error(pet_fluid.message)
                    alert(pet_fluid.message)
                    this.loading = false
                    return
                }
                pet_fluid = pet_fluid.fluid

                // Compose Pet TX
                if (this.imageSrc !== null && this.imageSrc !== undefined) {
                    payload.doc.push('0x59'+this.img_hash)
                }

                // Link documents to pet
                if (payload.doc.length == 0) {
                    delete payload.doc
                }
                let linked = await tools.apiFluidSetData(payload, pet_fluid)
                if (!linked.ok) {
                    console.error(linked.message)
                    alert(linked.message)
                    this.loading = false
                    return
                }
                if (!linked.transactions || !Array.isArray(linked.transactions || linked.transactions.length==0)) {
                    console.error(linked.message)
                    alert("Error in timestamping transactions")
                    this.loading = false
                    return
                }
                
                // Add the pet to the stored user
                let user = tools.getUser()
                // ... in case this is the first pet
                if (user.pets == undefined) { user.pets = [] }
                user.pets.push(pet_fluid)
                tools.storeUser(user)

                // Stop spinner, show dialog 
                this.loading = false
                this.done_dialog_show = true                
            },

            // Go back to User Page
            backToUser : function () {
                this.$router.push('/user/'+this.owner_fluid)
            }
        },

        // Lifecycle Hooks
        async mounted() {
            window.scrollTo(0,0)
            this.checkLogged()
            await this.initialize()
            await this.getPrevalues()
        },
    }
</script>
 
 
<style>
/* 
    those css values apply only to the .avatar_cropper div in this page
    used to give the circle selection to the cropper
*/
.avatar_cropper .cropper-view-box {
	box-shadow: 0 0 0 1px #39f;
	border-radius: 50%;
	outline: 0;
}
.avatar_cropper .cropper-face {
	background-color:inherit !important;
}
.avatar_cropper .cropper-view-box {
	outline:inherit !important;
}
</style>