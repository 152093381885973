<template>
    <!-- TAB-TABS -->
    <div class="infogroup-dark">
        <div class="detail-table-heading">
            <div class="detail-table-heading-title">
                <h3>Famiglia:</h3>
            </div>
        </div>
        <div v-if='this.parents.length>0' class="q-px-md">
            <div class="q-px-md">
                <h4>Genitori</h4>
            </div>
            <div class="q-px-md">
                <ScanPetGrid :pet_list="this.parents" />
            </div>
        </div>
        <div v-if='this.offsprings.length>0' class="q-px-md">
            <div class="q-px-md">
                <h4>Figli</h4>
            </div>
            <div class="q-px-md">
                <ScanPetGrid :pet_list="this.offsprings" />
            </div>
        </div>
    </div>
</template>

<script>
  import * as tools     from '../../common/tools'
  import ScanPetGrid    from './Scan_PetGrid.vue'

  export default {
    name : 'ScanFamily',

    components: {
        ScanPetGrid
    },
 
    props : {
    },
 
    data () {
        return {
            parents : [],
            offsprings : [],
        }
    },
 
    // Lifecycle Hooks
    async created() {
        await this.getData()
    },

    methods: {
        getData : async function () {
            let fp = await tools.getFocusPet()
            if (fp == null) {
                return
            }            
            // Create 2 arrays potentially empty arrays but with no null elements
            this.parents = [fp.family.mother, fp.family.father].filter (n=>n)
            if (fp.family.offsprings) {
                this.offsprings = [...fp.family.offsprings].filter(n=>n)
            }
        },
    }
}
</script>


<style>
</style>