<template>
    <div v-if="referrals.length > 0">
        <div class="separator-blank q-my-xl">
            <h3 class='q-px-lg'>I tuoi preferiti:</h3>
            <div class="infogroup">
                <q-virtual-scroll
                    :items="referrals"
                    virtual-scroll-horizontal
                    v-slot="{ item, index }"
                >
                    <div :key="index" 
                        v-on:click='manageClick(item.link_url)'
                    >
                        <q-img
                            :src="item.logo_url"
                            spinner-color="white"
                            v-bind:height = square_banner_size
                            v-bind:width = square_banner_size
                        ></q-img>
                    </div>
                    <q-separator vertical spaced></q-separator>
                </q-virtual-scroll>
            </div>
        </div>
    </div>
</template>

<script>
  import * as config from '../../common/config'
  import * as tools from '../../common/tools'

  export default {
    name : 'ScanReferrals',
 
    props : {
    },
 
    data () {
        return {
            referrals : [],
            square_banner_size : config.SquareAdvSize+'px',
            query_string : '',
        }
    },
 
    // Lifecycle Hooks
    async created() {
        await this.getData()
    },

    methods: {
        getData : async function () {
            let user = tools.getUser()
            let referrals = await tools.apiGetReferrals(user.user_fluid)
            tools.shuffleArray(referrals)

            this.referrals = []
            for (let i in referrals) {
                let biz = await tools.apiGetBusiness(referrals[i])
                if (biz.ok) {
                    delete biz.ok
                    biz=biz[0]
                    let item = {}
                    item.logo_url = biz.logo_url
                    item.link_url = "/biz/"+biz.fluid
                    this.referrals.push(item)
                }
            }

        },

        manageClick : function (url) {
            // window.open(url+this.query_string, '_blank');
            this.$router.push({path : url})
        }
    }
}
</script>


<style>
</style>