<template>
    <div class='infogroup-dark text-center'
        v-on:click="this.$router.push({path : '/addpet'})"
    >

            <div class="img-rounder">
            <q-img
                ratio=1
                v-bind:src="action_img"
                spinner-color="white"
            >
            </q-img>
        </div>
        <h3>{{ action_name }} </h3>
    </div>
</template>


<script>
    import * as config from '../common/config'
    export default {
        name : 'PetActionCard',
        components: {
        },

        props : {
            card_action : String,
        },

        data()  {
            return {
                action_img : '',
                action_name : '',
                avatar_size : config.AvatarSize+'px',
            }
        },   

        computed : {         // Computed on the spot from data values
        },

        methods: {           // Component functions
            makeCard : async function() {
                switch (this.card_action) {
                    case "new" :
                        // this.action_img = '<img src="/img/icons/add_pet.png"></img>'
                        this.action_img = '/img/icons/add_pet.png'
                        this.action_name = "Nuovo"
                        break;
                    case "new_owner" :
                        // this.action_img = '<img src="/img/icons/user.png"></img>'
                        this.action_img = '/img/icons/user.png'
                        this.action_name = "???"
                        break;
                    default :
                        console.error("unrecognized ation: ", this.card_action)
                }
            },

            goAdd : function() {
                this.$router.push({path : '/addpet'})
            }
        },

        watch: {             // Stuff that happend when a variable changes
        },

        // Lifecycle Hooks

        mounted() {
        },

        created() {
            this.makeCard()
        },
    }
</script>



// #css
<style>
.actionpic {
  width: v-bind(avatar_size) !important;
  height: v-bind(avatar_size) !important;
}
</style>