
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyASXT-mXTShwEmzhjvMdR2rGw41hNxd7YA",
  authDomain: "faunalife-auth.firebaseapp.com",
  projectId: "faunalife-auth",
  storageBucket: "faunalife-auth.appspot.com",
  messagingSenderId: "18361950334",
  appId: "1:18361950334:web:da8c6c6cdd56c26977f82f", 
  measurementId: "G-LY8GBK6NP5"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// Authentication method
const auth = getAuth(app)

export {
    auth,
    analytics
}