<template>
    <div class="container">
        <div class='row'>
            <div v-if="its_me" class='q-px-lg'>
                <h3>Utente: {{user_email}}</h3>
            </div>
            <div v-else-if="biz && biz.biz_name" class='q-px-lg'>
                <h3>Utente: {{ biz.biz_name }}</h3>
            </div> 
            <div v-else class='q-px-lg'>
                <h3>Utente: {{ short_hash }}</h3>
            </div>            

            <!-- Unfollow Button -->
            <!-- <div v-if="!its_me" class='q-px-lg'>
                <q-btn
                    v-if="this.is_follower"
                    color="green" 
                    text-color="red" 
                    rounded
                    size="sm"
                    icon="favorite"
                    class="q-ma-sm"
                    @click="toggleFollow()"
                >
                    <q-tooltip class="text-h6">sei follower</q-tooltip>
                </q-btn>

                < !-- Follow button -- >
                <q-btn
                    v-else
                    color="green" 
                    text-color="white" 
                    rounded
                    size="sm"
                    icon="favorite_border"
                    class="q-ma-sm"
                    @click="toggleFollow()"
                >
                    <q-tooltip class="text-h6">segui</q-tooltip>
                </q-btn>
            </div> -->
        </div>

        <div class="detail-table-heading infogroup" v-if="fetched.ok && biz">
            <div class="detail-table-heading-title" v-if="biz.logo_url">
                <q-img
                    loading="lazy"
                    :width="banner_size"
                    :src="biz.logo_url"
                ></q-img>
            </div>
            <div v-else class="detail-table-heading-title">
                <h3>{{biz.biz_name}}</h3>
            </div>
        </div>

        <div v-if='fetched.ok'>
            <ScanDetails 
                v-bind:details=fetched.attributes
                v-bind:buttons= details_buttons
                v-bind:copy   = "['FaunaLife ID']"
                v-bind:title  ="this.details_title"
            />

            <div class="separator-blank q-my-xl"></div>
        </div>
        <div v-else class="centered">
            <q-spinner-ios
                color="green"
                size="6em"
                :thickness="9"
            ></q-spinner-ios>
        </div>

        <div v-if="biz.iframe_url" class="h_iframe">
            <div class="separator-blank q-my-xl"></div>
            <div class="text-center">
                <iframe
                    id ="biz_iframe"  
                    ref="biz_iframe"
                    frameborder="0"
                    v-bind:src="biz.iframe_url"
                    width="95%"
                    height="300px"
                    style="border:none;"
                />
            </div>
        </div>
            
        <div v-if="maps_url" class='q-px-lg'>
            <div class="separator-blank q-my-xl"></div>
            <h3>Dove si trova:</h3>
            <div class="text-center">
                <iframe 
                    width="95%"
                    height="450"
                    frameborder="0" style="border:0"
                    referrerpolicy="no-referrer-when-downgrade"
                    v-bind:src="maps_url"
                    allowfullscreen
                >
                </iframe>
            </div>
        </div>

        <div class="separator-blank q-my-xl"></div>
        <!-- PETS -->
        <div class='q-px-lg'>
            <div  v-if="its_me" >
                <h3>I tuoi pet:</h3>
            </div>            
            <div v-else>
                <h3>I suoi pet:</h3>
            </div>
            <div class="row">
                <div class="col col-xs-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    Seleziona un profilo
                </div>
                <div class="col text-right col-xs-2 col-sm-2 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <q-toggle 
                        color="green"
                        v-model="showSearch"
                        icon="search"
                        v-on:update:model-value="showSearchUpdate"
                    ></q-toggle>
                      
                </div>                 
            </div>
            <!-- Search & Filter -->
            <div class='infogroup-dark container' v-if="showSearch">
                <div class="row col-12">Ordina per:</div>
                <div class="q-mx-md font-small">
                    <div class="row">
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-radio
                                class="q-ma-sm"
                                keep-color 
                                v-model="sortCriteria" 
                                val="alphaNameSort"   
                                label="Nome" 
                                color="green" 
                                dense
                                @update:model-value="sortFilterPets('alphaNameSort', null)"
                            ></q-radio>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-radio
                                class="q-ma-sm"
                                keep-color 
                                v-model="sortCriteria" 
                                val="ageSort"   
                                label="Età" 
                                color="green" 
                                dense
                                @update:model-value="sortFilterPets('ageSort', null)"
                            ></q-radio>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-radio
                                class="q-ma-sm"
                                keep-color 
                                v-model="sortCriteria" 
                                val="weightSort"   
                                label="Peso" 
                                color="green" 
                                dense
                                @update:model-value="sortFilterPets('weightSort', null)"
                            ></q-radio>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-radio
                                class="q-ma-sm"
                                keep-color 
                                v-model="sortCriteria" 
                                val="buildSort"   
                                label="Taglia" 
                                color="green" 
                                dense
                                @update:model-value="sortFilterPets('buildSort', null)"
                            ></q-radio>
                        </div>
                    </div>
                </div>
                <div class="row col-12">Filtra per:</div>
                
                <div class="q-mx-md font-small">
                    <div class="row">
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-toggle
                                dense
                                size="sm"
                                class="q-ma-sm"
                                toggle-indeterminate
                                label='Genere'
                                v-model="filterGender"
                                color="green"
                                keep-color
                                checked-icon="female"
                                unchecked-icon="male"
                                @update:model-value="sortFilterPets('', null)"
                            >
                            </q-toggle>
                        </div>
                        <!-- specie / razza (comporre liste ?)  -->
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-toggle
                                dense
                                size="sm"
                                class="q-ma-sm"
                                toggle-indeterminate
                                label='Ha note'
                                v-model="filterHasNotes"
                                color="green"
                                keep-color
                                checked-icon="done"
                                unchecked-icon="close"
                                @update:model-value="sortFilterPets('', null)"
                            >
                            </q-toggle>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-toggle
                                dense
                                size="sm"
                                class="q-ma-sm"
                                toggle-indeterminate
                                label='Documenti'
                                v-model="filterHasDocs"
                                color="green"
                                keep-color
                                checked-icon="done"
                                unchecked-icon="close"
                                @update:model-value="sortFilterPets('', null)"
                            >
                            </q-toggle>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <q-toggle
                                dense
                                size="sm"
                                class="q-ma-sm"
                                toggle-indeterminate
                                label='Sterilizzato'
                                v-model="filterIsNeutered"
                                color="green"
                                keep-color
                                checked-icon="done"
                                unchecked-icon="close"
                                @update:model-value="sortFilterPets('', null)"
                            >
                            </q-toggle>
                        </div>
                    </div>
                </div>
            </div> 

            <!-- PETS GRID -->
            <div v-if="show_pets">
                <div class="container" :key="refresher">
                    <div class="row justify-center">
                        <div v-for='(id, idx) in presented_pets' :key='idx' 
                            class="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 q-my-sm"
                        >
                            <PetProfileCard v-bind:pet_fluid=id />
                        </div>
                        <div v-if="its_me" 
                            class="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 q-my-sm"
                        >
                            <PetActionCard card_action="new" />
                        </div>
                    </div>
                </div>
    
                <div v-if="fetched.pets_transfered && fetched.pets_transfered.length>0" class='q-px-lg' :key="refresher">
                    <div class="separator-blank q-my-xl"></div>
                    <h3>Ceduti a nuovo proprietario:</h3>
                    <ScanPetGrid :pet_list="presented_pets_transfered" />
                </div>
    
                <div v-if="fetched.pets_dead && fetched.pets_dead.length>0" class='q-px-lg' :key="refresher">
                    <div class="separator-blank q-my-xl"></div>
                    <h3>Deceduti:</h3>
                    <ScanPetGrid :pet_list="presented_pets_dead" :gray=true />
                </div> 
            </div>
            <div v-else class="centered">
                <q-spinner-ios
                    color="green"
                    size="6em"
                    :thickness="9"
                ></q-spinner-ios>
            </div>
        </div>

        <!-- Promos & Refs -->
        <div v-if="its_me">
            <ScanPromos />
            <!-- <ScanReferrals /> -->
        </div>

        <!-- Popup -->
        <q-dialog 
            v-model="popup_ready" 
            persistent
            full-width
        >
            <q-card>
                <q-card-section>
                    <div class="row">
                        <div class="text-h6 col-8 q-pa-sm">{{popup_content.title}}</div>
                        <div class="col-4">
                            <q-card-actions align="right">
                                <q-btn flat icon="close" label="OK" color="green" @click="backToUser" v-close-popup></q-btn>
                            </q-card-actions>
                        </div>
                    </div>
                </q-card-section>
                <q-separator></q-separator>

                <q-card-section>
                    <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        infinite
                        v-model="popup_slide"
                        control-color="primary"
                        arrows
                        padding                        
                    >
                        <q-carousel-slide 
                            class="column no-wrap flex-center" 
                            v-for="(slide, idx) in popup_content.carusel" 
                            :key='idx'
                            :name='slide.name'
                            :img-src='slide.img'
                        >
                            <span v-html="slide.html" class="scroll"></span>
                            <div v-if="slide.button_txt">    
                                <div class="separator-blank q-my-xl"></div>
                                <q-btn
                                    color="green"
                                    text-color="white"
                                    rounded
                                    size="lg"
                                    class="q-ma-sm" 
                                    type="submit"
                                    v-bind:label='slide.button_txt'
                                    @click="goToSite(slide.button_to, '_blank')"
                                ></q-btn>
                            </div>    
                        </q-carousel-slide>
                    </q-carousel>
                </q-card-section>
            </q-card>
        </q-dialog>

        <br>
    </div>
</template>


<script>
    import { getCurrentInstance } from 'vue';
    import * as bctools     from '../common/bctools' 
    import * as tools       from '../common/tools'
    import * as config      from '../common/config'

    import PetProfileCard   from '../components/PetProfileCard'
    import PetActionCard    from '../components/PetActionCard'
    import ScanDetails      from '../components/Scan/Scan_Details'
    import ScanPetGrid      from '../components/Scan/Scan_PetGrid.vue'
    import ScanPromos       from '../components/Scan/Scan_Promos.vue'
    import ScanReferrals    from '../components/Scan/Scan_Referrals.vue'

    export default {
        name : 'User',
        components: {
            PetProfileCard,
            PetActionCard,
            ScanDetails,
            ScanPetGrid,
            ScanPromos,
            ScanReferrals,
        },
    
        props : {            // values passed by caller Commponent via props
            user_fluid : {
                type: String,
                required : true,
                default : null,
                validator : (fluid) => bctools.validAddress(fluid),
            },
        },


        data()  {
            return {
                fetched         : {},
                historylink     : null,
                banner_size     : config.SquareAdvSize+'px',
                its_me          : false,
                is_follower     : false,
                iframe          : null,
                biz             : false,
                show_pets       : false,
                maps_url        : null,
                key             : config.MapsApiKey,
                iframe_padding  : "400px",
                details_title   : 'Dati del proprietario',

                // Sort & Filfer
                sortCriteria    : '',
                showSearch      : false,
                refresher       : 0,
                presented_pets              : [],
                presented_pets_dead         : [],
                presented_pets_transfered   : [],
                filterGender    : null,
                filterHasNotes  : false,
                filterHasDocs   : false,
                filterIsNeutered: false,

                // Popups
                popup_show      : true,
                popup_ready     : false,
                popup_slide     : '',
                popup_content   : {},

                // Shop & Promos
                user_hascard    : false,
            }
        },    

        computed : {                // Computed on the spot from data values
            details_buttons : function () {
                let buttons=[]
                let share = {}
                share.title = ''

                share.url = location.href
                if (this.its_me) {
                    share.title = 'Il mio profilo su FaunaLife'
                    share.text  = 'Il mio profilo su FaunaLife:\n'
                    share.text  += 'Email: '+this.user_email+"\n"
                    share.text  += 'FLuID: '+this.user_fluid+"\n"
                    buttons.push({icon:'share' , url:'',  label:'', tooltip:'condividi', click:'share', share_data : share})
                }
                if (this.biz) {
                    share.title = 'La landing page di '+this.biz.biz_name+' su FaunaLife'
                    share.text = 'La landing page di '+this.biz.biz_name+' su FaunaLife\n'
                    buttons.push({icon:'share' , url:'',  label:'', tooltip:'condividi', click:'share', share_data : share})
                    buttons.push({icon:'open_in_new' , url: this.biz.site_url,  label:'', tooltip:'sito', click:'open'})
                    buttons.push({icon:'mail' , url:this.biz.email,  label:'', tooltip:'email', click:'open'})
                    buttons.push({icon:'phone' , url:this.biz.phone,  label:'', tooltip:'chiama', click:'open'})
                }
                return buttons
            }
        },
    
        methods: {

            /**
             *  Get the user profile data
             */
            getProfile : async function() {
                let ifluid
                if (bctools.validAddress(this.user_fluid)) {
                    ifluid = this.user_fluid
                } else {
                    // Not a valid id? Go to login
                    this.$router.push({path : '/login'})
                    return
                }
                
                this.its_me = tools.checkLogged(ifluid)
                if (this.its_me) {
                    let user = tools.getUser()
                    if (user) {
                        this.fetched = user
                        this.user_email = user.email
                        if (!user.empty) {
                            // If already logged use stored data
                            return
                        }
                        // Clear empty flag
                        delete user.empty
                        tools.storeUser(user)
                    } else {
                        this.$router.push({path : '/login'})
                    }
                }
                // Otherwise download data
                let fetched = {} 
                fetched = await tools.apiGet('/user/'+ifluid+'/?translate=true&lang=IT')
                if (!fetched.ok) {
                    // alert("Errore durante il caricamento del profilo")
                    // console.error(fetched.message)
                    this.$router.push({path : '/login'})
                    return
                }
                // delete fetched.ok
                if ( !fetched.rawcodes || !fetched.rawcodes.species || fetched.rawcodes.species != '0x00') {
                    // alert("FaunaLife Id Errato")
                    this.$router.push({path : '/login'})
                    return
                }

                // Assume it's human
                delete fetched.attributes.species

                fetched.attributes.fluid = ifluid
                // Separate returned values
                // Some pets might appear more than once if transfered and transfered back         
                fetched.pets = [...new Set(fetched.attributes.owns)]
                delete fetched.attributes.owns

                // Separate privacy settings
                fetched.privacy = fetched.attributes.privacy_settings
                delete fetched.attributes.privacy_settings
                
                // Translate Attributes
                fetched.attributes = await tools.translateDetails(fetched.attributes)
                this.short_hash = tools.shortHash(ifluid)
                this.fetched = JSON.parse(JSON.stringify(fetched))

                // console.error("TF", this.fetched)

                // Extras
                this.user_hascard = await tools.apiGetUserHasCard(ifluid)
                if (this.user_hascard.ok) {
                    this.user_hascard = this.user_hascard.hascard
                    fetched.extra = {}
                    if (this.user_hascard) {
                        fetched.extra.mypetcard = true
                    }
                }

                // Store user data for later reuse
                if (this.its_me) {
                    tools.updateUser(fetched)
                }

            },

            getBiz : async function() {
                // Is this a business / association ?
                switch (parseInt(this.fetched.rawcodes.role)) {
                    case 0 : // owner (nothing)
                        break
                    case 1 : // breeder
                    case 3 : // group / association
                        this.biz = await tools.apiGetBusiness(this.fetched.user)
                        if (this.biz.ok && this.biz[0]) {
                            delete this.biz.ok
                            this.biz=this.biz[0]
                            if (this.biz.address?.length > 0 ) {
                                this.maps_url = "https://www.google.com/maps/embed/v1/place?key="+this.key+"&q="+this.biz.address+"&maptype=satellite&zoom=17"
                            }
                            if (this.biz.email?.length > 0) {
                                this.biz.email = "mailto:"+this.biz.email
                            }
                            if (this.biz.phone?.length > 0) {
                                this.biz.phone = "tel:"+this.biz.phone
                            }
                        }
                        this.details_title = 'Dati dell\'associazione'
                        break
                    default: // role not compatible with this view
                        this.$router.go(-1)
                        break 
                }
            },

            /**
             * Clear search settings if the user closes the search/filter toggle
             * Resets search etc.
             */
            showSearchUpdate: function() {
                // Default values always the same ???
                tools.cacheSetInterfacePreferences(null)
                this.sortCriteria       = ''
                this.filterGender       = null
                this.filterHasDocs      = null
                this.filterHasNotes     = null
                this.filterIsNeutered   = null 
                this.sortFilterPets()
            },

            /**
             * Read the filter and sort options and reflect them into interface
             */
            getInterfaceOptions: function() {
                let ip = tools.cacheGetInterfacePreferences()
                if (ip) {
                    this.showSearch = ip.show || false
                    this.sortCriteria = ip.sort || 'alphaNameSort'
                    this.filterGender = (ip.filter.filterGender == 'F')? true : (ip.filter.filterGender == 'M')? false : null
                    this.filterHasDocs = ip.filter.filterHasDocs
                    this.filterHasNotes = ip.filter.filterHasNotes
                    this.filterIsNeutered = ip.filter.filterIsNeutered
                    this.popup_show = ip.popup
                } else {
                    this.showSearch = false
                    this.sortCriteria = 'alphaNameSort'
                    this.filterGender = null
                    this.filterHasDocs = false
                    this.filterHasNotes = false
                    this.filterIsNeutered = false
                    this.popup_show = false
                }
            },

            /**
             * Sort and filter the pets array according to some criteria
             * @param {*} order an object (?) that will contain sorting criteria 
             */
            sortFilterPets: async function(order=null, evt=null) {
                // Copy over pets arrays from fetched to presented
                this.presented_pets  = [...this.fetched.pets]
                this.presented_pets_dead  = [...this.fetched.pets_dead]
                this.presented_pets_transfered  = [...this.fetched.pets_transfered]

                // Get pet name from cache, no promises !!
                function getPetAttribute(fluid, attribute) {
                    try{
                        let cache = JSON.parse(sessionStorage.getItem('FL_Pet_'+fluid))
                        return cache.rawattributes[attribute]
                    } catch (e) {
                        console.error(e.message)
                        return null
                    }
                }

                function getPetRawCode(fluid, code) {
                    try{
                        let cache = JSON.parse(sessionStorage.getItem('FL_Pet_'+fluid))
                        return cache.rawcodes[code]
                    } catch (e) {
                        console.error(e.message)
                        return null
                    }
                }

                function petHasField(fluid, field) {
                    try {
                        let cache = JSON.parse(sessionStorage.getItem('FL_Pet_'+fluid))
                        if (cache[field]) {
                            let p = cache[field]
                            if (Array.isArray(p) && p.length == 0) {
                                return false
                            }
                            return true
                        }
                        return false
                    } catch (e) {
                        console.error(e.message)
                        return null
                    }
                }

                // alphabetic sort by name
                let alphaNameSort = function (a,b) {
                    let o = getPetAttribute(a, 'name').localeCompare(getPetAttribute(b, 'name'), 'it')  
                    return (o > 0) ? 1 : ( (o<0)? -1 : 0 )
                }

                // alphabetic sort by name
                let ageSort = function (a,b) {
                    let ba = Date.parse(getPetAttribute(a, 'birth'))
                    let bb = Date.parse(getPetAttribute(b, 'birth'))
                    return (ba > bb) ? 1 : ( (ba < bb)? -1 : 0 )
                }

                let weightSort = function(a,b) {
                    // Get weight from rawdata string, strip alphas and turn to int
                    let wa = parseInt(getPetAttribute(a, 'weight').replace(/[^0-9]/g, ''))
                    let wb = parseInt(getPetAttribute(b, 'weight').replace(/[^0-9]/g, ''))
                    return (wa > wb) ? 1 : ( (wa < wb)? -1 : 0 )
                }

                let buildSort = function(a,b) {
                    let builds = ['XXS','XS','S','M','L','XL','XXL']
                    let ba = builds.indexOf(getPetAttribute(a, 'build'))
                    let bb = builds.indexOf(getPetAttribute(b, 'build'))
                    return (ba > bb) ? 1 : ( (ba < bb)? -1 : 0 )
                }

                // Get preferences 
                let interfacePrefs = tools.cacheGetInterfacePreferences()
                let interfacePrefsOLD = tools.cacheGetInterfacePreferences()

                // First filter arrays

                // Filter gender M/-/F
                if (this.filterGender == null) {
                    delete interfacePrefs.filter.filterGender
                } else {
                    let val = this.filterGender ? 'F':'M'
                    this.presented_pets             = this.presented_pets.filter(x => {return getPetAttribute(x, 'gender') == val})
                    this.presented_pets_dead        = this.presented_pets_dead.filter(x => {return getPetAttribute(x, 'gender') == val})
                    this.presented_pets_transfered  = this.presented_pets_transfered.filter(x => {return getPetAttribute(x, 'gender') == val})
                    interfacePrefs.filter.filterGender=val

                }

                // Filter Has Notes
                if (this.filterHasNotes == null) {
                    delete interfacePrefs.filter.filterHasNotes
                } else {
                    this.presented_pets             = this.presented_pets.filter(x => {return petHasField(x, 'notes')==this.filterHasNotes})
                    this.presented_pets_dead        = this.presented_pets_dead.filter(x => {return petHasField(x, 'notes')==this.filterHasNotes})
                    this.presented_pets_transfered  = this.presented_pets_transfered.filter(x => {return petHasField(x, 'notes')==this.filterHasNotes})
                    interfacePrefs.filter.filterHasNotes=this.filterHasNotes
                }

                // Filter Has Documents
                if (this.filterHasDocs == null) {
                    delete interfacePrefs.filter.filterHasDocs
                } else {
                    delete interfacePrefs.filter.filterHasDocs
                    this.presented_pets             = this.presented_pets.filter(x => {return petHasField(x, 'doc')==this.filterHasDocs})
                    this.presented_pets_dead        = this.presented_pets_dead.filter(x => {return petHasField(x, 'doc')==this.filterHasDocs})
                    this.presented_pets_transfered  = this.presented_pets_transfered.filter(x => {return petHasField(x, 'doc')==this.filterHasDocs})
                    interfacePrefs.filter.filterHasDocs=this.filterHasDocs
                }

                // Filter Is Neutered
                if (this.filterIsNeutered == null) {
                    delete interfacePrefs.filter.filterIsNeutered
                } else {
                    let val = this.filterIsNeutered? 1:0
                    this.presented_pets             = this.presented_pets.filter(x => { return parseInt( getPetRawCode(x, 'neutered_b') || 0 ) == val })
                    this.presented_pets_dead        = this.presented_pets_dead.filter(x => {return parseInt(getPetRawCode(x, 'neutered_b') || 0 ) == val})
                    this.presented_pets_transfered  = this.presented_pets_transfered.filter(x => {return parseInt(getPetRawCode(x, 'neutered_b') || 0 ) == val})
                    interfacePrefs.filter.filterIsNeutered=this.filterIsNeutered
                }

                 // Pick the sorting function
                let sortFunction = null
                if (!order && interfacePrefs && interfacePrefs.sort) {
                    order = interfacePrefs.sort
                }
                switch(order) {
                    case 'ageSort':
                        sortFunction = ageSort
                        break
                    case 'weightSort':
                        sortFunction = weightSort
                        break
                    case 'buildSort':
                        sortFunction = buildSort
                        break
                    case 'alphaNameSort':
                    default:
                        order = 'alphaNameSort'
                        sortFunction = alphaNameSort
                        break
                }
                interfacePrefs.sort = order 
                
                // Sort according to function
                if (sortFunction) {
                    this.presented_pets = this.presented_pets.sort(sortFunction)
                    this.presented_pets_dead = this.presented_pets_dead.sort(sortFunction)
                    this.presented_pets_transfered = this.presented_pets_transfered.sort(sortFunction)
                }
                
                // Refresh part of the interface
                this.refresher+=1
                
                // Remember preferences
                if ( JSON.stringify(interfacePrefsOLD) != JSON.stringify(interfacePrefs)) {
                    tools.cacheSetInterfacePreferences(interfacePrefs)
                }
            },

            separatePets : async function() {
                // distinguish present and past pets or dead pets
                this.fetched.pets_dead = []
                this.fetched.pets_transfered = []

                // delete pets that do not exist (???)
                let abducted = []

                for (let n in this.fetched.pets) {
                    if (await tools.cacheGetPet(this.fetched.pets[n]) === null) {
                        abducted.push(this.fetched.pets[n])
                        continue
                    }

                    let dh = await tools.apiGetPetIsDead(this.fetched.pets[n])
                    if (dh != false) {
                        this.fetched.pets_dead.push(this.fetched.pets[n])
                        continue
                    }
                    let ow = (await tools.apiGetPetOwner(this.fetched.pets[n]))
                    if (ow && this.user_fluid.localeCompare(ow, undefined, {sensitivity: 'base'})) {
                        this.fetched.pets_transfered.push(this.fetched.pets[n])
                    }
                }
                this.fetched.pets = this.fetched.pets.filter(x=> {return !abducted.includes(x)})
                this.fetched.pets = this.fetched.pets.filter(x=> {return !this.fetched.pets_dead.includes(x)})
                this.fetched.pets = this.fetched.pets.filter(x=> {return !this.fetched.pets_transfered.includes(x)})
                
                // sort pets list according to preferences
                this.sortFilterPets(this.interfacePrefs, null)

                // this.show_pets = true
                this.refresher += 1
            },

            // getPopup : async function() {
            //     if (this.popup_show == false) {
            //         return
            //     }

            //     // Simulate Call API to get popups ...
            //     // Call API
            //     let popups = { ok : false}

            //     if (popups.ok) {
            //         // If there are any popups to show, do so..
            //         this.popup_content = {
            //             title: 'Belli popup',
            //             carusel : [
            //                 {   
            //                     name: "A", 
            //                     html: "lorem ipsum 1", 
            //                     img:'https://pbs.twimg.com/profile_images/640687635946565632/5pRp2WLy_400x400.jpg'
            //                 },
            //                 {   name: "B", 
            //                     html: "lorem ipsum 2", 
            //                     img:'https://m.media-amazon.com/images/I/81i9LjpT+eL._CR520,0,986,986_UX256.jpg',
            //                     button_txt: 'Clicca qui',
            //                     button_to : 'https://quasar.dev',
            //                 },
            //                 {
            //                     name: "C", 
            //                     html: "<font color=\"white\">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</font>", 
            //                     img:'https://styles.redditmedia.com/t5_37xq8/styles/communityIcon_lfkyzmw7c6a61.png',
            //                     button_txt: 'Google',
            //                     button_to : 'https://google.com',
            //                 },
            //             ]
            //         }
    
            //         // ... prepare the carusel for showing content ...
            //         this.popup_slide =  this.popup_content.carusel[0].name
            //         this.popup_ready = true
    
            //         // ... and prevent further popups in this session
            //         let ip = tools.cacheGetInterfacePreferences()
            //         ip.popup = false
            //         tools.cacheSetInterfacePreferences(ip)
            //     }
            // },

            // toggleFollow : function () {
            //     let user = tools.getUser()
            //     if (user) {
            //         if (!user.friends || ! Array.isArray(user.friends) ) {
            //             user.friends = []
            //         }
    
            //         let body = {friends : this.user_fluid}
            //         if (user.friends.includes(this.user_fluid)) {
            //             // delete from array
            //             user.friends = user.friends.filter(x => {return x != this.user_fluid})
            //             this.is_follower = false
            //         } else {
            //             // add to array
            //             user.friends.push(this.user_fluid)
            //             this.is_follower = true
            //         }
            //         tools.storeUser(user)
                    
            //         // Push update to API
            //         body.follow = this.is_follower
            //         tools.apiFollow(body)
            //     } else {
            //         this.$router.push({path : '/login'})
            //     }
            // },

            goToSite : function(url, target=null) {
                window.open(url, target);
            }

        },
    
        watch: {                    // Stuff that happend when a variable changes
        },
    
        // Lifecycle Hooks
        async mounted() {
            tools.clearFocusPet()
            window.scrollTo(0,0)
            
            // Get the sorting and filtering option BEFORE pet data
            // (as those might change it)
            this.getInterfaceOptions()

            // Get the profile (and wait for completion)
            await this.getProfile()

            // Start "pet preview"
            this.presented_pets = this.fetched.pets
            this.show_pets = true

            // Download all pet data in parallel to speed up following steps
            // tools.cachePreloadPets(this.fetched.pets)
            this.refresher += 1

            // Get User referrals & friends and store thems into loca storage
            await tools.apiGetReferrals(this.user_fluid)
            this.refresher += 1
            await tools.apiGetFriends(this.user_fluid)
            this.refresher += 1
            // Get other data in parallel
            this.getBiz()
            this.refresher += 1
            this.separatePets()
            // this.getPopup()

            // Check if it's a friend
            // let user = tools.getUser()
            // if (user) {
            //     this.is_follower = (user.friends && Array.isArray(user.friends) && user.friends.includes(this.user_fluid)) 
            // } else {
            //     this.$router.push({path : '/login'})
            // }
        },
    
        created() {
        },

    }
</script>


// #css
<style scoped>

.h_iframe {
    overflow: auto;
    position:relative;
    padding-top: v-bind(biz.iframe_height);
}


.h_iframe iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

</style>