<template>
    <div class="row">
        <div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>

        <!-- Compilation Completed -->
        <div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-justify" v-if="showPage">

            <div class="col-12 text-center">
                <div class="separator-blank q-my-lg"></div>
                <h2><q-icon name="warning"></q-icon> Attenzione!</h2>
            </div>

            <div v-if="showPage=='expired'">
                <div class="col-12 text-center">
                    <h3>MyPetCard scaduta</h3>
                    <div class="separator-blank q-my-md"></div>
                </div>
                <p>La MyPetCard che stai usando è scaduta il {{expires}}.</p>
                <p>Puoi continuare a utilizzare l'applicazione e gestire i dati del tuo pet e ad usufruire delle promozioni open ma
                non avrai più accesso a quelle riservate ai possessori di MyPetCard.</p>
                <p>Rinnova la MyPetCard per non perdere queste opportunità</p>
                <div class="separator-blank q-my-xl"></div>
                <div class="text-center">
                    <q-btn 
                        icon="credit_card" 
                        label="Rinnova MYPetCard" 
                        color="green" 
                        stack
                        size="lg"
                        @click="go_to('shop')">
                    </q-btn>
                </div>
                <div class="separator-blank q-my-md"></div>
                <div class="text-center">
                    <q-btn 
                        icon="arrow_forward" 
                        label="Vai al profilo" 
                        color="white"
                        text-color="green"
                        stack
                        unelevated
                        size="md"
                        @click="go_to('scan')">
                    </q-btn>
                </div>
            </div>
            <div v-else>
                <div class="col-12 text-center">
                    <h3>MyPetCard non valida</h3>
                    <div class="separator-blank q-my-md"></div>
                    <p>La MyPetCard che stai usando non è valida</p>
                </div>
            </div>

        </div>
        <div v-else class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-center">
            <q-spinner-ios
                color="green"
                size="6em"
                :thickness="9"
            ></q-spinner-ios>
        </div>


        <div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>
    </div>
    <div style="height: 850px;">
        &nbsp;
    </div>
</template>

<script>
    import * as tools   from '../common/tools'
    import * as bctools from '../common/bctools' 

    export default {
        name: 'CardProxy',
        components: {},
        
        props : {            // values passed by caller Commponent via props
            pet_fluid : {
                type: String,
                required : true,
                default : '0x00000000000000000000000000000000000000ZZ',
                validator : (fluid) => bctools.validAddress(fluid),
            },
        },

        data() {
            return {
                showPage : false,
                expires  : '',
            }
        },

        methods: {

            checkCardExpiration : async function () {
                let pet = await tools.cacheGetPet(this.pet_fluid)
                if (pet) {
                    if (pet.extra && pet.extra.mypetcard) {
                        this.expires = tools.euDate2Date(pet.extra.mypetcard)
    
                        let now = new Date()
                        console.error(this.expires)
                        console.error(now)
                        if (this.expires > now) {
                            // Card expires in the future, so it's valid
                            // Go to pet profile
                            this.$router.push("/scan/"+this.pet_fluid)
                        } else {
                            // Card is expired
                            this.expires = pet.extra.mypetcard
                        }
                    }
                    // All other cases (missin card, expired card) show the warning page
                    this.showPage = 'expired' 
                } else {
                    // Pet is null, so passed pet_fluid is invalid
                    this.showPage = 'invalid' 
                }
                this.showPage = true 
            },

            go_to : function (to) {
                switch (to) {
                    case 'shop':
                        let url="https://shop.fauna.life/renew/"+this.pet_fluid
                        window.open(url)
                        break
                    case 'scan':
                    default:
                        this.$router.push("/scan/"+this.pet_fluid)
                        break
                }
            },


        },

        mounted() {    
            window.scrollTo(0,0)
            this.checkCardExpiration()
        },

    }
</script>

<style scoped>
h2 {
  margin : 5px !important;
  font-size: clamp(3rem, -0.875rem + 8.333vw, 4rem) !important;
  font-weight: 700 !important;
  color : var(--fl-red-1);
}

h3 {
  margin : 0px !important;
  font-size: clamp(1.15rem, 1.0625rem + 0.78125vw, 2rem) !important;
  font-weight: 700 !important;
  color : var(--fl-red-1);
}
</style>