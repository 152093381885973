<template>
        <div style="position: relative; top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);" class="text-center">
            <q-img
                v-bind:src="qr_img_url"
                width="90%"
            >
            </q-img>
        </div>
</template>

<script>
    import * as config from '../common/config'
    import * as tools from '../common/tools'
    export default {

        name : 'QRCard',
        components: {
        },

        props : {
        },

        data()  {
            return {
                qr_img_url : ''
            }
        },   

        computed : {
        },

        methods: {           // Component functions
        },

        // Lifecycle Hooks
        async created() {
            let pet_fluid = (await tools.getFocusPet()).fluid
            let page_url = encodeURIComponent(config.AppBaseUrl+'/scan/'+pet_fluid)
            this.qr_img_url = config.ApiBaseUrl+'/util/qr/'+page_url
        },
    }
</script>

// #css
<style>
</style>