<template>
    <Header showLogo="false" />
    <div class="container-md">
        <slot />
    </div>
</template>

<script>
    import Header from '../Header'
    import Logo from '../Logo'
    export default {
        name: 'WithoutLogoLayout',
        components: {
            Header,
            Logo
        },
    }
</script>

<style>
</style>