<template>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white" 
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="$router.go(-1)"
        ></q-btn>
    </div>

    <div class="container">
        <div class="row">
            <!--Document Source-->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 q-my-lg q-px-md">
                <div class="infogroup">
                    <h3>Seleziona un File:</h3>
                    <q-file
                        name = "file"
                        label = "Seleziona file o scatta foto"
                        label-color = "white"
                        color="white" 
                        bg-color="green" 
                        standout
                        rounded
                        v-model="file" 
                        v-on:update:model-value="preload()"
                    >
                        <template v-slot:prepend>
                            <q-icon name="description" color="white"></q-icon>
                        </template>
                        <template v-slot:after>
                            <q-icon name="pets" :color="icm_file" size="25px"></q-icon>
                        </template>   
                    </q-file>
                    <div class="q-py-sm text-center"  v-show="imageSrc !== null">
                        <!--
                            See https://codesandbox.io/examples/package/vue-cropperjs
                        -->
                        <vue-cropper
                            ref="cropper"
                            v-bind:src="imageSrc"
                            :viewMode="2"
                            :autoCrop=false
                            v-on:cropstart="crop_init"
                            scalable=true
                            zoomable=false
                            v-bind:minCropBoxWidth="minImgSize"
                        >
                        </vue-cropper>
                        <q-btn 
                            v-if="!is_cropping"
                            color="green"                           
                            text-color="white" 
                            size="md"
                            rounded
                            icon="content_cut"
                            class="q-ma-md" 
                            label="Ritaglia" 
                            @click="crop_init()"
                        ></q-btn>
                        <q-btn 
                            v-if="is_cropping"
                            color="navigate"                           
                            text-color="white" 
                            size="md"
                            rounded
                            icon="undo"
                            class="q-ma-md" 
                            label="Annulla" 
                            @click="crop_abort()"
                        ></q-btn>
                        <q-btn 
                            v-if="is_cropping"
                            color="green"                           
                            text-color="white" 
                            size="md"
                            rounded
                            icon="done"
                            class="q-ma-md" 
                            label="conferma" 
                            @click="crop_image()"
                        ></q-btn>
                        <br>
                        
                    </div>
                    <div class="text-center">
                        <span v-show="imageSrc !== null">
                            Dimensione {{this.image_size.width}}x{{this.image_size.height}} px  -  
                        </span>
                        <span v-if="this.file_size>0">
                            <span v-if="file_size_exceeded" style="color:red;">{{this.file_size_str}} : Oltre i limiti consentiti</span>
                            <span v-else>{{this.file_size_str}}</span>                    
                        </span>
                        <span v-else>
                            Dimensione massima : {{ this.max_filesize }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Type & subtype -->
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-md">
                Categoria del documento
                <q-select ref="sel_type" 
                    v-model="sel_type" 
                    :options="opt_type" 
                    outlined 
                    label-color='green'
                    color='green'
                    v-on:update:model-value="load_subtype()"
                    label="" 
                    hint="Scegli la categoria del documento"
                >
                    <template v-slot:after>
                        <q-icon name="pets" :color="icm_sel_type" size="25px"></q-icon>
                    </template>   
                </q-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-md">
                Sottocategoria
                <q-select v-model="sel_subtype" 
                    :options="opt_subtype" 
                    outlined 
                    label-color='green'
                    color='green'
                    label="" 
                    hint="Scegli la sottocategoria specifica del documento"
                    v-on:update:model-value="changed_subtype()"
                >
                    <template v-slot:after>
                        <q-icon name="pets" :color="icm_sel_subtype" size="25px"></q-icon>
                    </template>                   
                </q-select>
            </div>
        </div>


        <div class="row">
            <div class="col-12 q-pa-lg">
                Tag del documento
                <q-input id="tags" v-model.lazy="tags" 
                    type="text" 
                    outlined 
                    label-color='green'
                    color='green'
                    hint="Inserisci una lista di tags che ti aiuteranno a riconoscere questo documento" 
                    label="tag, tag, tag"
                    maxlength=512
                    v-on:update:model-value="changed_tags()"
                >
                    <template v-slot:after>
                        <q-icon name="pets" :color="icm_text_tags" size="25px"></q-icon>
                    </template>   
                </q-input>
            </div>

            <div class="col-12 q-pa-lg">
                Descrizione del documento
                <q-input type="input"
                     v-model.lazy="description"
                    outlined 
                    label-color='green'
                    color='green'
                    hint="Inserisci una descrizione del documento" 
                    label="descrizione..."
                    maxlength=512
                    v-on:update:model-value="changed_desc()"
                >
                    <template v-slot:after>
                        <q-icon name="pets" :color="icm_text_desc" size="25px"></q-icon>
                    </template>                   
                </q-input>
            </div>


        </div>

        <!-- Mandatory signal -->
        <div class="row q-mb-xl" v-if="cant_send">
            <div class="col text-center q-pa-md">
                    Compila i campi obbligatori <q-icon name="pets" color="red" size="25px"/>
            </div>
        </div>

        <!-- Send -->
        <div class="row q-mb-xl" v-else>
            <div class="col-12 text-center q-pa-md">
                <div v-if='loading==false'>
                    <q-btn 
                        v-bind:color="send_color_button"
                        v-bind:disable="cant_send"
                        text-color="white" 
                        size="lg"
                        rounded
                        icon="upload_file"
                        class="q-ma-sm" 
                        label="Invia !" 
                        @click="upload_file()"
                    ></q-btn>
                </div>
                <div v-else>
                    <q-spinner-ios
                        color='green'
                        size="6em"
                        :thickness="9"
                    ></q-spinner-ios>
                </div>
            </div>
        </div>


        <!-- Done Dialog -->
        <q-dialog v-model="done_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="check" color='green'></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Fatto !</h4>
                    La transazione è stata presa in consegna. il nuovo documento sarà visibile a breve.                    
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="OK" color="green" @click="backToPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>


<script>
    import * as tools from '../common/tools'
    import * as config from '../common/config'
    import * as bc from '../common/bctools'
    
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    
    export default {
        name : 'Upload',
        components: {
            VueCropper
        },
 
    props : {
        pet_fluid : {
            type: String,
            required : true,
            default : '0x00000000000000000000000000000000000000ZZ',
            validator : (pet_fluid) => bc.validAddress(pet_fluid),
        },
    },
 
    data () {            // State(s) function
        return {
            // States
            is_logged           : false,
            loading             : false,
            is_cropping         : false,
            image_size          : {},

            // Internal Data    
            file                : null,
            file_hash           : null ,
            file_size           : 0,
            max_filesize        : tools.formatBytes(config.MaxFileSize), 
            imageSrc            : null,
            minImgSize          : config.AvatarSize,

            // Icon Colors for fields markers
            icm_file            : 'red',
            icm_sel_type        : 'red',
            icm_sel_subtype     : 'red',
            icm_text_tags       : 'red',
            icm_text_desc       : 'red',

            // Documnet & in terface data
            doc_types           : {},
            opt_type            : [],
            opt_subtype         : [],
            sel_type            : "Seleziona",
            sel_subtype         : "Seleziona",
            tags                : '',
            description         : '',

            done_dialog_show    : false,
        } 
    },
 
    computed : {         // Computed on the spot from data values
        
        send_color_button : function() {
            return (this.can_send) ? 'green' : 'grey'
        },

        can_send : function () {
            return (
                this.is_logged &&
                this.file != null &&
                this.file_hash != null && 
                bc.validBytes(32, this.file_hash) &&
                this.is_cropping == false &&
                this.file_size_exceeded == false &&

                this.opt_type.includes(this.sel_type)       != null &&
                this.opt_subtype.includes(this.sel_subtype) != null &&
                this.tags.length > 0                                &&
                this.description.length > 0
            )
        },

        cant_send : function () {
            return !this.can_send
        },

        file_size_str : function () {
            return tools.formatBytes(this.file_size)
        },

        file_size_exceeded : function () {
            return this.file_size > config.MaxFileSize
        },

        icm_file : function() {
            return (this.file != null && !this.file_size_exceeded && !this.is_cropping) ? 'green' : 'red'   
        }

    },
 
    methods: {

        hash_from_blob : function(blob) {
            let fr = new FileReader()
            fr.onload =  () => {
                this.file_hash = '0x'+tools.sha256sum(fr.result)
                console.error("blob hash: "+this.file_hash)
            }
            fr.readAsArrayBuffer(blob)
        },

        // Async sha256 load and compute for local file
        preload: function() {            
            let fr = new FileReader()

            if (this.file.type.split('/')[0] == 'image') {
                // It's an Image ! -> crop etc
                // Remember to always use arrow functions for hevent handlers!
                fr.onload =  () => {
                    // Load in new image for resizing
                    var image = new Image()
                    // Remember to always use arrow functions for event handlers!
                    image.onload=() => {
                        var canvas=document.createElement("canvas")
                        var context=canvas.getContext("2d")
                        canvas.width=tools.getReducedSize(image.width,image.height)[0]
                        canvas.height=tools.getReducedSize(image.width,image.height)[1]
                        context.drawImage(image,0,0,image.width,image.height,0,0,canvas.width,canvas.height)
                        // Put resized image in cropper
                        this.imageSrc = canvas.toDataURL()
                        // Get hash
                        let data = tools.dataURItoBlob(this.imageSrc)  
                        this.$refs.cropper.replace(this.imageSrc);
                        this.hash_from_blob(data)
                        // Get new image size                        
                        this.image_size = {'width' :canvas.width, 'height' : canvas.height}
                        this.file_size = data.size
                    }
                    image.src = fr.result
                }
                fr.readAsDataURL(this.file)
            } else {
                // It's ... else:
                this.imageSrc = null
                this.hash_from_blob(this.file)
                this.file_size = this.file.size
            }
            // Change marker color
            // this.icm_file = (this.file != null && !this.file_size_exceeded && !this.is_cropping) ? 'green' : 'red'
        },

        crop_init : async function () {
            this.$refs.cropper.initCrop()
            this.is_cropping = true
        },

        crop_abort : async function () {
            console.error("in abort")
            this.$refs.cropper.reset()
            this.$refs.cropper.replace(this.imageSrc)
            this.is_cropping = false
        },

        crop_image : async function () {
            this.image_size.width = this.$refs.cropper.getCroppedCanvas().width
            this.image_size.height = this.$refs.cropper.getCroppedCanvas().height

            // Replace Image with cropped part
            this.imageSrc = this.$refs.cropper
                .getCroppedCanvas()
                .toDataURL()
            this.$refs.cropper.replace(this.imageSrc)
            // Re-calculate file hash on cropped part
            let data = tools.dataURItoBlob(this.imageSrc)
            this.file_size = data.size
            this.hash_from_blob(data)
            
            console.error(this.image_size)
            this.is_cropping = false
        },

        load_all : async function () {
            this.doc_types = await tools.apiGet('/util/lookup/documents/?distinct=true')
            if (!this.doc_types.ok) {
                console.error(this.doc_types.message)
                return
            }
            // Remove Unwanted Types (4X & 9X)
            this.doc_types = this.doc_types.filter(x => {return ((x.id<40 || x.id>=50) && (x.id<90 || x.id>=100))})

            this.opt_type = [...new Set(this.doc_types.map(x => {return x.type}))]
            // this.sel_type = this.opt_type[0]
            // this.load_subtype()
        },

        load_subtype : async function () {
            this.icm_sel_type = this.opt_type.includes(this.sel_type) ? 'green' : 'red'
            this.opt_subtype=this.doc_types.filter( x => {return x.type === this.sel_type}).map(x=>{return x.subtype})
            const index = this.opt_subtype.indexOf('Altro')
            this.opt_subtype.splice(index,1)
            this.opt_subtype.push('Altro')
            this.sel_subtype = "Seleziona"
            this.icm_sel_subtype = 'red'
        },
        
        changed_subtype : function () {
            this.icm_sel_subtype = this.opt_subtype.includes(this.sel_subtype) ? 'green' : 'red'
        },

        changed_tags : function () {
            this.icm_text_tags = (this.tags.length > 0) ? 'green' : 'red'
        },

        changed_desc : function () {
            this.icm_text_desc = (this.description.length > 0) ? 'green' : 'red'
        },

        upload_file : async function () {
            if (this.file == null) 
                return
            // Start Spinner
            this.loading = true
            // Get document ID
            let id = parseInt(this.doc_types.filter( x => {return x.type === this.sel_type})
                        .filter( x => {return x.subtype === this.sel_subtype})
                        .map(x=>{return x.id})[0]
                        )
            // Create Form Data
            var data = new FormData()
            // Handle file - image - cropped img
            if (this.file.type.split('/')[0] == 'image') {
                let blob = tools.dataURItoBlob(this.imageSrc)
                data.append('file', blob, this.file.name)
            } else {
                //It's a regular file
                data.append('file', this.file)
            }
            data.append('tags', this.tags)
            data.append('hash', this.file_hash)
            data.append('description', this.description)
            console.error(JSON.stringify(data,null,4))
            console.error(this.file.name)

            // TX: link doc to fluid
            let body = {'doc' : '0x'+id.toString(16).padStart(2,'0')+this.file_hash }            
            let linked = await tools.apiFluidSetData(body, this.pet_fluid)
            if (!linked.ok) {
                console.error(linked.message)
                alert("Error timestamping document:"+linked.message)
                this.loading = false
                return
            }
            if (!linked.transactions || !Array.isArray(linked.transactions || linked.transactions.length==0)) {
                console.error(linked.message)
                alert("Error in timestamping transactions")
                this.loading = false
                return
            }
            // Put TX data into Form Data
            data.append('transaction', linked.transactions[0])

            // Upload File to AWS
            let done = await tools.apiUploadDoc(data)
            if (!done.ok) {
                console.error(done.message)
                alert(done.message)
                this.loading = false
                return
            }
            console.error(done)

            // Since the document is added just push it to the Focused Pet
            let new_doc = {
                'doccode' : id,
                'dochash' : this.file_hash,
                'doctype' : this.sel_type,
                'docsubtype' : this.sel_subtype
            }

            let fpet = await tools.getFocusPet()
            if (! fpet.doc || ! Array.isArray(fpet.doc)) {
                fpet.doc = []
            }
            fpet.doc.push(new_doc)
            tools.cacheStorePet(fpet)
            
            // get bacK
            this.loading = false
            this.done_dialog_show = true


        },

        backToPet : function () {
            // This function is called just upon success so 
            this.$router.go(-1)
        }
    },
 
    watch: {             // Stuff that happend when a variable changes
    },
 
    // Lifecycle Hooks
    mounted() {
        this.is_logged = tools.checkLogged()
        window.scrollTo(0,0)
        this.load_all()
    },
 
    created() {
        this.is_logged = tools.checkLogged()
    },
}
</script>



// #css
<style>
</style>