<template>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white" 
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="$router.go(-1)"
        ></q-btn>
    </div>

    <div class="container q-px-xl text-center">
        <h3 class="q-mb-xl">Inserisci una foto del tuo animale</h3>
        <form>
            <!-- Avatar -->
            <div class="row justify-center">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Seleziona o scatta
                    <q-file
                        name = "profile"
                        label = "Seleziona file o scatta foto"
                        label-color = "white"
                        color="white" 
                        bg-color="green" 
                        rounded
                        standout
                        accept="image/*"
                        v-model="img_file" 
                        v-on:update:model-value="preload()"
                    >
                        <template v-slot:prepend>
                            <q-icon name="photo_camera" color="white"></q-icon>
                        </template>
                    </q-file>
                </div>
            </div>

            <div class="row justify-center" v-if="is_cropping==this.CROP_START">
                <div v-show="imageSrc !== null" 
                    class="col-12 q-my-lg q-pa-lg avatar_cropper text-center"
                >
                    <div>
                        <vue-cropper
                            ref="cropper"
                            v-bind:src="imageSrc"
                            :viewMode="2"
                            aspectRatio=1
                            :scalable=true
                            :zoomable=true
                            :background=false
                            v-bind:minCropBoxWidth=minImgSize
                            :autoCrop=true
                            v-on:cropstart="crop_init"
                        >
                        </vue-cropper>
                        <q-btn 
                            color="navigate"                           
                            text-color="white" 
                            size="md"
                            rounded
                            icon="undo"
                            class="q-ma-md" 
                            label="Annulla" 
                            @click="crop_abort()"
                        ></q-btn>
                        <q-btn 
                            color="green"                           
                            text-color="white" 
                            size="md"
                            rounded
                            icon="done"
                            class="q-ma-md" 
                            label="Conferma" 
                            @click="crop_image()"
                        ></q-btn>
                    </div>
                </div>
            </div>

            <!-- Submit Buttons  -->
            <div class="q-mb-lg" v-if="is_cropping == this.CROP_DONE">
                <!-- Final Cropped Image -->
                <div class="row q-pa-sm justify-center" >
                    <q-avatar v-bind:size="avatar_size">
                        <img
                            v-if="imageSrc"
                            :src="imageSrc"
                            alt="Avatar"
                        />
                    </q-avatar>
                </div>

                <div class="row q-pa-sm justify-center" v-if='loading==false' >
                    <q-btn 
                        color="green"
                        text-color="white" 
                        size="lg"
                        rounded
                        icon="upload"
                        class="q-ma-sm" 
                        label="Invia" 
                        @click="submitPic()"
                    ></q-btn>
                </div>
                <div v-else class="row q-mb-xl justify-center">
                    <q-spinner-ios
                        color="green"
                        size="6em"
                        :thickness="9"
                    ></q-spinner-ios>
                </div>           
            </div>
        </form>

        <!-- Error Dialog -->
        <q-dialog v-model="error_dialog_show" persistent>   
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="warning" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Attenzione !</h4>
                    Dati inseriti incompleti o errati:
                    <span v-html="input_error"></span>
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Done Dialog -->
        <q-dialog v-model="done_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="check" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Fatto !</h4>
                    La transazione è stata presa in consegna. il nuovo profilo sarà visibile a breve.                    
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="OK" color="green" @click="backToPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>
 
 
 
<script>
    import * as tools from '../common/tools'
    import * as bctools from '../common/bctools' 
    import * as config from '../common/config'

    // https://github.com/Agontuk/vue-cropperjs
    import VueCropper from 'vue-cropperjs'
    import 'cropperjs/dist/cropper.css'

    export default {
        name : 'AddAvatar',
        components: {
            VueCropper
        },

        props : {
        },

        data () {   
            return {
                // Service variables
                CROP_NONE   : 0,
                CROP_START  : 1,
                CROP_DONE   : 2,

                // Image handling, hashing & cropping
                minImgSize : config.AvatarImgSize,
                avatar_size : config.AvatarSize+'px',

                img_file : null,
                imageSrc : null,
                img_hash : null,
                is_cropping : this.CROP_NONE,

                // Session
                fluid : null,
                fetched : null,

                // Status
                loading : false,
                error_dialog_show : false,
                done_dialog_show : false,
            } 
        },

        computed : {
            comp_can_send : function () {
                return (
                    tools.checkLogged()             &&
                    this.is_cropping != this.CROP_START
                )
            }
        },

        methods: {
            check_can_send : function () {
                let error = ''
                if (this.is_cropping == this.CROP_START) {error = 'Terminare ridimensionamento immagine'}
                if (tools.checkLogged() == false) {error = 'Utente non loggato'} 
                if (error == '') {
                    return true
                }
                console.error(error)
                this.input_error = error
                this.error_dialog_show = true
                return false
            },

            hash_from_blob : function(blob) {
                let fr = new FileReader()
                fr.onload =  () => {
                    this.img_hash = '0x'+tools.sha256sum(fr.result)
                }
                fr.readAsArrayBuffer(blob)
            },

            preload: function() {            
                let fr = new FileReader()
                console.error("Cropping Start")
                this.is_cropping = this.CROP_START
                if (this.img_file.type.split('/')[0] == 'image') {
                    // It's an Image ! -> crop etc
                    // Remember to always use arrow functions for hevent handlers!
                    fr.onload =  () => {
                        // Load in new image for resizing
                        let image = new Image()
                        // Remember to always use arrow functions for event handlers!
                        image.onload=() => {
                            // Reduce Image size since some browsers can't handle huge images
                            let canvas=document.createElement("canvas")
                            let context=canvas.getContext("2d")
                            canvas.width=tools.getReducedSize(image.width,image.height)[0]
                            canvas.height=tools.getReducedSize(image.width,image.height)[1]
                            context.drawImage(image,0,0,image.width,image.height,0,0,canvas.width,canvas.height)
                            // Put resized image in cropper
                            this.imageSrc = canvas.toDataURL()
                            this.$refs.cropper.replace(this.imageSrc);
                        }
                        image.src = fr.result
                    }
                    fr.readAsDataURL(this.img_file)
                } else {
                    alert("E' possibile solo caricare immagini")
                }
            },

            crop_init : async function () {
                this.img_hash = null
                this.$refs.cropper.initCrop()
                this.is_cropping = this.CROP_START
            },

            crop_abort : async function () {
                console.error("in abort")
                this.$refs.cropper.destroy()
                // this.$refs.cropper.replace(this.imageSrc)
                this.img_file = null
                this.imageSrc = null
                this.is_cropping = this.CROP_NONE
            },

            crop_image : async function () {
                // Replace Image with cropped part
                this.imageSrc = this.$refs.cropper
                    .getCroppedCanvas()
                    .toDataURL()
                // Get Image to 200x200
                let image = new Image()
                image.onload=() => {
                    let canvas=document.createElement("canvas")
                    let context=canvas.getContext("2d")
                    canvas.width=config.AvatarImgSize
                    canvas.height=config.AvatarImgSize
                    context.drawImage(image,0,0,image.width,image.height,0,0,canvas.width,canvas.height)
                    // Put resized image in cropper
                    this.imageSrc = canvas.toDataURL()
                    let data = tools.dataURItoBlob(this.imageSrc)  
                    this.hash_from_blob(data)
                    this.$refs.cropper.replace(this.imageSrc)
                    this.is_cropping = this.CROP_DONE
                    this.$refs.cropper.clear()
                }
                image.src = this.imageSrc
            },

            initialize : async function () {
                console.error("Entering")
                let fp = await tools.getFocusPet()
                if (fp !== null) {
                    this.fetched = fp
                    this.fluid = fp.fluid
                } else {
                    // Go back as something is wrong
                    console.error("Going back to pet")
                    this.$router.go(-1)
                    return
                }
            },

            checkLogged : function () {
                // If it's not a logged user redirect it to login
                if (!tools.checkLogged()) {
                    this.$router.push({path : '/login'})
                }
            },

            submitPic : async function () {
                // Sanity check before sending     
                if (!this.check_can_send()) {
                    return
                }
                // Start the spinner
                this.loading = true

                // Prepare empty docs array for later
                let payload = {}
                payload.doc = []

                // Send Document of type 89 (0x59) for Profile picture if present
                if (this.imageSrc !== null && this.imageSrc !== undefined) {
                    // Hash must be present
                    if (this.img_hash==null) {
                        this.loading = false;
                        return
                    }
                    // Create formdata
                    let data = new FormData()
                    let blob = tools.dataURItoBlob(this.imageSrc)
                    data.append('file', blob, this.img_file.name)
                    data.append('tags', 'Avatar')
                    data.append('hash', this.img_hash)
                    data.append('description', 'Avatar di '+this.fetched.rawattributes.name)
                    // Upload File to AWS
                    let done = await tools.apiUploadDoc(data)
                    if (!done.ok) {
                        console.error(done.message)
                        alert(done.message)
                        this.loading = false
                        return
                    }
                    // Record document entry and add it to payload
                    payload.doc.push('0x59'+this.img_hash)
                } else {
                    delete payload.doc
                    this.loading = false;
                    return
                }

                // Send data
                let sent = await tools.apiFluidSetData(payload, this.fluid)
                if (!sent.ok) {
                    console.error(sent.message)
                    alert(sent.message)
                    this.loading = false
                    return
                }

                // Invalidate cache for this pet
                tools.cacheClear(this.fluid)

                // Stop spinner, show dialog 
                this.loading = false
                this.done_dialog_show = true                
            },

            // Go back to User Page
            backToPet : function () {
                // Might just add the new doc instead but...
                tools.clearFocusPet()
                this.$router.push('/scan/'+this.fluid)
            }
        },

        // Lifecycle Hooks
        created() {
            this.checkLogged()
            window.scrollTo(0,0)
            this.initialize()
            console.error("Loaded AddAvatar")
        },
    }
</script>
 
 
<style>
/* 
    those css values apply only to the .avatar_cropper div in this page
    used to give the circle selection to the cropper
*/
.avatar_cropper .cropper-view-box {
	box-shadow: 0 0 0 1px #39f;
	border-radius: 50%;
	outline: 0;
}
.avatar_cropper .cropper-face {
	background-color:inherit !important;
}
.avatar_cropper .cropper-view-box {
	outline:inherit !important;
}
</style>