import * as config from './config'
import axios from 'axios'
import sha256 from 'js-sha256'
import { createLogger } from 'vuex'

// --- API FUNCTIONS ------------------------------------------------------------------------------

async function apiPost(postbody, endpoint) {
    let posted = {}
    let r
    try {
        r = await axios.post(config.ApiBaseUrl+endpoint, postbody, {
                                withCredentials: true,
                                validateStatus: status => {return true;}    //TRICK: consider all responses valid...
                            })

        if (r.status != 200) {                                              // ... and handle them manually later
            posted.ok = false
            posted.message = r.status+' ' + JSON.stringify(r.data,null,4)
        } else {
            if (typeof r.data === 'object' && r.data !== null) {
                posted = r.data
            }
            posted.ok = true
        }
    } catch (e) {
        posted.ok = false
        posted.message = e.message
    }
    return posted
}

async function apiLogin(email, password, idToken=null, captchaToken=null) {
    let postbody = {'email' : email, 'password': password, 'idToken' : idToken, 'captchaToken' : captchaToken}
    return await apiPost(postbody, '/user/login')
}

async function apiLogout() {
    let postbody = {}
    return await apiPost(postbody, '/user/logout')
}

async function apiRegister(email, password, role, idToken=null, captchaToken=null, extraQuery=null, privacyFlags=null ) {
    // Get mandatory fields from call
    let mandatory = {'email' : email, 'password': password, 'role' : role, 'idToken' : idToken, 'captchaToken' : captchaToken}
    // Mix mandatory with (optional)extraQuery params.
    // Mandatory fields overwrite extraQuery in case of collision
    let postbody = {...privacyFlags, ...extraQuery, ...mandatory};
    // Send
    return await apiPost(postbody, '/user/create')
}

async function apiFluidSetData (body, fluid) {
    return await apiPost(body,'/fluid/'+fluid)
}

async function apiUploadDoc(formData) {
    let done = {}
    try {
        let r = await axios.post(config.ApiBaseUrl+'/docs', formData, {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        console.error(r)
        done = r.data
        done.ok = true
    } catch (e) {
        done.ok = false
        console.error(e.message)
        done.message = e.message
    }
    return done
}

async function apiTransferPet(body, fluid) {
    return await apiPost(body, "/fluid/transfer/"+fluid)
}

async function apiGet(endpoint) {
    let fetched = {}
    try {
        let r = await axios.get(config.ApiBaseUrl+endpoint, {withCredentials: true})
        fetched = r.data
        fetched.ok = true
    } catch(e) {
        fetched.ok = false
        fetched.message =  e.message 
    }
    return fetched
}

async function downloadUrl(url) {
    let fetched = {}
    try {
        let r = await axios.get(url)
        fetched.content = r.data
        fetched.ok = true
    } catch(e) {
        fetched.ok = false
        fetched.message =  e.message 
    }
    return fetched
}

async function apiGetPetOwner(fluid) {
    let fetched = await cacheGetPet(fluid)
    if (!fetched || !fetched.ok || fetched.fluid == undefined || !fetched.owners || ! Array.isArray(fetched.owners)) {
        return false
    }
    return fetched.owners[0] // 0 is most recent
}

async function apiGetPetIsDead(fluid) {
    let fetched = await cacheGetPet(fluid)
    if (!fetched || !fetched.ok || fetched.fluid == undefined || !fetched.rawattributes || ! fetched.rawattributes.death) {
        return false
    }
    return fetched.rawattributes.death
}

async function apiGetUser(fluid) {
    return apiGet("/user/"+fluid)
}

async function apiGetUserHasCard(fluid) {
    return apiGet("/user/hascard/"+fluid)
}

async function apiGetUserExists(fluid, email) {
    let url ="/user/exists?"
    if (fluid) { url += "id="+fluid }
    if (fluid && email) {url += "&"}
    if (email) { url += "email="+email }
    return apiGet(url)
}

async function apiGetUserRoles() {
    return apiGet("/util/lookup/roles?distinct=true&cols=role_id,IT")
}


async function apiGetUserPromos() {
    // Get a list of user's pets species
    let species = new Set()
    let user = getUser()
    if (user && user.pets) {
        let pets = user.pets
        for (let fluid of pets) {
            species.add(parseInt( (await cacheGetPet(fluid)).rawcodes.species))
        }
    }
    species.add(0)
    species = [...species].join()
    return apiGet("/adv/promo/?species="+species)
}

async function apiGetOneTimeURL(destination) {
    return apiGet("/extra/redirect/"+destination)
}

async function apiGetBusiness(fluid) {
    return apiGet("/adv/biz/"+fluid)
}

async function apiGetReferrals(fluid) {
    let user = getUser()
    let refs = []
    if (!user) {
        return refs
    }
    if ( !user.referrals || !Array.isArray(user.referrals) ) {
        refs = await apiGet("/adv/referral/"+fluid)
        if (!refs.ok) {
            console.error("Error loading referrals")
            refs = []
        } else {
            refs = refs.map(x => {return x.referral})
        }
        user.referrals = refs
        storeUser(user)
    }
    return user.referrals
}


async function apiGetFriends(fluid) {
    let user = getUser()
    let friends = []
    if (!user) {
        return friends
    }
    if (
        !user.friends || 
        !Array.isArray(user.friends)
    ) {
        let friends = await apiGet("/social/friends")
        if (!friends.ok) {
            console.error("Error loading friends")
            friends = []
        } else {
            friends = friends.map(x => {return x.friend})
        }
        user.friends = friends
        storeUser(user)
    }

    return friends
}


async function apiNotifyMessage(message) {
    return apiPost(message, "/util/notify")
}


async function apiFollow(body) {
    return apiPost(body, "/social/follow")
}

// --- FLUID FUNCTIONS ------------------------------------------------------------------------------

async function translateDetails(details, onlykeys=false) {
    let tr_details = {}
    let keys=Object.keys(details)
    let lang = 'IT'
    let fetched

    // Get words previously translated
    let prev = JSON.parse(sessionStorage.getItem('FL_translationsDetails') || '{}' )
    prev[lang] = prev[lang] || {}

    // Get missing (non translated) keys
    let missing = keys.filter(x=> {return !(Object.keys(prev[lang]).includes(x))})
    if (missing.length > 0) {
        // Translate the (missing) keys
        fetched = await apiGet('/util/translate/'+lang+'?words='+keys.join(','))
        if (!fetched.ok) {
            console.error(fetched.message)
            return
        }
        delete fetched.ok
        // Some did not get translated?
        missing = missing.filter( x=> {return !(Object.keys(fetched).includes(x))} )
        for (let m of missing) {
            fetched[m]=m
        }
        
        prev[lang] = {...prev[lang], ...fetched}
        sessionStorage.setItem('FL_translationsDetails', JSON.stringify(prev))
    } else {
        // console.error("Skipping api call")
    }
    fetched = prev[lang]

    // Only translate the keys
    if (onlykeys) {
        for (let k=0; k<keys.length; k++) {
            let newkey = fetched[keys[k]] || keys[k]
            tr_details[newkey] = details[keys[k]]
        }
        return tr_details
    }

    // Translate keys and beautify values
    for (let k=0; k<keys.length; k++) {
        let newkey = fetched[keys[k]] || keys[k]
        switch (keys[k]) {
            case 'uploader':
                tr_details[newkey] = '<a href="'+config.AppBaseUrl+'/user/'+details[keys[k]]+'">'+details[keys[k]]+'</a>'
                break
            case 'transaction':
                tr_details[newkey] = '<a href="'+config.TrxBaseUrl+'/tx/'+details[keys[k]]+'" target="explorer">'+details[keys[k]]+'</a>'
                break
            case 'size' :
                tr_details[newkey] = formatBytes(parseInt(details[keys[k]]))
                break
            case 'breed' : 
                tr_details[newkey] = details[keys[k]].replace(/^[0-9]+ /, '')
                break
            case 'build' :
                tr_details[newkey] = details[keys[k]]
                break
            case 'weight' :
                tr_details[newkey] = formatWeight(details[keys[k]])
                break

            case 'birth' :
            case 'death' :
            case 'regdate' :
            case 'chipdate' :
            case 'neutered' : 
            case 'mypetcard' :
                let d = Date.parse( details[keys[k]] )
                if (!isNaN(d)) {
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    tr_details[newkey] = (new Date(d)).toLocaleDateString('it-IT', options)
                    break
                } 
                // if it's not date go to default
                break
            case 'neutered_b' :
                if (
                    ( parseInt(details[keys[k]]) == 0 ) ||
                    ( details[keys[k]][0].toLowerCase() == 'n')
                ) {
                    tr_details[newkey] = 'No'
                } else {
                    tr_details[newkey] = 'Si'
                }
                
                break
            default :
                // Default: 
                if (newkey) {
                    // copy the value over to a translated key
                    tr_details[newkey] = details[keys[k]]
                } else {
                    // copy the value over to the same, not translated (missing) key
                    tr_details[keys[k]] = details[keys[k]]
                }        
        }
    }
    return tr_details
}

function defaultPic(breed) {
    breed = parseInt(breed)
    let imgArray = [
        '',                                 // 0 = human
        '/img/icons/generic_dog.png',
        '/img/icons/generic_cat.png',
        '/img/icons/generic_ferret.png'
    ]
    return imgArray[breed] || '/img/icons/generic_unknown.png'
}

// --- XXX FUNCTIONS ------------------------------------------------------------------------------

function shortHash(hash) {
    return hash.substr(0,6)+'...'+hash.substr(hash.length-4,4)
}

function sha256sum(data) {
    return sha256(data);
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function formatWeight(weight) {
    let w = parseFloat(weight)
    if (w <= 1) {
        weight = (w*1000).toString() + ' g'
    } else {
        weight = parseFloat(weight) + ' kg'
    }
    weight = weight.replace('.',',')
    return weight
}

// Turns a EU date 'dd/mm/yyyy' into a JS date object since parse il racist
function euDate2Date (eudate) {
    let parts = eudate.split("/");
    let date = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) -1,  // the month is 0-indexed
        parseInt(parts[0], 10));
    return date
}

function euDate2ISO(eudate) {
    let parts = eudate.split("/");
    return parts[2]+'-'+parts[1]+'-'+parts[0]
}

function date2EUDate (date) {
    let d = Date.parse(date)
    if (!isNaN(d)) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
        return (new Date(d)).toLocaleDateString('it-IT', options)
    }
    return undefined
}

function date2ISO (date) {
    let eu = date2EUDate (date)
    return eu ? euDate2ISO(eu) : eu
}

function isValidEmail(email) {
    let email_regex = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/    
    return email_regex.test(email)
}

function isValidChip (pet_tag, iso3166 = null) {
    for (let r of config.Tag_RegExp) {
        let rx = new RegExp(r)
        if (pet_tag.match(rx) !== null) {
            // Check if matches ISO3166 country code according to ISO 11784 & 11785
            // First 3 chip numbers must match ISO3166 country code
            // or:
            // 999 : test
            // 9xx : manufacturer specific code
            if (iso3166 && pet_tag.length == 15 && pet_tag[0] != '9') {
                // Is ISO 11784 & 11785
                let tag_3166 = pet_tag.substring(0,3)
                if (iso3166 != parseInt(tag_3166)) {
                        console.error("ISO Mismatch")
                        return false    
                }
            }
            return true
        }
    }
    return false
}


function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}


// --- IMG/FILE/BLOB FUNCTIONS --------------------------------------------------------------------

 function dataURItoBlob (dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    var ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
}

function getReducedSize(X,Y) {
    let x
    let y
    if (X>Y) {
        if (X<=config.MaxImageSide) {
            return [X,Y]
        }
        x = config.MaxImageSide
        y = (Y*config.MaxImageSide)/X
    } else {
        if (Y<=config.MaxImageSide) {
            return[X,Y]
        }
        y = config.MaxImageSide
        x = (X*config.MaxImageSide)/Y
    }
    return [x,y]
}

function obj2QueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

// --- STORAGE FUNCTIONS --------------------------------------------------------------------------

/**
 * Check if user is logged i.e. there is a fluid in the SessionStorage
 * Optionally, if a fluid is passed, checks that it matches the one in SessionStorage
 * @param {*} fluid 
 * @returns true/false
 */
function checkLogged(fluid) {
    let user = getUser()
    if (
        user == null ||
        ( user.expires && user.expires < Date.now()) ||
        ! user.user_fluid
    ) {
        // User is not logged
        clearLogout()
        return false
    }

    // compare to parameter
    if (fluid) {
        return (fluid.toLowerCase() == user.user_fluid.toLowerCase())
    } 
    return true
}

function userHasPet(pet) {
    try {
        return getUser().pets.includes(pet)
    } catch {
        return false
    }
}

function clearLogout() {
    // Remove all 'FL_*' keys from localStorage
    Object.keys(localStorage).filter(k => {return k.startsWith('FL_')}).forEach(k => {localStorage.removeItem(k)})
}

function storeUser(user) {
    let string = JSON.stringify(user)
    localStorage.setItem('FL_User', string)
}

function clearUser() {
    localStorage.removeItem('FL_User')
}

function getUser() {
    try {
        return JSON.parse(localStorage.getItem('FL_User'))
    } catch {
        console.error("User 404")
        return null
    }
}

function updateUser(updates) {
    let user = getUser() || {}
    let merged = {...user, ...updates}
    storeUser(merged)
    return merged
}

function storeFocusPet (fluid) {
    sessionStorage.setItem('FL_FocusPet', fluid)
}

async function getFocusPet(full=true) {
    try{
        let fp = sessionStorage.getItem('FL_FocusPet')
        if (full) {
            return fp ? cacheGetPet( fp ) : null 
        } else {
            return fp
        }
    } catch {
        return null
    }
}

function clearFocusPet() {
    sessionStorage.removeItem('FL_FocusPet')
}


async function cacheGetPet(fluid) {
    // See if pet is into sessionStorage cache
    let cache 
    try{
        cache = JSON.parse(sessionStorage.getItem('FL_Pet_'+fluid))
    } catch (e) {
        console.error(e.message)
        return null
    }
    if (cache != null) {
        return cache
    }

    // otherwise get it
    let fetched = await apiGet('/fluid/'+fluid+'/?translate=true&lang=IT')
    if (!fetched || !fetched.ok) {
        console.error(fetched.message)
        return null
    }

    // docs
    if (Array.isArray(fetched.attributes.doc) && fetched.attributes.doc.length > 0) {
        fetched.doc = fetched.attributes.doc    
        // separate notes (doc type 90-99)
        fetched.notes = fetched.doc.filter( d => {return d.doccode=='0x5a'})
        fetched.doc = fetched.doc.filter( d => {return d.doccode!='0x5a'})
        // profile picture
        fetched.profilepic = fetched.doc.filter( d => {return d.doccode =='0x59'})
        fetched.doc = fetched.doc.filter( d => {return d.doccode!='0x59'})
    }
    delete fetched.attributes.doc

    // Get profile pic urls
    if (fetched.profilepic && fetched.profilepic.length>0) {
        fetched.profilepicurl = []

        let proms = []
        for (let pic of fetched.profilepic) {
            proms.push(apiGet('/docs/' + pic.dochash))
        }
        let resps = await Promise.allSettled(proms)
        for (let resp of resps) {
            if (resp.status != 'fulfilled') {
                console.error(resp.value.message)   
            } else {
                if (resp.value.ok) {
                    fetched.profilepicurl.push(resp.value.data.url)
                }
            }
        }
    }

    // owners
    fetched.owners = fetched.attributes.owner?.reverse() || []
    delete fetched.attributes.owner

    // family
    if (fetched.attributes.mother || fetched.attributes.father || fetched.attributes.offsprings) {
        fetched.family = {}
        if (fetched.attributes.mother) {
            fetched.family.mother = fetched.attributes.mother
            delete fetched.attributes.mother
        }
        if (fetched.attributes.father) {
            fetched.family.father = fetched.attributes.father
            delete fetched.attributes.father
        }
        if (fetched.attributes.offsprings) {
            fetched.family.offsprings = fetched.attributes.offsprings
            delete fetched.attributes.offsprings
        }
    }

    // *** REMOVE PRIVATE and EXTERNAL DOCS ***
    delete fetched.attributes.doc_private
    delete fetched.attributes.doc_external

    // scores
    fetched.scores = fetched.attributes.scores
    delete fetched.attributes.scores

    // extra fields (business oriented... like MyPetCard)
    fetched.extra = {}
    fetched.extra.mypetcard = fetched.attributes.mypetcard
    delete fetched.attributes.mypetcard
    fetched.extra = await translateDetails(fetched.extra)

    // Translate & beautyfy, Return
    fetched.attributes.fluid = fetched.fluid
    fetched.rawattributes = fetched.attributes;
    fetched.attributes = await translateDetails(fetched.attributes)
    if (fetched.updates) {
        fetched.updates = await translateDetails(fetched.updates, true)
    }

    cacheStorePet(fetched)
    return fetched
}

function cacheStorePet(pet) {
    try {
        sessionStorage.setItem('FL_Pet_'+pet.fluid, JSON.stringify(pet))
    } catch (e) {
        console.error(e.message)
    }
}

async function cachePreloadPets(pets) {
    let proms = []
    for (let pet of pets) {
        proms.push(cacheGetPet(pet))
    }
    await Promise.race(proms)
}

function cacheGetInterfacePreferences() {
    let preferences = null
    try {
        // Read stored value or default
        preferences = JSON.parse(sessionStorage.getItem('FL_Interface')) || config.DefaultInterface
    } catch (e) {
        console.error(e.message)
    }
    return preferences
}

function cacheSetInterfacePreferences(preferences) {
    if (!preferences) {
        preferences = config.DefaultInterface
    }

    try {
        sessionStorage.setItem('FL_Interface', JSON.stringify(preferences))
    } catch (e) {
        console.error(e.message)
    }
}

function cacheClearInterfacePreferences() {
    cacheSetInterfacePreferences(config.DefaultInterface)
}


function cacheClear(key) {
        // Remove all 'FL_*' keys that match a key from localStorage and sessionStorage
        Object.keys(sessionStorage)
            .filter(k => {return k.startsWith('FL_')})
            .filter(k => {return k.includes(key)})
            .forEach(k => {sessionStorage.removeItem(k)})
        Object.keys(localStorage)
            .filter(k => {return k.startsWith('FL_')})
            .filter(k => {return k.includes(key)})
            .forEach(k => {localStorage.removeItem(k)})
}

async function cacheGetDoc(dochash, mtype = null, stype = null) {
    // Docs need to be stored 1 by one or parallel read/write 
    // operations (like for FL_Pets) whill overwrite them

    // Search for doc in cache
    let cache 
    try{
        cache = JSON.parse(sessionStorage.getItem('FL_Doc_'+dochash))
    } catch (e) {
        console.error(e.message)
        return null
    }
    if (cache != null) {
        return cache
    }

    // if not found get it and store it
    let filedata = {}
    let tx = 0
    filedata.hash=dochash
    filedata.shorthash = shortHash(dochash)

    let fetched = await apiGet('/docs/'+dochash)
    if (!fetched.ok) {
        filedata.name = 'NotFound'
        filedata.size = 0
        filedata.url  = ''
        filedata.date = 'N/A'
        filedata.description = 'N/A'
        filedata.tags  = ['missing']
        filedata.path  = ''
        filedata.uploader = ''
        filedata.mimetype = ''
        filedata.missing = true
        return filedata
    } else {
        filedata.name = fetched.data.name
        filedata.size = formatBytes(fetched.data.size)
        filedata.url  = fetched.data.url
        filedata.description = fetched.data.description
        filedata.tags  = fetched.data.tags.split(" ")
        filedata.path  = fetched.data.path
        filedata.uploader = fetched.data.uploader
        filedata.mimetype = fetched.data.mimetype
        // Try to get upload time from TX
        let tx = fetched.data.transaction
        fetched = await apiGet('/util/tx/'+tx)
        if (!fetched.ok) {
            filedata.date = 'N/A'
        } else {
            filedata.date = (new Date(fetched.timestamp*1000)).toLocaleDateString('it-IT')                
        }
    }
    filedata.mtype = mtype
    filedata.stype = stype

    // Store it into cache and save for later
    try {
        sessionStorage.setItem('FL_Doc_'+dochash, JSON.stringify(filedata))
    } catch (e) {
        console.error(e.message)
    }
    return filedata
}

// --- EXPORTS ------------------------------------------------------------------------------------

export {
    // API Calls
    apiLogin,
    apiLogout,
    apiRegister,
    apiFluidSetData,
    apiUploadDoc,
    apiTransferPet,
    downloadUrl,
    apiGet,
    apiGetPetOwner,
    apiGetPetIsDead,
    apiGetUser,
    apiGetUserExists,
    apiGetUserRoles,
    apiGetUserPromos,
    apiGetUserHasCard,
    apiGetOneTimeURL,
    apiGetBusiness,
    apiGetReferrals,
    apiGetFriends,
    apiNotifyMessage,
    apiFollow,
    translateDetails,
    defaultPic,
    
    // User persistance
    getUser,
    clearUser,
    storeUser,
    updateUser,
    checkLogged,
    clearLogout,

    // Pet persistance
    storeFocusPet,
    getFocusPet,
    clearFocusPet,
    userHasPet,
    cacheGetPet,
    cachePreloadPets,
    cacheStorePet,
    cacheGetDoc,
    cacheGetInterfacePreferences,
    cacheSetInterfacePreferences,
    cacheClearInterfacePreferences,
    cacheClear,

    // Input Validation
    isValidEmail,
    isValidChip,

    // Formatting Data
    shortHash,
    sha256sum,
    formatBytes,
    formatWeight,
    euDate2Date,
    euDate2ISO,
    date2EUDate,
    date2ISO,
    obj2QueryString,
    shuffleArray,


    dataURItoBlob,
    getReducedSize,
}