<template>
    <div class="separator-blank q-my-xl" v-if = "user" >
        <!-- <h3 class='q-px-lg'>Le offerte per i tuoi pet:</h3> -->
        <div class="row justify-center">
            <div class="row"></div>
            <div
                class="text-center q-pa-lg col-xs-8 col-sm-8 col-md-6 col-lg-6 col-xl-5 mesh_bg"
                v-on:click='goToShop()'
            >
                VAI ALLE TUE OFFERTE
            </div>
            <div class="row"></div>
        </div>
    </div>
</template>

<script>
  import * as tools from '../../common/tools'

  export default {
    name : 'ScanPromos',
 
    props : {
    },
 
    data () {
        return {
            user : {}
        }
    },
 
    // Lifecycle Hooks
    async created() {
        this.user = tools.getUser()
    },

    methods: {
        goToShop : async function () {
            // Request onetimetoken to access shop
            let url = await tools.apiGetOneTimeURL('shop')            
            console.error("URL", url.url)
            window.open(url.url, '_blank');
        }   
    }
}
</script>


<style scoped>
    .mesh_bg {
        background-image: url("../../assets/bg_mesh_hor.png");
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 25px;
        color : white  !important;
        font-size: large;
        font-weight: 700;
        text-shadow: 2px 4px 4px rgba(46,91,73,0.6);
    }
</style>