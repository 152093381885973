<template>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white" 
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="$router.go(-1)"
        ></q-btn>
    </div>

    <div class="container q-px-xl">
        <form v-on:submit.prevent="login" >
            <h3 class="q-mb-xl">Trasferisci {{pet_name}} ad un nuovo proprietario</h3>
            
            <div class="container">
                <div class="row justify-center items-stretch">
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 q-ma-sm">
                        <PetProfileCard :pet_fluid="pet_fluid"/>
                    </div>
                    <div class="self-center text-center col-xs-12 col-sm-12 col-md-2 col-lg-1 col-xl-1 q-ma-sm">
                        <span class="material-icons text-h1 vertical-middle">fast_forward</span>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 q-ma-sm">
                        <PetActionCard card_action="new_owner" />
                    </div>
                </div>
            </div>
            <div class="separator-blank q-my-xl"></div>
            <q-input class="q-mb-xl"
                :rules="[ d => email_regexp.test(d) || 'Email non conforme']"
                v-model.lazy="recipient_email" 
                outlined 
                type="email" 
                hint="Email" 
                label="Inserisci l'email del nuovo propietario">
            </q-input>

            <q-input v-model.lazy="recipient_fluid" outlined
                :rules="[ d => fluid_regexp.test(d) || 'FaunaLife ID non conforme']"
                hint="FaunaLife ID"
                label="Inserisci l'identificativo FaunaLife del nuovo proprietario">
            </q-input>

            <div class="text-center" >
                <div v-if='loading==false'>
                    <q-btn 
                        color="green" 
                        text-color="white"
                        rounded
                        size='lg'
                        icon="fast_forward"
                        class="q-ma-sm" 
                        label="Trasferisci" 
                        @click="preSubmit()"
                    ></q-btn>
                </div>
                <div v-else>
                    <q-spinner-ios
                        color="green"
                        size="6em"
                        :thickness="9"
                    ></q-spinner-ios>
                </div>
            </div>
        </form>


        <!-- Confirm Dialog -->
        <q-dialog v-model="confirm_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="warning" color="yellow"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Attenzione !</h4>
                    <p>Confermando questa operazione {{pet_name}} non sarà più nel 
                        tuo profilo e non potrai gestirne i dati.</p>
                    <p>Vuoi proseguire?</p>
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
                    <q-btn flat icon="check" label="Confermo" color="green" @click="transferPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Error Dialog -->
        <q-dialog v-model="error_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="error" color="red"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Attenzione !</h4>
                    <p>I dati inseriti non sono corretti.</p>
                    <p>Ricontrollare i dati e riprovare.</p>
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Riprova" color="red" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Success Dialog -->
        <q-dialog v-model="success_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="done" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Fatto !</h4>
                    <p>{{pet_name}} è stato assegnato ad un nuovo proprietario.</p>
                    <p>I dati saranno aggiornati a breve.</p>
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="check" label="ok" color="green" @click="backToUser" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>



<script>
    import * as tools from '../common/tools'
    import * as config from '../common/config'
    import * as bctools from '../common/bctools'
 
    import PetProfileCard from '../components/PetProfileCard.vue'
    import PetActionCard from '../components/PetActionCard.vue'

    export default {
        name : 'Transfer',
        components: {
            PetProfileCard,
            PetActionCard
        },

        props : {            // values passed by caller Commponent via props
            pet_fluid : {
                type: String,
                required : true,
                default : '0x00000000000000000000000000000000000000ZZ',
                validator : (fluid) => bctools.validAddress(fluid),
            },
        },

        data () {            // State(s) function
            return {
                pet_name : '',
                recipient_email : '',
                recipient_fluid : '',
                is_logged : false,
                user_fluid : 0,

                loading : false,
                confirm_dialog_show : false,
                error_dialog_show : false,
                success_dialog_show : false,

                email_regexp : config.Email_RegExp,
                fluid_regexp : config.Fluid_RegExp,
            } 
        },

        computed : {         // Computed on the spot from data values
        },

        methods: {
            getData : async function () {
                let fp = await tools.getFocusPet()
                if (fp == null || fp.fluid != this.pet_fluid) {
                    return
                }
                this.pet_name = fp.rawattributes.name
            },


            preSubmit: async function() {
                // Check that fields are syntactically good
                if (
                    ! this.email_regexp.test(this.recipient_email) ||
                    ! this.fluid_regexp.test(this.recipient_fluid)
                ) {
                    return false
                }
                  
                // check that the email is registered
                let exists = await tools.apiGetUserExists(this.recipient_fluid, this.recipient_email)
                if (! exists.ok ) {
                    console.error(exists)
                    this.error_dialog_show = true
                    return
                }
                // shows the popup that asks for a confirmation before sending
                this.confirm_dialog_show = true
            },

            transferPet : async function() {
                this.loading = true;
                let body = {}
                body.newowner = this.recipient_fluid
                body.newowner_email = this.recipient_email
                let tr = await tools.apiTransferPet(body, this.pet_fluid)
                this.loading = false;
                if (!tr.ok) {
                    console.error(tr)
                    alert(tr.message)
                    return
                }

                // Move pet
                let user = tools.getUser()
                if (!user.pets_transfered) {
                    user.pets_transfered = []
                }
                user.pets_transfered.push(this.pet_fluid)
                user.pets = user.pets.filter(x=>{return x != this.pet_fluid})
                tools.storeUser(user)

                this.success_dialog_show = true;
            },

            // Go back to User Page
            backToUser : function () {
                this.$router.push('/user/'+this.user_fluid)
            }
        },

        watch: {             // Stuff that happend when a variable changes
        },

        // Lifecycle Hooks

        mounted() {
            this.is_logged = tools.checkLogged()
            this.user_fluid = (this.is_logged)  ? (tools.getUser()).user_fluid : 0
            window.scrollTo(0,0)
            this.getData()          
        },

        created() {
        },
    }
</script>



// #css
<style>

</style>