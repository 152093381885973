<template>	
	<div class="infogroup">
		<div class="detail-table-heading">
			<div class="detail-table-heading-title">
				<h3>{{title}}</h3>
			</div>
			<div class="detail-table-heading-buttons" v-if="buttons !== null">
				<q-btn v-for='(but, idx) in buttons' :key='idx'
					color="green" 
					text-color= "white" 
					rounded
					v-bind:label="but.label"
					size="sm"
					v-bind:icon="but.icon"
					class="q-ma-sm"
					@click="genericClick(but)"
				>
					<q-tooltip v-if="but.tooltip" class="text-h6">{{but.tooltip}}</q-tooltip>
				</q-btn>
			</div>
		</div>
    	<div class='scan-data' v-if="details_ui">
	    	<div v-for='(att, name, idx) in details_ui' :key='idx'>
    			<div class="row">
        			<div class="detail-table-property col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            			{{ name }}:
          			</div>
          			<div class="detail-table-value col-xs-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
			            <div style="width:100%;">
							<span class="details-shorten-value" v-html="att.value" />
							<span v-if="att.updates" class="detail-table-warning">({{att.updates}}x<span class="material-icons">restore</span>)</span>
							<span v-if="att.copy" 
								class="detail-table-warning material-icons" 
								v-on:click="clipboardCopy(att.value)"
							>
								<q-tooltip anchor="top middle" self="top middle" :offset="[10, 10]">copia</q-tooltip>
								content_copy
							</span>
						</div>
					</div>
		        </div>
      		</div>
    	</div>
  	</div>
</template>

<script>
	export default {
		name : 'ScanDetails',
		components: {
		},
 
		props : {            // values passed by caller Commponent via props
			// Title of the table
			title : {
				type: String,
				required : false,
				default : 'Elenco Dati',
			},
			// Data displayed in the table
			details : {
				type: Object,
				required : true,
			},
			// Bottons to present on the right side array of icon, label, url
			buttons : {
				type: Array,
				required : false,
				default : [],
			},
			// Did the data get updated?
			updates : {
				type: Object,
				required : false,
				default : {},
			},
			// Can we copy values to the clipboard?
			copy : {
				type: Array,
				required : false,
				default : [],
			},
		},
	
		data () {            // State(s) function
			return {
				details_ui : null
			} 
		},

		created() {
			this.mergeProperties()
		},

		methods: {
			mergeProperties: async function () {
				let details_ui = {}
				// Turn all elements into objects with rich properties
				for (let element in this.details) {
					details_ui[element] = {'value' : this.details[element]}
				}

				if (this.updates) {
					// Add the updates gliphs at the end
					for (let element in this.updates) {				
						if (details_ui[element]) {
							details_ui[element]['updates'] = this.updates[element]
						}
					}
				}

				if(this.copy && navigator.clipboard) {
					for (let element of this.copy) {
						if (details_ui[element]) {
							details_ui[element]['copy'] = true
						}
					}
				}

				this.details_ui = details_ui
			},

			// Sadly this function cannot go in the tools...
			clipboardCopy: function (text) {
				if (navigator.clipboard) {
					navigator.clipboard.writeText(text);
				}
			},

			// Navigator Share
			genericClick : function (button) {
				switch (button.click) {
					case 'share':
						if (navigator.share) {
							navigator.share(button.share_data)
						}  else {
							// allow to send an email if navigator share fails
							let mail_url = 'mailto:?subject='+encodeURIComponent(button.share_data.title)+'&body='+encodeURIComponent(button.share_data.text)+'\nURL: '+encodeURIComponent(button.share_data.url)
							window.open(mail_url, null)
						}
						break
						case 'open' :
							// Goes to the URL in the new window
							window.open(button.url, '_blank')
							break
						default:
						case 'go':
							// Goes to the view/page on the app
							this.$router.push(button.url)
							break
				}
			}
		}
	}
</script>


<style>
</style>