
module.exports = {
    AppVersion  :   process.env.VUE_APP_VERSION,
    TrxBaseUrl  :   process.env.VUE_APP_EXPLORER,
    AppBaseUrl  :   process.env.VUE_APP_BASE_URL,
    ApiBaseUrl  :   process.env.VUE_APP_BASE_URL+'/api',

    ShopBaseUrl :   process.env.VUE_APP_SHOP_URL,

    MapsApiKey  :   process.env.VUE_APP_MAPS_KEY,
    DocBaseUrl  :   'http://localhost/docs',
    
    AvatarSize      : '200',      //size in pixel for pet profile card
    AvatarImgSize   : '400',      //size in pixel for avatar image file
    AvatarPrintSize : '130',      //size in pixel for document print
    AvatarSmallSize : '90',
    MaxImageSide    : 1280,
    MaxFileSize     : 8388608,    // can't upload more than 8MB

    SquareAdvSize   : '150',      // Size of the square ADV banner

    JWT_CookieName  : process.env.VUE_APP_JWT_TOKEN,

    Date_Locale     : 'it-IT',
    Date_Options    : { year: 'numeric', month: '2-digit', day: '2-digit' },
    
    // Regular expressions
    Fluid_RegExp    : /^0x[a-fA-F0-9]{40}$/,
    Email_RegExp    : /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z0-9]{2,20})(\]?)$/,
    Date_RegExp     : /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,

    // Known valid chip formats
    Tag_RegExp : [
        "^[0-9]{15}$",      // ISO FDXB
        // "^[0-9A-Z]{10}$",   // ISO FDXA
        // "^[0-9]{9}$",
        // "^[0-9]{10}$",
    ],

    // Default interface options
    DefaultInterface:   {
        sort: 'alphaNameSort', 
        filter: {}, 
        show: false,
        popup: true,
    }
}


