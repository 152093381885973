<template>
    <h3 class="h3 mb-3 fw-normal q-px-lg">ID Documento: {{ this.short_hash }}</h3>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white" 
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="goToPet()"
        ></q-btn>
    </div>

    <!-- Show doc contents -->
    <div v-if="fetched.ok && !this.forbidden">
        <div>
            <ScanDetails v-bind:details="details" />
        </div>

        <div class="text-center infogroup" >
            <!-- Display if Image -->
            <div v-if="fetched.data.mimetype.includes('image/')">
                <a v-bind:href="file_url">
                    <q-img
                        v-bind:src="file_url"
                        spinner-color="white"
                    />
                </a>
            </div>

            <!-- Display if text -->
            <div v-if="fetched.data.mimetype.includes('text/')">
            "{{this.txt_content}}"
            </div>

            <!-- Display if PDF -->
            <div v-if="fetched.data.mimetype.includes('application/pdf')">
            
                <VuePdf 
                    v-bind:src="this.file_url"
                    v-bind:page="this.pdf_page"
                    :key="this.pdf_page"
                />

                <div class="text-center q-mx-xl">
                    <q-btn 
                        v-if="(this.pdf_page > 1)"
                        color="navigate" 
                        text-color="white" 
                        rounded
                        size="md"
                        icon="arrow_back"
                        class="q-ma-sm" 
                        type="submit"
                        @click="flipPdfPage(-1)"
                    ></q-btn>
                    {{this.pdf_page}}/{{this.pdf_max_page}}
                    <q-btn 
                        v-if="(this.pdf_page < this.pdf_max_page)"
                        color="navigate" 
                        text-color="white" 
                        rounded
                        size="md"
                        icon="arrow_forward"
                        class="q-ma-sm" 
                        type="submit"
                        @click="flipPdfPage(+1)"
                    ></q-btn>
                </div>

            </div>


        </div>

        <div class="text-center">
            <q-btn 
                color="green" 
                text-color="white" 
                size="lg"
                rounded
                icon="cloud_download"
                type="a"
                v-bind:href="file_url"
                class="q-ma-sm" 
                label="Download"
            ></q-btn>
            
            <q-btn  v-if="is_owner"
                color="green" 
                text-color="white"
                rounded
                size="lg"
                icon="delete"
                class="q-ma-sm" 
                label="richiedi cancellazione"
                @click="sendDeleteMail()"
            ></q-btn>
        </div>
    </div>

    <!-- Error Dialog -->
    <q-dialog v-model="forbidden" persistent>
        <q-card>
            <q-card-section class="row items-center">
                <q-avatar icon="warning" color="green"></q-avatar>
                <span class="q-ml-sm">
                <h4>Attenzione !</h4>
                Il documento richiesto è privato e non hai le credenziali 
                necessarie per accedervi.
                <span v-html="input_error"></span>
                </span>
            </q-card-section>
            <q-card-actions align="right">
                <q-btn flat icon="close" label="OK" color="green" @click="goToPet" v-close-popup></q-btn>
            </q-card-actions>
        </q-card>
    </q-dialog>

</template>


<script>
    import * as bctools from '../common/bctools' 
    import * as tools from '../common/tools'
    import {VuePdf, createLoadingTask} from 'vue3-pdfjs/esm'
    // import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
    
    import ScanDetails from '../components/Scan/Scan_Details'

    export default {
        name : 'Docs',
        components: {
            ScanDetails,
            VuePdf,
        },
    
 
        props : {            // values passed by caller Commponent via props
            doc_hash : {
                type: String,
                required : true,
                default : '0x00000000000000000000000000000000000000000000000000000000000000ZZ',
                validator : (dh) => bctools.validBytes(32, dh),
            },
        },
 
        data () {            // State(s) function
            return {
                fetched : '',
                img_url : '',
                txt_content : '',
                file_url : '',
                pdf_page : 1,
                pdf_max_page : 0,
                short_hash : tools.shortHash(this.doc_hash),
                details : null,
                is_owner : '',
                fluid : '',
                forbidden : false,
                delete_url : ''
            } 
        },
 
        computed : {         // Computed on the spot from data values
        },
 
        methods: {           // Component functions
            initialize : async function () {
                let fetched = await tools.apiGet('/docs/'+this.doc_hash)
                if (!fetched.ok) {
                    // console.error("E1: ", fetched.message)
                    return
                }
                // Handle received data
                this.file_url = fetched.data.url
                let mime = fetched.data.mimetype.split('/')[0]
                switch (mime) {
                    case 'image':
                        this.img_url = '<img src="'+fetched.data.url+'"/>'
                        break;
                    case 'text' :
                        let r = await tools.downloadUrl(fetched.data.url)
                        if (r.ok) {
                            this.txt_content = r.content
                        }
                        break;
                    case 'application' :
                        // Check that it's actually a PDF
                        if (fetched.data.mimetype.split('/')[1] != "pdf") {
                            break
                        }
                        // Load the PDF to get the total number of pages
                        const loadingTask = createLoadingTask(this.file_url)
                        let pdf = await (loadingTask.promise)
                        this.pdf_max_page = pdf.numPages
                        break
                    default:
                        break;
                }

                let fetchdate = await tools.apiGet('/util/tx/'+fetched.data.transaction)
                if (fetchdate.ok) {
                    fetched.data.uploaddate = (new Date(fetchdate.timestamp*1000)).toLocaleDateString('it-IT')                
                }

                // Remove unneded data:
                delete fetched.data.path
                delete fetched.data.url
                this.details = await tools.translateDetails(fetched.data)

                // Update delete mail link
                this.delete_url = 'mailto:info@fauna.life?subject=Richiesta%20cancellazione%20documento%20'+this.doc_hash+'&body='
                this.delete_url += encodeURIComponent('La presente per richiedere la cancellazione\ndel documento '+this.doc_hash+'\nper la seguente motivazione:\n')
                
                // Render
                this.fetched = fetched
            },

            flipPdfPage : function (inc) {
                this.pdf_page += inc
                this.pdf_page = Math.min(this.pdf_page, this.pdf_max_page)
                this.pdf_page = Math.max(this.pdf_page, 1)
            },

            goToPet : async function () {
                let to = await tools.getFocusPet(false)
                this.$router.push('/scan/'+to)
            },
            sendDeleteMail : function () {
                window.open(this.delete_url, null);
            }
        },
 
        // watch: {
        // },
 
        // Lifecycle Hooks
    
        async created() {
            // Should the document be shown ??
            let pet = await tools.getFocusPet()
            this.fluid = pet.fluid
            this.is_owner = tools.userHasPet(this.fluid)
            let ownerRole = parseInt((await tools.apiGetUser(pet.owners[pet.owners.length-1])).attributes.role)
            switch (ownerRole) {
                case 1 : // breeder
                case 2 : // vet
                case 3 : // group / association
                case 4 : // company
                    // Document is public, just go on, show document
                    this.initialize()                
                    break
                case 0 : // owner 
                default: // Unrecognized
                    // Document is private ... un less viewer is owner
                    if(this.is_owner) {
                        this.initialize()
                    } else {
                        this.forbidden = true
                    }

                    break
            }

            window.scrollTo(0,0)
        }
    }
</script>



// #css
<style>
</style>