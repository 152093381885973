<template>
    <!-- TAB-TABS -->
    <div class="infogroup">
         <div class="detail-table-heading">
            <div class="detail-table-heading-title">
                <h3>Documenti:</h3> 
            </div>
            <div class="detail-table-heading-buttons" v-if="is_owner">
                <q-toggle v-if="has_missing"
                    color="green"
                    label="Mostra mancanti"
                    v-model="show_missing"
                    v-on:update:model-value="show_hide"
                ></q-toggle>
                <q-btn 
                color="green" 
                text-color="white" 
                rounded
                label=""
                size="md"
                icon="note_add"
                class="q-ma-sm" 
                @click="$router.push({path : '/upload/'+fluid})"
                ></q-btn>
            </div>
        </div>

        <div v-if="loading" class="row q-mb-xl text-center">
            <q-spinner-ios
                color="green"
                size="6em"
                :thickness="9"
            ></q-spinner-ios>
        </div>


        <!-- <h3>Documenti caricati:</h3>  -->
        <div v-else id="q-app" >

            <div v-if="visbile_library && Object.keys(visbile_library).length > 0">
            <!-- <div v-if="true"> -->
                
                <div class="q-py-md">
                    <div class="q-gutter-y-md">
                    <q-card>
                        <q-tabs
                            v-model="docTab"
                            active-bg-color="green-3"
                            class="text-green"
                            inline-label
                            active-color="primary"
                            align="justify"
                            narrow-indicator
                            indicator-color="green"
                        >
                            <q-tab 
                                v-for="(mtype, idx) in Object.keys(visbile_library)" :key='idx' 
                                v-bind:name="mtype" 
                                v-bind:label="mtype"
                            ></q-tab>                        
                        </q-tabs>

                        <q-separator></q-separator>

                        <q-tab-panels v-model="docTab" animated>
                            <q-tab-panel
                                v-for="(mtype, idx) in Object.keys(visbile_library)" :key='idx'
                                v-bind:name="mtype"
                            >
                                <div v-for="(stype, idx) in Object.keys(visbile_library[mtype])" :key='idx'>
                                    {{stype}}
                                    <hr>
                                    <table class="FLData">
                                        <tr>
                                            <td>#</td>
                                            <!-- <td>Nome File</td> -->
                                            <td>Descrizione</td>
                                            <td>Tags</td>
                                            <td>Dimensione</td>
                                            <td>Data</td>
                                            <!-- <td>Hash</td> -->
                                        </tr>
                                        <!--    -->
                                        <tr v-for="(doc, idx) in visbile_library[mtype][stype]"  
                                            :key='idx' 
                                        >
                                            <td><router-link v-bind:to="doc.docpage">{{idx+1}}</router-link></td>
                                            <td><router-link v-bind:to="doc.docpage">{{doc.description}}</router-link></td>
                                            <td>
                                                &nbsp;
                                                <span v-for="(tag, idx) in doc.tags" :key='idx' class="q-mx-xs">
                                                    <q-badge color="green" align="middle" v-if="tag!='missing'">
                                                        <span v-html="tag" />
                                                    </q-badge>
                                                    <q-badge color="red" align="middle" v-else>
                                                        <span v-html="tag" />
                                                    </q-badge>
                                                </span>
                                            </td>
                                            <td><router-link v-bind:to="doc.docpage">{{doc.size}}</router-link></td>
                                            <td><router-link v-bind:to="doc.docpage">{{doc.date}}</router-link></td>
                                        </tr>                                
                                    </table>                                
                                </div>
                            </q-tab-panel>

                        </q-tab-panels>
                    </q-card>
                    </div>
                </div>
            </div>
            <div v-else class="q-mx-lg">
                {{pet_name}} non ha ancora nessun documento!
                <div v-if="is_owner">
                    Clicca sul pulsante <span class="material-icons">note_add</span> e inserisci i suoi documenti per averli sempre con te
                </div>
            </div>
        </div>



    </div>
</template>



<script>
  import * as tools from '../../common/tools'
  import * as bctools from '../../common/bctools'
  import * as config from '../../common/config'

  export default {
    name : 'ScanDocs',
    props : {
    },
    data () {
        return {
            fluid : '',
            docs : [],
            library : {},
            visbile_library : {},
            docTab : '',
            is_owner : false,
            show_missing : false,
            has_missing : false,
            loading : true,
            pet_name : '',
        }
    },
 
    // Lifecycle Hooks
    async created() {
        await this.getData()
        this.is_owner = tools.userHasPet(this.fluid)
        await this.prepareLibrary()
    },

    methods: {
        getData : async function () {
            let fp = await tools.getFocusPet()
            if (fp == null) {
                return
            }
            this.fluid = fp.fluid
            this.docs = fp.doc
            this.pet_name = fp.rawattributes.name
        },

        show_hide: function() {
            if (this.show_missing) {
                // Deepcopy all
                this.visbile_library = JSON.parse(JSON.stringify(this.library))
            } else {
                // Manual deepcopy with 2 nested levels and the array filter
                this.visbile_library = {}
                Object.keys(this.library).forEach( (type,i) => {
                    this.visbile_library[type] = {}
                    Object.keys(this.library[type]).forEach( (subtype,i) => {
                         this.visbile_library[type][subtype] = this.library[type][subtype].filter( x => { return ! x.tags.includes('missing') })
                         if (Object.keys(this.visbile_library[type][subtype]).length == 0) {
                        delete this.visbile_library[type][subtype]
                    } 
                    })
                    if (Object.keys(this.visbile_library[type]).length == 0) {
                        delete this.visbile_library[type]
                    }
                })
            }
        },

        prepareLibrary: async function() {
            this.loading = true

            if (Array.isArray(this.docs) && this.docs.length > 0) {
                // Library is a dictionary divided in 
                // mtype - main type (ex: medical)
                // stype - sub type (ex: xr)

                this.library = {}
                let proms = []
                for (let o of this.docs) {
                    // Tabbed tabs content
                    let mtype = o.doctype
                    let stype = o.docsubtype
                    // proms.push(this.getFileData(o.dochash, mtype, stype))
                    proms.push(tools.cacheGetDoc(o.dochash, mtype, stype))
                }
                let resps = await Promise.allSettled(proms)
                for (let resp of resps) {
                    if (resp.status != 'fulfilled') {
                        console.error(resp.value.message)
                    } else {
                        let filedata = resp.value
                        filedata.docpage = '/docs/'+filedata.hash
                        this.has_missing = this.has_missing || filedata.missing == true
                        let mtype = filedata.mtype
                        let stype = filedata.stype
                        delete filedata.mtype
                        delete filedata.stype
                        
                        if (!this.library[mtype]) {          
                            this.library[mtype] = {}
                        }
                        if (!this.library[mtype][stype]) {
                            this.library[mtype][stype] = []
                        }

                        this.library[mtype][stype].push(filedata)
                    }
                }

                // Go for the first tab (if any)
                if (Object.keys(this.library).length >0 ) {
                    this.docTab = Object.keys(this.library)[0]
                }

            }
            // Copy library to visible_library, once
            this.show_hide()
            this.loading = false
        }
    }
}
</script>


<style>
    table.FLData {
        width: 100%;
        text-align: center;
    }
    table.FLData td, table.FLData th {
        border-top: 1px solid #247838;
        padding: 3px 2px;
    }
    table.FLData tr:nth-child(even) {
        background: #E9FFE9;
    }
    table.FLData thead {
        background: #2E472B;
    }
    table.FLData thead th {
        font-weight: normal;
        color: #FFFFFF;
        text-align: center;
        border-left: 2px solid #D0E4F5;
    }
    table.FLData thead th:first-child {
        border-left: none;
    }

    table.FLData tfoot td {
        font-size: 21px;
    }
</style>