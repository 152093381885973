<template>
    <!-- Back Button -->
    <div class="text-center q-mx-xl">
        <q-btn 
            color="navigate" 
            text-color="white" 
            rounded
            size="lg"
            icon="arrow_back"
            class="q-ma-sm" 
            label="Indietro" 
            type="submit"
            @click="goToPet()"
        ></q-btn>
    </div>
    
    <div class="container q-px-xxs">
        <h3 class="q-px-xl">Modifica i dati del tuo animale</h3>
        <form>
            <!-- NAME & TAG -->
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Nome del pet
                    <q-input id="pet_name" v-model.lazy="pet_name" type="text" 
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Modifica il nome" 
                        label=""
                        maxlength=64
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="red" size="14px"></q-icon>
                        </template>
                    </q-input>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Numero Microchip
                    <q-input id="pet_tag" v-model.lazy="pet_tag" type="text" 
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Non modificabile" 
                        label=""
                        readonly
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="white" size="14px"></q-icon>
                        </template>                    
                    </q-input>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Data di nascita
                    <q-input v-model="date_birth" mask="##/##/####" :rules="[ d => date_regex.test(d) || 'Data errata']"
                        outlined
                        rounded
                        label-color="green"
                        color="green"                        
                        hint="Modifica la data di nascita"
                        label=""
                    >
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="date_birth" color="green"
                                        v-bind:navigation-max-year-month="max_year_month"
                                        mask="DD/MM/YYYY"
                                    >
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="OK" color="green" flat></q-btn>
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>
                        <template v-slot:after>
                            <q-icon name="pets" color="red" size="14px"></q-icon>
                        </template>      
                    </q-input>
                </div>
            </div>

            <!-- Species & Breed -->
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Specie
                    <q-input id="sel_species" v-model.lazy="sel_species" type="text" 
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Non modificabile" 
                        label=""
                        readonly
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="white" size="14px"></q-icon>
                        </template>                    
                    </q-input>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Razza
                    <q-select 
                        outlined 
                        rounded
                        v-model="sel_breed"
                        v-bind:options="opt_breed_filtered" 
                        label-color="green"
                        color="green"
                        label=""
                        use-input
                        @filter="filterBreed"
                        behavior="dialog"
                        hint="Moificare la razza (Inizia a scrivere e scegli dalla lista)"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="red" size="14px"></q-icon>
                        </template>
                        <template v-slot:selected-item="scope">
                            <div class="ellipsis">{{scope.opt}}</div>
                        </template>
                        <q-tooltip class="text-h6">{{sel_breed}}</q-tooltip>
                    </q-select>
                </div>
            </div>

            <!-- Geolocation -->
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Paese
                    <q-select id="sel_state" v-model="sel_state" 
                        v-bind:options="opt_state"
                        v-on:update:model-value="update_region_list()"
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        label="" 
                        hint="Modifica il Paese di residenza"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="red" size="14px"></q-icon>
                        </template>                     
                    </q-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    Regione
                    <q-select id="sel_region" v-model="sel_region" 
                        v-bind:options="opt_region"
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        label="" 
                        hint="Modifica la Regione di residenza"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="red" size="14px"></q-icon>
                        </template>                     
                    </q-select>                    
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 q-my-lg q-px-lg">
                    {{zip_name}}
                    <q-input id="zip" v-model.lazy="zip" type="text" 
                        outlined
                        rounded
                        label-color="green"
                        color="green"
                        hint="Modifica il codice postale" 
                        label=""
                        mask="#####"
                        :error="zip_error"
                        error-message="Valore non conforme"
                        @blur="isValidZip()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="red" size="14px"></q-icon>
                        </template>
                    </q-input>
                </div>
            </div>

            <!-- Weight Build --> 
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Peso (kg)
                    <q-input id="pet_weight" v-model.lazy="weight" type="number" 
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        hint="Aggiorna il peso in kg (es: 2.400)" 
                        label=""
                        :error="weight_error"
                        error-message="Peso non conforme"
                        @blur="isValidWeight()"
                    >
                        <template v-slot:after>
                            <q-icon name="pets" color="white" size="14px"></q-icon>
                        </template>                    
                    </q-input>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Taglia
                     <q-select id="sel_build" v-model="sel_build" 
                        :options="opt_build"
                        outlined 
                        rounded
                        label-color="green"
                        color="green"
                        label="" 
                        hint="Modifica la taglia (xxs...xxl)"
                     >
                        <template v-slot:after>
                            <q-icon name="pets" color="white" size="14px"></q-icon>
                        </template>                     
                     </q-select>
                </div>
            </div>

            <!-- Gender and Reproduction-->
            <div class="row">
                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    <div class="row items-center">
                        <div class="col-6 text-right">
                            Genere: <q-icon name="pets" :color="icm_gender" size="25px"></q-icon>
                        </div>
                        <div class="col-6 self-center">
                            <q-radio 
                                keep-color 
                                v-model="gender" 
                                val="M"   
                                label="Maschio" 
                                color="green" 
                                dense
                                @update:model-value="changed_gender(value, evt)"
                            ></q-radio><br>
                            <q-radio 
                                keep-color
                                v-model="gender"
                                val="F"
                                label="Femmina"
                                color="green"
                                dense
                                @update:model-value="changed_gender(value, evt)"
                            ></q-radio>
                        </div>
                    </div>
                </div>

                <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    <div class="row items-center">
                        <div class="col-6 text-right">
                        Sterilizzato:
                        </div>
                        <div class="col-6">
                            <q-radio 
                                keep-color 
                                v-model="neutered" 
                                val="N"   
                                label="No" 
                                color="green" 
                                dense
                            ></q-radio><br>
                            <q-radio 
                                keep-color
                                v-model="neutered"
                                val="Y"
                                label="Si"
                                color="green"
                                dense
                            ></q-radio>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Death -->
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                    Data di decesso
                    <q-input v-model="date_death" mask="##/##/####" :rules="[ d => date_regex.test(d) || 'Data errata']"
                        outlined
                        rounded
                        label-color="green"
                        color="green"                        
                        hint="Inserisci la data di decesso"
                        label=""
                    >
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="date_death" color="green"
                                        v-bind:navigation-max-year-month="max_year_month"
                                        mask="DD/MM/YYYY"
                                    >
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="OK" color="green" flat></q-btn>
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>
                        <template v-slot:after>
                            <q-icon name="pets" color="white" size="14px"></q-icon>
                        </template>      
                    </q-input>
                </div>
                <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 q-my-lg q-px-lg">
                </div> -->
            </div>


            <!-- Mandatory marker -->
            <div class="q-mt-xl text-center q-pa-md">
                Campi richiesti <q-icon name="pets" color="red" size="14px"/>
            </div>

            <!-- Update Button  -->
            <div v-if='loading==false' class="q-mb-lg q-pa-sm text-center">
                <q-btn 
                    color="green"
                    rounded
                    text-color="white" 
                    size="lg"
                    icon="update"
                    class="q-ma-sm" 
                    label="Aggiorna" 
                    @click="preSubmit()"
                ></q-btn>
            </div>
            <div v-else class="row q-mb-xl text-center">
                <q-spinner-ios
                    color="green"
                    size="6em"
                    :thickness="9"
                ></q-spinner-ios>
            </div>
            
        </form>

        <!-- Confirm Dialog -->
        <q-dialog v-model="confirm_dialog_show" persistent>
            <q-card class="q-ma-sm">
                <q-card-section class="row items-center">
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                        <q-avatar v-if="date_death!=''" icon="sentiment_dissatisfied" color="grey" size="100px"></q-avatar>
                        <q-avatar v-else icon="warning" color="green" size="100px"></q-avatar>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <span class="q-ml-sm">
                            <h4>Attenzione !</h4>
                            <p>Controlla le seguenti modifiche prima di procedere:</p>
                            <span v-for="(key, value, idx) in this.translated_payload" :key='idx'>
                                <span v-html="value" /> : <span v-html="key" /><br>
                            </span>
                        </span>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
                    <q-btn flat icon="check" label="Confermo" color="green" @click="submitPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Error Dialog -->
        <q-dialog v-model="error_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="warning" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Attenzione !</h4>
                    Dati inseriti incompleti o errati:
                    <span v-html="input_error"></span>
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="Annulla" color="red" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- Done Dialog -->
        <q-dialog v-model="done_dialog_show" persistent>
            <q-card>
                <q-card-section class="row items-center">
                  <q-avatar icon="check" color="green"></q-avatar>
                  <span class="q-ml-sm">
                    <h4>Fatto !</h4>
                    La transazione è stata presa in consegna. il nuovo profilo sarà visibile a breve.                    
                  </span>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat icon="close" label="OK" color="green" @click="goToPet" v-close-popup></q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>
 
 
 
<script>
    import * as tools from '../common/tools'
    import * as config from '../common/config'
    import Datepicker from 'vue3-datepicker'

    import VueCropper from 'vue-cropperjs'
    import 'cropperjs/dist/cropper.css'
import { json } from 'body-parser'

    const CROP_NONE = 0
    const CROP_START = 1
    const CROP_DONE = 2

    export default {
        name : 'AddPet',
        components: {
            Datepicker,
            VueCropper
        },

        props : {
            pet_fluid :{
            type: String,
            required : true
            }
        },

        data () {   
            return {
                // Pet Subject
                pet : {},

                //Date Handling
                date_regex : config.Date_RegExp,

                // Form data
                pet_name : '',
                pet_tag : '',
                gender : '',
                neutered : '',
                weight : '0',
                zip : '',
                zip_name : 'CAP',

                // Drop Down lists, selections and values
                species_list : '',
                breed_list :'',
                state_list : '',
                region_list : '',

                sel_species : '',
                sel_breed : '',
                sel_build : '',
                sel_state : '',
                sel_region : '',
                
                opt_species : '',
                opt_breed : '',
                opt_breed_filtered : '',
                opt_build : ['XXS','XS','S','M','L','XL','XXL'],
                opt_state : '',
                opt_region : '',
                
                // Dates
                todaystr : '',
                max_year_month : '',
                date_birth : '',
                date_chip : '',
                date_death : '',
                // date_sterilized : '',

                // Session
                owner_fluid : tools.getUser().user_fluid,

                // Error Handling
                weight_error :false,
                chip_error : false,
                zip_error : false,
                input_error : '',

                // Status
                loading : false,
                confirm_dialog_show : false,
                done_dialog_show : false,
                error_dialog_show : false,
                initial_data :  {},
                payload : {},
                translated_payload : {},
            } 
        },

        computed : {
        },

        methods: {
            check_can_send : function () {
                let error = ''

                if (this.isValidZip() == false) {error = this.zip_name+' non valido'}
                if (this.zip == '') {error = 'Specificare '+this.zip_name} 
                
                if (this.gender == '') {error = 'Specificare sesso'} 
                if (this.date_birth == '') {error = 'Indicare data di nascita'} 
                if (this.sel_region == '') {error = 'Selezionare Regione'} 
                if (this.sel_state == '') {error = 'Selezionare Stato'} 
                if (this.sel_breed == '') {error = 'Selezionare Razza'} 
                if (this.pet_name == '') {error = 'Attribuire nome'} 

                let td = tools.euDate2Date(this.todaystr)
                let bd = (this.date_birth == '') ? null : tools.euDate2Date(this.date_birth)
                // let cd = (this.date_chip == '') ?  null : tools.euDate2Date(this.date_chip)
                let dd = (this.date_death == '') ? null : tools.euDate2Date(this.date_death)
                if ( 
                   // (cd && cd > td) ||          // can't chip in the future
                    (bd && bd > td) ||          // can't be born in the future
                    (dd && dd > td) ||          // can't be dead in the future
                   // (cd && bd && cd < bd) ||    // can't be chipped before born
                    (dd && bd && dd < bd)       // can't be dead before born
                   //  (dd && cd && dd < cd)       // can't be dead before chipped
                ) { 
                    console.error("D: ", td, bd, dd, cd)    
                    error = "Valori delle date non plausibili" 
                }

                if (tools.checkLogged() == false) {error = 'Utente non loggato'} 

                if (error == '') {
                    return true
                }
                console.error(error)
                this.input_error = error
                this.error_dialog_show = true
                return false
            },

            isValidZip : function() {
                // Valid only in Italy
                let zipRegEx =''
                switch (this.sel_state) {
                    case 'Switzerland' :
                        zipRegEx = "^[1-9][0-9]{3}$"
                        break
                    case 'Italy' :
                    default:
                        zipRegEx = "^[0-9]{5}$"
                        break
                }
                // Include empty string
                zipRegEx = "("+zipRegEx+")|^$"
                let rx = new RegExp(zipRegEx)
                if (this.zip.match(rx) !== null) {
                    this.zip_error = false
                    return true
                }
                this.zip_error = true
                return false
            },

            isValidWeight : function() {
                let rx = new RegExp(/^([0-9]{1,3}[,.])?[0-9]{1,3}$/gm)
                let w = this.weight.toString()
                this.weight_error = (w.match(rx) == null)
                return !this.weight_error
            },

            checkLogged : function () {
                // If it's not a logged user redirect it to login
                if (!tools.checkLogged()) {
                    this.$router.push({path : '/login'})
                }
            },

            // Safely get a property from an object if it exists
            copyCheck : function (obj, key) {
                if ( 
                    obj !== null &&
                    obj &&
                    obj.toString() === "[object Object]" &&
                    obj.hasOwnProperty(key)
                )
                    return obj[key]
                return undefined
            },

            initialize : async function () {
                // Set Dates
                const yourDate      = new Date()
                this.todaystr       = yourDate.toLocaleDateString(config.Date_Locale, config.Date_Options)
                this.max_year_month = yourDate.getFullYear()+'/'+(yourDate.getMonth()+1)
                // this.date_birth     = this.todaystr

                // Get non-null  non undefined fields from focused pet
                this.pet_name       = this.copyCheck(this.pet.rawattributes, 'name')
                this.pet_tag        = this.copyCheck(this.pet.rawattributes, 'idtag')
                this.sel_species    = this.copyCheck(this.pet.rawattributes, 'species')
                this.zip            = this.copyCheck(this.pet.rawattributes, 'geo_zip')
                this.date_birth     = tools.date2EUDate(this.copyCheck(this.pet.rawattributes, 'birth'))
                this.date_chip      = tools.date2EUDate(this.copyCheck(this.pet.rawattributes, 'chipdate'))
                this.date_death     = tools.date2EUDate(this.copyCheck(this.pet.rawattributes, 'death'))
                // this.date_sterilized= tools.date2EUDate(this.copyCheck(this.pet.rawattributes, 'neutered'))
                this.sel_build      = this.copyCheck(this.pet.rawattributes, 'build')
                this.weight         = this.copyCheck(this.pet.rawattributes, 'weight')
                this.weight         = this.weight ? this.weight.split(' ')[0] : undefined
                this.gender         = this.copyCheck(this.pet.rawattributes, 'gender')
                this.neutered       = (this.pet.rawcodes['neutered_b'] && parseInt(this.pet.rawcodes['neutered_b']) != 0) ? 'Y' : 'N'

                await this.update_breed_list()

                // Get geo values
                this.state_list =  await tools.apiGet('/util/lookup/countries/?distinct=true')
                if (!this.state_list.ok) {
                    console.error(this.state_list.message)
                    return
                }
                this.opt_state = [ ...new Set(this.state_list.map(x => {return x.country_name}))]
                this.sel_state = this.pet.rawattributes.geo_state || this.opt_state[0]
                await this.update_region_list()
                this.sel_region = this.pet.rawattributes.geo_region || this.opt_region[0]

                // Create/Copy initial state object (use raw values)
                this.initial_data['name'] = this.copyCheck(this.pet.rawattributes, 'name')
                this.initial_data['species'] = this.copyCheck(this.pet.rawcodes, 'species')
                this.initial_data['breed'] = this.copyCheck(this.pet.rawcodes, 'breed')
                this.initial_data['birth'] = tools.date2ISO(this.copyCheck(this.pet.rawattributes, 'birth'))
                this.initial_data['chipdate'] = tools.date2ISO(this.copyCheck(this.pet.rawattributes, 'chipdate'))
                this.initial_data['geo_state'] = this.copyCheck(this.pet.rawcodes, 'geo_state')
                this.initial_data['geo_region'] = this.copyCheck(this.pet.rawcodes, 'geo_region')
                this.initial_data['geo_zip'] = this.copyCheck(this.pet.rawattributes, 'geo_zip')
                this.initial_data['weight'] = this.weight ? this.weight * 1000 : undefined
                this.initial_data['build'] = this.copyCheck(this.pet.rawcodes, 'build')
                this.initial_data['gender'] = this.gender
                this.initial_data['neutered'] = tools.date2ISO(this.copyCheck(this.pet.rawattributes, 'neutered'))
                this.initial_data['neutered_b'] = this.pet.rawcodes['neutered_b'] || '0x00'

                // Clean object
                for (const key in this.initial_data) {
                    if (this.initial_data[key] === undefined) {
                        delete this.initial_data[key];
                    }
                }
            },

            compose_breed_option : function (x) {
                // Remove "numbers before name" ordering trick
                let opt = x.breed_name.replace(/^[0-9]+ /, '') 
                // Add Eng and Original between brackets and remove duplicates
                // Put into two sets to remove duplicates
                let opt_set = new Set(opt.split(', '))
                let en = x.EN.replace(/^[0-9]+ /, '')
                let or = x.ORIGINAL.replace(/^[0-9]+ /, '')
                let oth_set = new Set([en, or].join(', ').split(', '))
                oth_set.delete('')
                // Get element of eng+orig that are not in name
                let diff = [...oth_set].filter( x => { return !opt_set.has(x)})
                if (diff.length > 0) {
                    opt += ' ('
                    opt += (diff.length > 1) ? diff.join(', ') : diff[0]
                    opt += ')'
                }
                return opt
            },

            update_breed_list : async function () {
                let species_id = this.pet.rawcodes.species
                this.breed_list = await tools.apiGet('/util/lookup/breeds/?distinct=true&species_id='+parseInt(species_id)+'&cols=breed_id,IT as breed_name,EN,ORIGINAL')
                if (!this.breed_list.ok) {
                    console.error(this.breed_list.message)
                    return
                }
                delete this.breed_list.ok
                
                // remove empty lines and order by name 
                // some names might have a number in front to alter ordering
                this.breed_list = this.breed_list
                    .filter(x => {return x.breed_name && x.breed_name != ''})
                    .sort( (a,b)=> { 
                        let o = a.breed_name.localeCompare(b.breed_name, 'it')  
                        return (o > 0) ? 1 : ( (o<0)? -1 : 0 ) 
                    })

               // Compose "complex" option list = "name (english, original)"
                this.opt_breed = this.breed_list.map(x=>{ return this.compose_breed_option(x)})
                if (this.opt_breed.length == 0) {
                    this.opt_breed[0] = '(non definito)'
                }
                this.opt_breed_filtered = this.opt_breed


                this.sel_breed = this.pet.rawattributes.breed.replace(/^[0-9]+ /, '')

                let sel = this.breed_list.filter(x=>{
                    return x.breed_id == this.pet.rawcodes.breed
                })[0]
                this.sel_breed = this.compose_breed_option(sel)
            },

            update_region_list : async function () {
                let state_id = this.state_list.filter(x => {return x.country_name == this.sel_state}).map(x=>{return x.id})
                this.region_list = await tools.apiGet('/util/lookup/regions/?distinct=true&country_id='+state_id)
                if (!this.region_list.ok) {
                    console.error(this.region_list.message)
                    return
                }
                delete this.region_list.ok
  
                // remove empty lines and order by name 
                // some names might have a number in front to alter ordering
                this.region_list = this.region_list
                    .filter(x => {return x.region_name && x.region_name != ''})
                    .sort( (a,b)=> { 
                        let o = a.region_name.localeCompare(b.region_name, 'it', { sensitivity: 'variant' })  
                        return (o > 0) ? 1 : ( (o<0)? -1 : 0 )
                    })
                this.opt_region = this.region_list.map(x=>{return x.region_name})
                this.sel_region = this.opt_region[0]

                // Set the proper ZIP Code name
                switch (this.sel_state) {
                    case 'Switzerland' :
                        this.zip_name = 'NPA'
                        break
                    case 'Italy' :
                    default:
                        this.zip_name = 'CAP' 
                        break
                }
                this.isValidZip()
            },

            filterBreed : async function(val, update, abort) {
                update(() => {
                    const needle = val.toLocaleLowerCase()
                    this.opt_breed_filtered = this.opt_breed.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
                })
            },

            preSubmit: async function() {
                // minimal data check
                if (!this.check_can_send()) {
                    return
                }
                // Check some changes occurred
                await this.formPayload()
                if (Object.keys(this.payload).length === 0) {
                    this.input_error = "Nessuna modifica !"
                    this.error_dialog_show = true
                    return
                }
                
                // shows the popup that asks for a confirmation before sending
                this.confirm_dialog_show = true
            },

            formPayload: async function() {
                let td = tools.euDate2ISO(this.todaystr)

                let payload = {}
                payload.name = this.pet_name
                payload.breed = this.breed_list[this.opt_breed.indexOf(this.sel_breed)].breed_id
                payload.birth = (this.date_birth == '') ? undefined : tools.euDate2ISO(this.date_birth)
                // payload.chipdate = (this.date_chip == '') ? undefined : tools.euDate2ISO(this.date_chip)
                payload.death = (this.date_death == '') ? undefined : tools.euDate2ISO(this.date_death)
                // payload.neutered = (this.date_sterilized == '') ? undefined : tools.euDate2ISO(this.date_sterilized)
                payload.neutered_b = (this.neutered == 'Y')? "0x01" : "0x00"
                payload.geo_state = this.state_list.filter(x=>{return x.country_name==this.sel_state}).map(x=>{return x.id})[0]
                payload.geo_region = this.region_list.filter(x=>{return x.region_name==this.sel_region}).map(x=>{return x.region_id})[0]
                
                payload.gender = this.gender
                payload.geo_zip = this.zip
                // weight
                let local_weight = parseFloat(this.weight)*1000
                if (!isNaN(local_weight) &&  local_weight > 0 && local_weight < 999999) {
                    payload.weight = local_weight
                }
                // build
                payload.build = this.opt_build.indexOf(this.sel_build)
                if (payload.build <0 ) {
                    delete payload.build
                } 

                // Clean object
                for (const key in this.initial_data) {
                    if (payload.hasOwnProperty(key) && payload[key] == this.initial_data[key]) {
                        delete payload[key]
                    }
                }
                for (const key in payload) {
                    if (payload[key] == undefined || payload[key] == '') {
                        delete payload[key];
                    }
                }

                // Save payload object (dseep copy)
                this.payload = JSON.parse(JSON.stringify(payload));

                // Translate Payload for dialog using lookups
                if (payload['breed']) {payload['breed'] = this.sel_breed}
                if (payload['geo_state']) {payload['geo_state'] = this.sel_state}
                if (payload['geo_region']) {payload['geo_region'] = this.sel_region}
                if (payload['build']) {payload['build'] = this.sel_build}
                if (payload['weight']) {payload['weight'] = payload['weight']/1000}
                this.translated_payload = await tools.translateDetails(payload)
            },

            submitPet : async function () {
                this.confirm_dialog_show = false
                // Sanity check before sending     
                if (!this.check_can_send()) {
                    return
                }
                // Start the spinner
                this.loading = true
                // get the clean payload
                await this.formPayload()
                // Send TX
                let sent = await tools.apiFluidSetData(this.payload, this.pet_fluid)
                if (!sent.ok) {
                    alert(sent.message)
                    this.loading = false
                    return
                }

                if (this.payload.death) {
                    // Move pet to dead array
                    let user = tools.getUser()
                    if (!user.pets_dead) {
                        user.pets_dead = []
                    }
                    user.pets_dead.push(this.pet_fluid)
                    user.pets = user.pets.filter(x=>{return x != this.pet_fluid})
                    tools.storeUser(user)
                }

                // Change local pet data to reflect changes
                // NOTE TO SELF : does not properly translate values...
                //      let updatedPet = { ...this.initial_data, ...this.payload}
                //      this.pet.rawattributes = updatedPet
                //      this.pet.attributes = await tools.translateDetails(updatedPet)
                //      tools.cacheStorePet(this.pet)
                tools.cacheClear(this.pet_fluid)
                
                // Stop spinner, show dialog 
                this.loading = false
                this.done_dialog_show = true

            },

            // Go back to User Page
            goToPet : async function () {
                let to = await tools.getFocusPet(false)
                this.$router.push('/scan/'+to)
            },
        },

        // Lifecycle Hooks
        async mounted() {
            this.checkLogged()
            this.pet = await tools.getFocusPet()
            if (this.pet_fluid != this.pet.fluid)
                this.$router.push('/user/'+this.owner_fluid)
            else 
                window.scrollTo(0,0)
                this.initialize()
        },
    }
</script>
 
 
<style>
</style>