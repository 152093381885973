import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import VuePdf from 'vue3-pdfjs'
import quasarUserOptions from './quasar-user-options'

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).use(VuePdf).mount('#app')
