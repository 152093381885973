<template>
    <!-- LOGBACK -->
    <div class="column items-center ">
        <div class="col-sx-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
            <div class="text-center self-center">
                <h1>Benvenutə!</h1>

                <!-- Request Mail -->
                <div v-if="request_email">
                    Probabilmente non stai aprendo il link con lo stesso browser usato per loggarti<br>
                    Abbiamo quindi bisogni di chiederti nuovamente la mail usata durante il login:
                   <p />
                    <form class="login" @submit.prevent="login2ndstep">
                        <input 
                            type="email" 
                            placeholder="Email address"
                            v-model="login_form_email"
                        />
                        <p></p>

                        <q-btn
                            color="green" 
                            text-color="white" 
                            rounded
                            label="conferma" 
                            icon="login"
                            v-on:click="login2ndstep()"
                        ></q-btn>

                    </form>
                </div>

                <!-- Subscribe -->
                <div v-if="request_subscribe">
                    Per registrarti alla piattaforma FaunaLife in 4 semplici click.<br>
                    (<span class="material-icons red">pets</span> Campi obbligatori)
                    <p>&nbsp;</p>
                    <div class="infogroup col-12 col-lg-10 col-xl-10">
                        <div class="text-left">
                            Che tipo di utenza vuoi creare? <span class="material-icons red">pets</span>
                            <q-select
                                v-model="user_type_string"
                                :options="user_role_options"
                                outlined 
                                label-color="green"
                                color="green"
                                label="Sono un..."
                                hint="Selezionare il tipo di utenza"
                            ></q-select>
                            <br>
                            Prima leggi la nostra <a href="https://www.fauna.life/informativa-privacy/" target="_blank"><b>informativa sulla privacy</b></a> e <a href="https://www.fauna.life/cookie-policy/" target="_blank"><b>cookie policy</b></a>
                            <p></p>
                            <q-toggle
                                v-model="cq_gdpr"
                                checked-icon="check"
                                color="green"
                                unchecked-icon="clear"
                            >
                                <template v-slot:default>
                                    Confermi di aver preso visione dell’ informativa sulla privacy e cookie policy <span class="material-icons red">pets</span>
                                </template>
                            </q-toggle>
                            <p>&nbsp;</p>
                            Acconsenti ai trattamenti dei dati personali indicati nell’informativa privacy precedentemente fornita,
                            relativi a:  <span class="material-icons red">pets</span>
                            <br>
                            <q-toggle
                                v-model="cq_blockchain"
                                checked-icon="check"
                                color="green"
                                unchecked-icon="clear"
                            >
                                <template v-slot:default>
                                    Creazione di un <b>ID Digitale</b> per te e per i tuoi pet sulla piattaforma FaunaLife
                                </template>                            
                            </q-toggle>
                            <br>
                            <q-toggle
                                v-model="cq_newsletter"
                                checked-icon="check"
                                color="green"
                                unchecked-icon="clear"
                            >
                                <template v-slot:default>
                                    Ricezione di <b>aggiornamenti, offerte e sconti</b> tramite la nostra <b>newsletter</b>
                                </template>
                            </q-toggle>
                            <br>
                        </div>
                        <br>

                        <div class="text-center">
                            <VueRecaptcha sitekey="6Ld_XKcfAAAAAJ8m4nvXkAaM1_QGc_N73N3Tr5u6"
                                type='recaptcha'
                                class="g-recaptcha"
                                @verify="captchaVerified"
                                @expired="captchaFailed"
                                @error="captchaFailed"
                            >
                            </VueRecaptcha>
                        </div>
                        <p></p>

                        <div v-if='loading==false'>
                            <q-btn
                                v-bind:disabled="user_checks_in"
                                color="green" 
                                text-color="white" 
                                rounded
                                label="Iscriviti" 
                                icon="login"
                                size='lg'
                                v-on:click="subscribe()"
                            ></q-btn>
                        </div>
                        <div v-else>
                            <q-spinner-ios
                                color="green"
                                size="6em"
                                :thickness="9"
                            ></q-spinner-ios>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Error Dialog -->
	<q-dialog v-model="error_dialog_show" persistent>
		<q-card>
			<q-card-section class="row items-center">
				<q-avatar icon="error" color="red" text-color="white" size="100px"></q-avatar>
				<span class="q-ml-sm">
                    <h4>Opsss...</h4>Link d' accesso invalido'.<br>Torna alla pagina di login e riprova.<br>Grazie.
                </span>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn flat icon="login" label="Login" color="green" 
                    @click="this.$router.push({name:'Login', query : this.extra_query})"
                ></q-btn>
			</q-card-actions>
		</q-card>
	</q-dialog>
</template>

<script>
    import { auth } from '../common/firebase'
    import * as tools from '../common/tools'
    import { 
        signInWithEmailLink,
        isSignInWithEmailLink,
    } from 'firebase/auth'
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        name: 'LogBackView',
        components: {
            VueRecaptcha,
        },

        data() {
            return {
                query : this.$route.query,
                extra_query : false,
                page_url : location.href,
                request_email : false,
                request_subscribe : false,
                loading : false,
                login_form_email : '',
                error_dialog_show : false,
                error_dialog_html : '',
                user_role_options : [],
                user_type_string : '',
                cq_gdpr : false,
                cq_blockchain : false,
                cq_newsletter : false,
                id_token : null,
                captcha_passed : false,
                captcha_response : null
            }
        },

        methods: {
            storeUserInLocalStorage : function (currentUser, method) {
                let user = {}
                user.accessToken = currentUser.accessToken
                user.email = currentUser.email
                user.name = currentUser.displayName || ''
                user.loginMethod = method
                tools.storeUser(user)
            },
        
            buildExtraQuery : function () {
                // Copy querystring object Keeping just flmail and other refs that might be there
                this.extra_query = JSON.parse(JSON.stringify(this.query))
                // Clean unwanted fields
                delete this.extra_query.apiKey
                delete this.extra_query.mode
                delete this.extra_query.oobCode
                delete this.extra_query.lang
            },

            logout : function () {
                tools.clearLogout()
                this.$emit("evLogged")
            },

            captchaVerified : function (response) {
                console.error("Captcha Response : ", response)
                this.captcha_passed = true
                this.captcha_response = response
            },

            captchaFailed : function () {
                this.captcha_passed = false
            },

            // This seems to be common to all the auth methods/options
            logback : async function () {
                // Check if we are loading login from the email link
                this.login_form_email = this.query.flmail || null
                if (this.query && this.query.mode === "signIn" && this.query.apiKey) {
                    if (isSignInWithEmailLink(auth, this.page_url)) {   
                        if (this.login_form_email) {
                            await this.login2ndstep()
                        } else {
                            this.request_email = true
                        }
                    }
                } else {
                    // Back to login
                    console.error("TO Login")
                    this.$router.push({name:'Login', query : this.extra_query})
                }
            },

            login2ndstep : async function () {
                let user
                try {
                    user = await signInWithEmailLink(auth, this.login_form_email, this.page_url)
                    this.id_token = user._tokenResponse.idToken
                } catch (e) {
                    // Display an error popup and send to login page
                    console.error(e)
                    this.error_dialog_html = "<h4>Opsss...</h4>Link d' accesso invalido'.<br>Torna alla pagina di login e riprova.<br>Grazie."
                    this.error_dialog_show = true
                    return
                }

                // Successfully logged on firebase, now do backend part
                // tools.clearUser()
                this.storeUserInLocalStorage(auth.currentUser, 'email_link')

                // Check if the user exists
                let exists = await tools.apiGetUserExists(null, auth.currentUser.email)
                if (exists.exists) {
                    // User can go to login
                    this.loginFinalStep()
                } else {
                    // User must subscribe
                    // Get roles from lookup table
                    let roles = await tools.apiGetUserRoles()
                    console.error("roles: ", roles)
                    this.user_role_options = roles.filter(x=> {return x.role_id >= 0}).map(x => x.IT)
                    this.request_subscribe = true
                }
            }, 

            subscribe : async function () {
                // Form privacy flags obj
                let privacy_flags = {
                    'privacy_policy'        : this.cq_gdpr,
                    'privacy_digital_id'    : this.cq_blockchain,
                    'privacy_newsletter'    : this.cq_newsletter,
                }
                // Need to subscribe first
                let regged = await tools.apiRegister(
                    auth.currentUser.email, 
                    null, 
                    this.user_type_string, 
                    this.id_token, 
                    this.captcha_response,
                    this.extra_query,
                    privacy_flags,
                )
                console.error("Reg: ",regged)
                if (! regged.ok) {
                    this.error_dialog_html = "<h4>Opsss...</h4>Errore in fase di registrazione.<br>Torna alla pagina di login e riprova.<br>Grazie."
                    this.error_dialog_show = true
                    this.loading = false;
                    return
                }

                // Now login with the new account
                this.captcha_response = null
                this.loginFinalStep()
            },

            loginFinalStep : async function () {
                this.loading = true
                let user = tools.getUser()
 
                let logged = await tools.apiLogin(user.email, null, user.accessToken, this.captcha_response)
                if (!logged.ok) {
                    if (logged.message == "Network Error") {
                        this.error_dialog_html = "<h4>Opss... !</h4>Si è verificato un errore di connessione alla rete.<br>Controlla la tua connessione e riprova.<br>Grazie."                    
                    } else {
                        this.error_dialog_html = "<h4>Opss... !</h4>Le credenziali fornite non corrispondono<br>Controlla i dati inseriti e riprova.<br>Grazie."
                    }
                    this.error_dialog_show = true
                    
                    // Clear Data
                    this.loading = false;
                    this.logout()
                } else {
                    console.error("logged: ", logged)
                    logged.email = this.login_form_email
                    delete logged.success
                    delete logged.ok
                    delete logged.message
                    // Set empty flag to make user view fill the data
                    logged.empty = true
                    tools.updateUser(logged)
                    // Emit event to warn page to redraw header
                    this.$emit("evLogged")
                    // Redirect to proper page based on role
                    switch (logged.user_role) {
                        case 0 : // owner
                        case 1 : // breeder
                        case 3 : // group / association
                            this.$router.push({path : '/user/'+logged.user_fluid})
                            break
                        case 2 : // vet
                        case 4 : // company
                            this.$router.push({path : '/biz/'+logged.user_fluid})
                            break
                        default: 
                            break
                    }
                    
                }
            },
        },

        computed : {
            user_checks_in : function() {
                return ! (this.cq_gdpr && this.cq_blockchain && this.user_type_string != '' && this.captcha_passed) 
            }
        },

        mounted() {    
            this.buildExtraQuery()
            this.logback()
        },

    }
</script>

<style scoped>
    .g-recaptcha {
        display: inline-block;
    }

    .red {
        color: red;
        font-size:0.8rem;
    }
</style>