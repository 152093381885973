<template>
    <!-- TAB-TABS -->
    <div class="infogroup-dark">
        <div class="detail-table-heading">
            <div class="detail-table-heading-title">
                <h3>Note e caratteristiche:</h3>
            </div>
            <div class="detail-table-heading-buttons" v-if="is_owner">
                <q-btn 
                color="green" 
                text-color="white" 
                rounded
                label=""
                size="md"
                icon="edit"
                class="q-ma-sm" 
                @click="$router.push({path : '/note/'+fluid})"
                ></q-btn>
            </div>
        </div>
        <div v-if="notes_txt.length>0">
            <q-carousel 
                v-model="slide"
                transition-prev="slide-right"
                transition-next="slide-left"
                animated
                control-color="green"
                class="rounded-borders"
                arrows
                navigation
            >
                <q-carousel-slide v-for="(note, idx) in notes_txt" :key='idx'
                    v-bind:name="idx"
                >
                    <h4>{{idx+1}}) {{note.description}}</h4>
                    <span v-for="(tag, idx) in note.tags" :key='idx' class="q-mx-xs">
                        <q-badge align="middle" color="green"><span v-html="tag" /></q-badge>
                    </span>                
                    <br>
                    {{note.date}}
                    <br>
                    {{note.content}}                
                </q-carousel-slide>
            </q-carousel>
        </div>
        <div v-else class="q-mx-lg">
            {{pet_name}} non ha ancora alcune nota descrittiva!
            <div v-if="is_owner">
                Clicca sul pulsante <span class="material-icons">edit</span> e raccontantaci qualcosa
            </div>
        </div>
    </div>
</template>

<script>
  import * as tools from '../../common/tools'

  export default {
    name : 'ScanNotes',
 
    props : {
    },
 
    data () {
        return {
            notes_txt : [],
            notes : [],
            fluid : '',
            slide : 0,
            is_owner : false,
            pet_name : '',
        }
    },
 
    // Lifecycle Hooks
    async created() {
        await this.getData()
        await this.readNotes()
    },

    methods: {
        getData : async function () {
            let fp = await tools.getFocusPet()
            if (fp == null) {
                return
            }
            this.fluid = fp.fluid
            this.notes = fp.notes
            this.is_owner = tools.userHasPet(this.fluid)
            this.pet_name = fp.rawattributes.name
        },

        readNotes : async function () {
            if (this.notes) {
                for (let n of this.notes) {
                    let note = await tools.cacheGetDoc(n.dochash)
                    if (!note.missing) {
                        note.content = (await tools.downloadUrl(note.url)).content
                        this.notes_txt.push(note)
                    }
                }
                this.slide=0
            }
        },
    }
}
</script>


<style>
</style>