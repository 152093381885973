import { createRouter, createWebHistory } from 'vue-router'
// import Home from  '../views/Home.vue'
import Scan from    '../views/Scan.vue'
import Print from   '../views/Print.vue'
import Hist from    '../views/History.vue'
import Login from   '../views/Login.vue'
import LogBackView from '../views/Logback.vue'
import User from    '../views/User.vue'
import Business from    '../views/Business.vue'
import AddPet from  '../views/AddPet.vue'
import EditPet from '../views/EditPet.vue'
import Docs from    '../views/Docs.vue'
import Upload from  '../views/Upload.vue'
import Search from  '../views/Search.vue'
import AddAvatar from  '../views/AddAvatar.vue'
import AddNote from '../views/AddNote.vue'
import Transfer from  '../views/Transfer.vue'
import SOS from     '../views/SOS.vue'
import CardProxy from '../views/CardProxy.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect : {name : 'Login'},
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/scan/:fluid?/:refresh?',
    name: 'Scan',
    component : Scan,
    props : true 
  },    
  {
    path: '/search',
    name: 'Search',
    component : Search,
    props : false 
  },  
  {
    path: '/print/:fluid?',
    name: 'Print',
    component : Print,
    props : true,
    meta: {
      layout: 'WithoutLogoLayout'
    } 
  },
  {
    path: '/hist/:fluid?',
    name: 'History',
    component : Hist,
    props : true,
  },
  {
    path: '/avatar/:fluid?',
    name: 'Avatar',
    component : AddAvatar,
    props : false,
  },
  {
    path: '/note/:fluid?',
    name: 'Note',
    component : AddNote,
    props : false,
  },
  {
    path: '/login',
    name: 'Login',
    component : Login,
    props : false
  },
  {
    path: '/logback',
    name: 'logback',
    component : LogBackView,
  },
  {
    path: '/user/:user_fluid?',
    name: 'User',
    component : User,
    props : true,
  },
  {
    path: '/biz/:business_fluid?',
    name: 'Biz',
    component : Business,
    props : true,
  },  
  {
    path: '/docs/:doc_hash?',
    name: 'Docs',
    component : Docs,
    props : true,
  },
  {
    path: '/addpet',
    name: 'AddPet',
    component : AddPet,
    props : false,
  },  
  {
    path: '/edit/:pet_fluid?',
    name: 'EditPet',
    component : EditPet,
    props : true,
  },
  {
    path: '/upload/:pet_fluid?',
    name: 'Upload',
    component : Upload,
    props : true,
  },
  {
    path: '/transfer/:pet_fluid?',
    name: 'Transfer',
    component : Transfer,
    props : true,
  },
  {
    path: '/sos',
    name: 'SOS',
    component : SOS,
    props : false,
  },
  {
    path: '/card/:pet_fluid',
    name: 'CardProxy',
    component : CardProxy,
    props : true,
  },
]

// Define router
const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    // Allow to set dinamic title using meta object in routes
    let title = "FaunaLife"
    if (to.meta && to.meta.title ) {
      document.title = to.meta.title
    }
    document.title = title
    next()
  })

export default router
