<template>
    <div class="row">
        <div v-for='(pet, idx) in grid' :key='idx'
            class="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 q-my-sm text-center"
            v-bind:class="{gray : this.gray}"
        >
            <q-avatar v-bind:size="avatar_size" v-on:click='onPetSelected(pet)'>
                <q-img
                    ratio=1
                    v-bind:src="pet.profilepic"
                    spinner-color="white"
                >
                    <template v-slot:error >
                        <img v-bind:src="pet.errorpic" class="profileminipic" />
                    </template>
                </q-img>
            </q-avatar>
            <div v-on:click='onPetSelected(pet)'>
                <h4 class="ellipsis">{{ pet.name }}</h4>
            </div>
        </div>
    </div>
</template>


<script>
    import * as bctools from '../../common/bctools'
    import * as tools from '../../common/tools'
    import * as config from '../../common/config'

    export default {
        name : 'ScanPetGrid',
        components: {
    },
 
    props : {               // values passed by caller Commponent via props
        pet_list : [],      // array of pet fluids
        gray : {            // Apply a grayscale palette to image (for dead pets)
            type    : Boolean,
            required: false,
            default : false,
        },
        selectOnly : {              // True:    just return an event with the selected pet, do not go to pet profile
            type    : Boolean,      // False:   (default) if selected, go to pet profile
            required: false,
            default : false,
        }
    },
 
    data () {               // State(s) function
        return {
            grid : [],
            avatar_size : config.AvatarSmallSize+'px',
        } 
    },
 
    computed : {            // Computed on the spot from data values
    },
 
    methods: {              // Component functions
        makeGrid : async function() {
            let n
            for (n in this.pet_list) {
                //check is a valid fluid
                let pet = {}
                if(! bctools.validAddress(this.pet_list[n])) {
                    continue
                }

                let fetched = await tools.cacheGetPet(this.pet_list[n])
                if (fetched==null) {
                    console.error(fetched.messate)
                    return
                }

                pet.fluid = this.pet_list[n]
                pet.name = fetched.rawattributes.name
                pet.profilepic = (fetched.profilepicurl && Array.isArray(fetched.profilepicurl) && fetched.profilepicurl.length>0) 
                    ? fetched.profilepicurl[fetched.profilepicurl.length-1] : 'error'
                pet.errorpic = tools.defaultPic(fetched.rawcodes.species)
                this.grid.push(pet)
            }
        },

        onPetSelected : function (pet) {
            // Why this works and editing avatar don't ???
            if (this.selectOnly) {
                this.$emit('petSelected', pet)
            } else {
                this.$router.push({path : '/scan/'+pet.fluid})
            }
        },
    },
 
    watch: {                // Stuff that happend when a variable changes
    },
 
    // Lifecycle Hooks
 
    mounted() {
    },
 
    created() {
        this.makeGrid()
    },
  }
</script>


<style>
.gray {
  filter: grayscale();
}

.profileminipic {
  width: v-bind(avatar_size) !important;
  height: v-bind(avatar_size) !important;
}
</style>